// COMPONENT.COMM-PREFS

$comm-prefs-bp: "small";

.c-comm-prefs {}

.c-comm-prefs__list {
	@include list-reset;
	margin-top: $bsu;

	max-width: 500px;
}

.c-comm-prefs__item {
	@include flex-extend;

	min-height: 44px;

	margin-bottom: $ssu;
	&:last-child {
		margin-bottom: 0;
	}
}

.c-comm-prefs__label {
	@include flex-extend;
	align-items: center;

	width: 150px;
	flex-shrink: 0;

	cursor: pointer;
	&.is-disabled {
		cursor: default;
	}
}

.c-comm-prefs__static-detail {
	flex-grow: 1;

	@include flex-extend;
	@include bp-below($comm-prefs-bp) {
		flex-direction: column;
	}
	@include bp($comm-prefs-bp) {
		justify-content: space-between;
	}
}

.c-comm-prefs__value {
	margin-top: 13px;

	color: $grey;
	&.is-selected {
		color: $black;
	}

	@include link-transition;

	overflow-wrap: anywhere;
}

.c-comm-prefs__change {
	@include flex-extend;
	align-items: center;

	@include font-14;

	margin-top: 2px;
	@include bp-below($comm-prefs-bp) {
		align-self: flex-start;
	}
	@include bp($comm-prefs-bp) {
		margin-left: $ssu;
	}
}

.c-comm-prefs__add {
	display: block;
	margin-top: 11px;
}
