.c-notification {
	@include flex-extend;
	align-items: stretch;

	+ .c-notification {
		margin-top: $bsu;
	}

	> :first-child {
		border-radius: 8px 0 0 8px;
	}

	> :last-child {
		border-radius: 0 8px 8px 0;
	}

	> :first-child:last-child {
		border-radius: 8px;
	}
}

.c-notification--form-result {
	@include bp-below($tab-nav-bp) {
		margin-top: $bsu;
	}
	@include bp($tab-nav-bp) {
		margin-top: $lsu;
	}

	margin-bottom: $bsu;
}

.c-notification__bar {
	border: 2px solid;
	padding: 15px 4px 15px 7px;
}

.c-notification__icon {
	color: $white;

	font-size: 20px;
}

.c-notification__body {
	@include bp("medium") {
		@include flex-extend;
		align-items: center;
		justify-content: space-between;
	}
	.c-portal-card & {
		flex-wrap: wrap;
	}

	flex-grow: 1;

	border: 2px solid;
	@include bp-below("small") {
		padding: $ssu;
	}
	@include bp("small") {
		padding: $ssu $bsu;
	}

	position: relative;

	.o-btn {
		margin-right: $ssu;
		margin-bottom: $ssu;

		&:last-child {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}

.c-notification--dismissable .c-notification__body {
	// padding-right: 44px;
	padding-right: 30px;
}

.c-notification__close {
	@include button-reset;

	position: absolute;
	top: 0;
	right: 0;

	// width: 44px;
	// height: 44px;
	width: 30px;
	height: 30px;
}

.c-notification__close-icon {
	font-size: 12px;
}

.c-notification__content {
	flex-grow: 1;

	.c-portal-card & {
		width: 100%;
	}

	@include font-15b;

	.c-app-form__alert & {
		@include font-15;
	}

	a {
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	p {
		margin-bottom: $tsu;
	}
	&:last-child,
	p:last-child {
		margin-bottom: 0;
	}

	.c-tooltip {
		margin-left: $ssu;
		vertical-align: middle;
	}
}

.c-notification__low-contrast {
	color: $grey;
}

.c-notification__amount {
	display: block;
	margin: $tsu 0;

	@include font-20b;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-notification__description {
	@include font-14;
}

@mixin notification-actions-small {
	align-items: flex-start;
	justify-content: flex-start;

	width: auto;
	margin-left: 0;
	margin-top: $ssu;
}

.c-notification__actions {
	.o-btn {
		margin-bottom: 0;
	}

	@include bp-below("medium") {
		@include notification-actions-small;
	}
	@include bp("medium") {
		@include flex-extend;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;

		flex-shrink: 0;
		margin-left: $ssu;
	}
	.c-portal-card & {
		@include notification-actions-small;
	}
}


////////////
// Status //
////////////
@mixin portal-panel-theme($dark: $status-teal-dark, $light: $white) {
	&.c-notification {
		.c-notification__bar {
			border-color: $dark;
			background: $dark;
		}
		.c-notification__body {
			border-color: $dark;
			background: $light;
		}
		.c-notification__amount {
			color: $dark;
		}
		.c-notification__low-contrast {
			color: $dark;
		}
	}
}

.o-portal-status--crystallised {
	@include portal-panel-theme($grey--darkest);
}
.o-portal-status--settled {
	@include portal-panel-theme($grey--darker);
}
.o-portal-status--success {
	@include portal-panel-theme($status-green, $status-green--pale);
}
.o-portal-status--overdue {
	@include portal-panel-theme($status-orange-light, $status-orange-light--pale);
}
.o-portal-status--overdue-very,
.o-portal-status--error {
	@include portal-panel-theme($status-red, $status-red--pale);
}
.o-portal-status--on-arrangement,
.o-portal-status--warning {
	@include portal-panel-theme($status-orange-dark, $status-orange-dark--pale);
}
