// COMPONENT.APP-FORM
$app-form-width: 870px;

.c-app-form {
	padding: 0 0 $lsu;

	@include bp("medium") {
		padding: 0 0 $hsu;
	}

	margin-top: $bsu;
}

.c-app-form__loader {
	min-height: 50vh;
}

.c-app-form__header,
.c-app-form__tools,
.c-app-form__alert,
.c-app-form__layout,
.c-app-form__form,
.c-app-form__loader {
	max-width: 100%;

	@include bp($app-form-width + $bsu * 2) {
		max-width: $app-form-width;
	}
}

.c-app-form .c-form__range {
	max-width: 710px;
}

.c-app-form__continue-modal {
	width: 320px;
}

.c-app-form__header {
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
	margin: $bsu 0;

	@include bp("medium") {
		border-bottom: 1px solid $grey--light;
		padding-bottom: $bsu;
	}
}

.o-logo-form {
	img {
		display: block;
		height: 29px;

		@include bp("medium") {
			height: 40px;
		}
	}
}

.c-app-form__tools {
	position: relative;
	@include flex-extend;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-bottom: $bsu;

	@include bp("medium") {
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: $lsu;
	}
}

.c-app-form__tools-back {
	@include button-reset;

	&::before {
		display: block;
		@include font-icon;
		content: $iconf-arrow-left;
	}

	@include link-brand;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	position: absolute;
	z-index: 1;
	left: $ssu;
	top: 12px;
	transform: translateY(-50%);

	&::after {
		content: "";
		@include abs-center;
		min-height: 44px;
		min-width: 44px;
	}

	@include bp("medium") {
		display: none;
	}
}

.c-app-form__continue {
	@include bp("medium") {
		width: auto;
		padding-top: 0;
	}
}

.c-app-form__continue-button {
	display: none;

	@include bp("medium") {
		display: block;
	}
}

.c-app-form__continue-mobile {
	margin-top: $bsu;

	p:last-child {
		margin-bottom: 0;
	}

	@include bp("medium") {
		display: none;
	}
}

.c-app-form__continue-mobile-link {
	@include button-reset;
	display: inline-block;

	@include font-16b;
	@include link-brand($orange);
}

.c-app-form__alert {
	margin: $bsu 0;
}

// App Reference
.c-app-form__app-ref {
	@include flex-extend;
	align-items: center;
	background: $cream;
	padding: $ssu $bsu;
	margin-top: $bsu;

	@include bp("medium") {
		margin-top: 0;
	}

	@include bp("large") {
		padding: $ssu $hsu;
	}
}

.c-app-form__app-ref-wrap {
	margin: 0 $bsu 0 0;
	padding: 0;
	@include flex-extend;

	&:last-child {
		margin-right: 0;
	}
}

.c-app-form__app-ref-label {
	@include font-15;
	margin-right: $ssu;
	white-space: nowrap;
}

.c-app-form__app-ref-value {
	@include font-15b;
}

// Step List //
.c-app-form__step-list {
	@include list-reset;
	@include flex-extend;

	@include bp-below("medium") {
		justify-content: center;
	}

	width: 100%;
	max-width: 250px;

	@include bp("medium") {
		max-width: 350px;
	}
}

.c-app-form__tools-back + .c-app-form__step-list {
	margin-left: 50px;

	@include bp(375px) {
		margin-left: 0;
	}
}

// CSS uses the current step to determine the state of each step
// Because CSS can only detect subsequent siblings, this means
// the default state of each step is that it is complete.
// The current step is denoted by [aria-current="true"], and
// future, incomplete steps are its subsequent siblings.

.c-app-form__step-list-item {
	@include flex-extend;
	flex-direction: column;
	align-items: center;
	width: 20%;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 12px;

		@include bp("medium") {
			top: 16px;
		}

		right: calc(50%);
		width: 100%;
		border-top: 1px solid var(--primary-a);
	}

	&:first-child {
		&::before {
			display: none;
		}
	}
}

.c-app-form__step-list-btn {
	@include button-reset;
	display: block;
	width: 24px;
	height: 24px;
	@include font-12b;

	@include bp("medium") {
		width: 32px;
		height: 32px;
		@include font-16b;
	}

	border-radius: 100%;
	box-shadow: inset 0 0 0 1px var(--primary-a);
	background: var(--primary-a);
	color: var(--primary-b);
	@include link-transition;
	position: relative;

	&::before {
		content: "";
		@include abs-center;
		min-width: 44px;
		min-height: 44px;
	}
}

.c-app-form__step-list-number {
	display: none;
}

.c-app-form__step-list-complete {
	display: block;
}

.c-app-form__step-list-title {
	display: none;

	@include bp("medium") {
		display: block;
	}

	margin-top: $tsu;

	@include font-10;
	text-align: center;
	white-space: nowrap;
	visibility: hidden;
}

.c-app-form__step-list-item[aria-current="true"] {
	.c-app-form__step-list-btn {
		background: var(--primary-a);
		color: var(--primary-b);
	}

	.c-app-form__step-list-title {
		display: block;
	}
	// Current and future
	&,
	& ~ .c-app-form__step-list-item {
		.c-app-form__step-list-number {
			display: block;
		}

		.c-app-form__step-list-complete {
			display: none;
		}

		.c-app-form__step-list-title {
			color: $grey--darkest;
			visibility: visible;
		}
	}
	// Future
	& ~ .c-app-form__step-list-item {
		.c-app-form__step-list-btn {
			background: var(--primary-b);
			color: var(--primary-a);
		}

		.c-app-form__step-list-title {
			color: $grey;
		}
	}
}



// Step //
.c-app-form__steps {
	@include list-reset;
}

.c-app-form__step-title {
	@include font-28b;
	margin-bottom: 0;

	strong {
		color: $teal--dark;
/*		white-space: nowrap;*/
	}

	@include bp("medium") {
		@include font-50sb;
	}

	.c-app-form__confirmation--declined & {
		strong {
			color: $orange;
		}
	}
}

.c-app-form__confirmation--conditional .c-app-form__step-title {
	strong {
		white-space: nowrap;
	}
}

.c-app-form__step-title-tick {
	height: 16px;

	@include bp("medium") {
		height: 30px;
	}
}

.c-app-form__step-title--small {

	@include bp("medium") {
		@include font-40sb;
	}
}

.c-app-form__step-intro {
	margin-top: $bsu;
	margin-bottom: 0;
}


@include bp-below("medium") {
	.c-app-form__back {
		display: none;
	}

	.c-app-form__submit-text {
		order: 2;
	}

	.c-app-form__submit {
		order: 1;
	}
}

.c-app-form__change,
.c-app-form__add {
	position: relative;

	.o-btn__icon,
	.o-btn__text {
		display: inline-block;
		vertical-align: middle;
		padding-left: 30px;
	}

	.o-btn__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-left: -30px;
		font-size: 1.5em;
	}
}

.c-app-form__change {
	+ .c-app-form__submit {
		margin-left: $ssu;
	}
}

.c-app-form__submit {
	&:first-child:last-child {
		flex-grow: 1;
	}
}



// Help //
.c-app-form__help-button-sticky-wrapper {
	position: absolute;
	z-index: 2;
	right: 20px;
	bottom: 0;
}

.c-app-form__help-button {
	margin-bottom: 20px;

	@include link-transition;
	padding: $ssu;
	min-width: 44px;

	@include bp("medium") {
		padding: $ssu $bsu;
	}

	border: 2px solid $white;
	border-radius: 30px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	background: $brand;
	color: $white;
	box-shadow: 0 7px 10px 2px rgba($black, 0.2);

	@include font-16b;

	&:hover,
	&:focus {
		background-color: $brand--dark;
	}
}

.c-app-form--help-hint {
	.c-app-form__help-button {
		animation: bounceShake 1s 1.5s;

		@include bp("medium") {
			animation: bounceWiggle 1s 1.5s;
		}
	}
}

.c-app-form__help-button-text {
	@include bp-below("medium") {
		display: none;
	}
}

@supports (position: sticky) {
	.c-app-form__help-button-wrapper {
		@include flex-extend;
		align-items: flex-end;
		pointer-events: none;
		position: absolute;
		z-index: 2;
		bottom: 20px;
		right: 20px;
		height: 100%;
	}

	.c-app-form__help-button-sticky-wrapper {
		pointer-events: auto;
		z-index: auto;
		position: sticky;
		bottom: 20px;
		right: 20px;
	}

	.c-app-form__help-button {
		margin-bottom: 0;
	}
}

.c-app-form__form {
}

.c-app-form__help-sticky-wrapper {
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100vh;
}

.c-app-form__help {
	visibility: hidden;
	height: 100%;
	overflow: auto;
	width: 500px;
	max-width: 100%;
	padding-left: 1px;
	background: white;
	box-shadow: inset 2px 0 0 -1px $black;

	@include flex-extend;
	flex-direction: column;
}

.c-app-form__help-wrapper {
	pointer-events: none;

	&[aria-hidden="false"] {
		pointer-events: auto;

		.c-app-form__help {
			animation: 0.5s slideinRight forwards, 0.5s becomeVisible forwards;
		}
	}

	&[aria-hidden="true"] {
		.c-app-form__help {
			animation: 0.5s slideoutRight forwards, 0.5s becomeHidden forwards;
		}
	}
}

@supports (position: sticky) {
	.c-app-form__help-wrapper {
		position: absolute;
		z-index: 3;
		top: 0;
		right: 0;
		height: 100%;
	}

	.c-app-form__help-sticky-wrapper {
		position: sticky;
	}
}

@include bp-below("medium") {
	.c-app-form__help-sticky-wrapper {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: auto;
		width: auto;
	}

	.c-app-form__help {
		width: 100%;
		max-width: none;
	}
}

// Help innards
.c-app-form__help-close {
	@include button-reset;
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	padding: 14px;
	border-radius: 3px 0 3px 3px;
	font-size: 16px;
	color: $white;

	@include link-transition;

	&:hover {
		background: $white;
		color: $green--grey;
	}

	&:focus {
		@include focus-outline($white);
	}
}

.c-app-form__help-hide-wrapper {
	position: relative;
}

.c-app-form__help-hide {
	position: absolute;
	bottom: 20px;
	left: 20px;
}

.c-app-form__help-footer + .c-app-form__help-hide-wrapper {
	.c-app-form__help-hide {
		bottom: 140px;
	}
}

.c-app-form__help-body {
	flex-grow: 1;
	@include flex-extend;
	flex-direction: column;
}

.c-app-form__help-body-bg {
	padding: $bsu;
	flex-grow: 1;
	background-position: bottom right 20px;
	background-repeat: no-repeat;

	@include flex-extend;
	flex-direction: column;
	justify-content: center;
}

.c-app-form__help-body-viewport {
	overflow: auto;
	// 39px is the height of the "Hide" button
	margin-bottom: $bsu + 39px;
}

.c-app-form__help-title {
	@include font-28b;
	margin-bottom: $bsu;
	padding-right: (44px - $bsu);
}

.c-app-form__help-text {
	@include font-16;
}

.c-app-form__help-footer {
	background: $white;
	height: 120px;
}


.c-app-form__help-tool-list {
	@include list-reset;
	@include flex-extend;
	justify-content: space-between;
}

.c-app-form__help-tool {
	width: 25%;
}

.c-app-form__help-tool-link {
	@include button-reset;
	display: block;
	width: 100%;
	text-align: center;
	padding: $bsu $ssu $lsu;
}

.c-app-form__help-tool-icon {
	display: block;
	font-size: 30px;
	color: $brand;
	margin-bottom: $ssu;
}

.c-app-form__help-tool-name {
	@include font-12b;
	color: $grey--darkest;
}

// Confirmation
.c-app-form__confirmation {
	display: block;
	max-width: $app-form-width;
	overflow: hidden;
	border-left: 3px solid $teal--dark;

	.c-app-form__confirmation--declined & {
		border-color: $orange;
	}
}

.c-app-form__confirmation-head {
	padding: $bsu;
	margin: 0;

	@include flex-extend;
	flex-wrap: wrap;
	align-items: center;
}

.c-app-form__confirmation-body {
	background: $white;
	padding: $ssu;

	@include bp("small") {
		padding: $ssu $bsu;
	}
}

.c-app-form__confirmation-item {
	@include flex-extend;
	flex-wrap: wrap;
	justify-content: space-between;
}

.c-app-form__confirmation-xfer {
	@include flex-extend;
	flex-wrap: wrap;
	background: $status-orange-light--pale;
	padding: $bsu;
	margin: 0 0 $bsu;
	width: 100%;
	border-radius: 10px;
	justify-content: space-between;
}

.c-app-form__confirmation-xfer-icon {
	width: 30px;
	flex: 0 0 30px;
	color: $status-orange-light;
	font-size: 20px;
}

.c-app-form__confirmation-xfer-text {
	@include font-15b;
	flex: 0 1 auto;
	max-width: calc(100% - 30px);

	@include bp("medium") {
		max-width: calc(75% - 30px);
		padding-right: $bsu;
	}
}

.c-app-form__confirmation-xfer-logo {
	max-width: 220px;
	flex: 0 0 auto;
	margin-left: 30px;

	@include bp("medium") {
		width: 25%;
		margin: 0;
	}

	img {
		display: block;
		width: 100%;
	}
}

.c-app-form__confirmation-item {
	width: 100%;
	break-inside: avoid;

	span {
		display: inline;
	}
}

.c-app-form__confirmation-notes {
	.c-app-form__confirmation-item {
		margin-top: $bsu;

		@include bp("medium") {
			margin-bottom: 0;
		}
	}
}

.c-app-form__confirmation-label {
	display: block;
	margin-bottom: $tsu;
	color: $grey--darkest;
	flex: 50%;

	@include bp-below("small") {
		font-size: 12px;
	}
}

.c-app-form__confirmation-value {
	display: block;
	margin-bottom: $tsu;
	color: $teal--dark;
	flex: 50%;

	@include font-16b;

	@include bp-below("small") {
		font-size: 12px;
	}

	a {
		@include link-brand;
	}

	span {
		color: $grey--darkest;
	}
}

.c-app-form__footer-text {
	margin: 0;
}

.c-app-form__footer-value {
	@include font-18b;
	color: $grey--darkest;

	a {
		@include link-brand;
		white-space: nowrap;
	}
}
// Pane
.c-app-form__pane {
	padding: $bsu;
	border-radius: 3px;
	box-shadow: inset 0 0 0 1px $grey;

	&.c-app-form__pane--status {
		@include flex-extend;
		align-items: center;
	}

	&.c-app-form__pane--success {
		box-shadow: inset 0 0 0 1px $success;

		.c-app-form__pane-icon {
			background-color: $success;

			&::before {
				content: $iconf-tick;
			}
		}
	}

	&.c-app-form__pane--error {
		box-shadow: inset 0 0 0 1px $error;

		.c-app-form__pane-icon {
			background-color: $error;

			&::before {
				content: $iconf-cross;
			}
		}
	}

	+ .c-app-form__action {
		margin-top: $bsu;
	}
}

.c-app-form__pane-icon {
	width: 30px;
	height: 30px;
	font-size: 15px;
	border-radius: 30px;

	@include bp("medium") {
		width: 50px;
		height: 50px;
		font-size: 25px;
		border-radius: 50px;
	}

	margin-right: $bsu;
	flex-shrink: 0;
	position: relative;

	&::before {
		@include font-icon;
		@include abs-center;
		color: $white;
	}
}

.c-app-form__pane-text {
	flex-grow: 1;

	> p:last-child {
		margin-bottom: 0;
	}
}

.c-app-form__secure {
	margin: $lsu auto $bsu;
	width: max-content;

	+ .c-form__section {
		padding-top: 0;
	}

	@include flex-extend;
	align-items: center;
}

.c-app-form__secure-icon {
	display: block;
	margin-right: $bsu;
}

.c-app-form__secure-text {
	display: block;

	@include font-16b;
	margin-right: $bsu;

	&:last-child {
		margin-right: 0;
	}
}

.c-app-form__skip {
	margin: $bsu 0;

	@include flex-extend;
	justify-content: flex-end;
}

.c-app-form__skip-button {
	@include button-reset;
	@include font-16b;
	@include link-brand;
}
// IF-138 Prefilled panel
.c-app-form__secondary,
.c-app-form__inactive {
	max-width: 500px;
}

.c-app-form__secondary {
	padding-top: $bsu;
}

.c-app-form__prefill {
	margin-top: $bsu;
	padding: ($bsu - $tsu) ($bsu + $ssu);
	border-radius: 8px;
	background-color: $cream;
}

.c-app-form__prefill-list {
	margin: 0 $bsu 0 0;
	padding: 0;

	&:last-child {
		margin-right: 0;
	}
}

.c-app-form__prefill-item {
	@include bp("small") {
		@include flex-extend;
		align-items: flex-start;
	}

	padding: 10px 0;
}

.c-app-form__prefill-label {
	@include font-16b;

	@include bp("small") {
		flex: 0 0 106px;
		white-space: nowrap;
	}
}

.c-app-form__prefill-value {
	@include font-18;
	flex: 1 0 auto;

	@include bp("small") {
		width: calc(100% - 106px);
		padding-left: $ssu;
	}
}
// Referral //
.c-app-form__referral {
	margin-bottom: $lsu;
}

.c-app-form__referral-logo {
	display: block;
	margin: 0 0 $lsu;
	max-width: 400px;
}

.c-app-form__referral-intro {
}

.c-app-form__skeleton-item {
	&::before {
		display: inline-block;
		content: " ";
	}

	background: rgba($black, 0.03);
	position: relative;
	overflow: hidden;

	&::after {
		content: "";
		background: rgba($white, 0.3);
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 2ch;
		height: 100%;
		transform: skew(-30deg);
		animation: 1s linear infinite skeleton-text;
		animation-direction: forwards;
	}
}

.c-app-form__skeleton-field {
	display: block;
	max-width: 500px;
	height: 120px;
}
// Pre-save check

.c-app-form__pre-save-check-button-list {
	display: flex;

	@include bp-below("medium") {
		flex-wrap: wrap;
	}

	margin-left: -$bsu;
}

.c-app-form__pre-save-check-button-item {
	flex-grow: 1;
	padding-left: $bsu;
	padding-top: $bsu;

	@include bp-below("medium") {
		width: 100%;
	}
}

.c-app-form__pre-save-check-button {
	white-space: nowrap;
	display: block;
	width: 100%;
}

.c-app-form__step-info {
	margin-top: 40px;
	max-width: 870px;

	p {
		font-size: 14px;
		line-height: 17px;
	}
}


#HeroTextReadMore {
	max-height: 82px;
	overflow: hidden;
	transition: height 0.3s;
	position: relative;

	@include bp("small") {
		max-height: 100%;
	}

	&.readmore {
		max-height: 100%;
		transition: height 0.3s;
	}

	p {
		margin: 0;
	}
}

#expandbtn {
	display: block;
	height: auto;
	transition: height 0.3s;
	margin-top: 10px;
	@include button-reset;

	@include font-12b;

	span {
		margin-right: 5px;
		color: $teal--dark;
	}

	@include bp("small") {
		display: none;
	}
}

.c-form__section.footer {
	margin-top: 40px;
}

.c-confirmation__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: white;
	z-index: 1000;
	padding-top: 80px;
	opacity: 1;
	transition: opacity 0.6s linear;
	pointer-events: none;

	&.fadeout {
		opacity: 0;
	}

	&.fadein {
		opacity: 1 !important;
	}

	.c-form__section-title, p {
		text-align: center;
	}

	.loader-wrapper {
		padding: 150px;
		margin: 0 auto;
		width: 100%;

		.loader {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			margin: 0 auto;
			display: block;
			position: relative;
			border: 3px solid;
			border-color: $teal $teal transparent;
			box-sizing: border-box;
			animation: rotation 1s linear infinite;

			&:after {
				content: '';
				box-sizing: border-box;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				border: 3px solid;
				border-color: transparent $teal--dark $teal--dark;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				animation: rotationBack 0.5s linear infinite;
				transform-origin: center center;
			}
		}
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}
