﻿// COMPONENT.FIND-A-BRANCH
.c-find-a-branch__container {
	@include gradient-bg__to-bottom-right;
	position: relative;
	padding: $bsu;
	padding-top: 40px;
	border-radius: 5px;

	@include bp('large') {
		padding: $bsu*2;
		padding-left: 170px;
		padding-right: 100px;
		border-radius: 10px;
	}
}

.c-find-a-branch__logo {
	position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
	width: 65px;

	@include bp('large') {
		left: 50px;
		transform: none;
		top: 30px;
		width: 85px;
	}
}
.c-find-a-branch__title {
	@include font-16b;
	color: $white;

	@include bp('large') {
		@include font-20b;
	}
}
.c-find-a-branch__search {
	position: relative;
	margin-top: $ssu;

	input.c-find-a-branch__search-input {
		@include font-15;
		width: 100%;
		max-width: none;
		padding-right: $bsu*2;
	}


}


.c-find-a-branch__search-icon {
	position: absolute;
	right: $ssu;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
	cursor: pointer;
	padding: $ssu;
}

.c-find-a-branch__view-link {
	@include font-14b;
	display: block;
	color: $white;
	margin-top: $bsu;
	&:hover, &:focus {
		text-decoration: underline;
	}
}

.c-find-a-branch__title-wrapper {
	@include bp('large') {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}