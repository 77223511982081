// OBJECT.BTN

// Shared button styles

.o-btn, .btn {
	@include link-transition;
	@include font-16b;
	display: inline-block;
	padding: 17px $ssu*3 18px;
	border: 0;
	border-radius: 30px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}

.o-btn--nowrap {
	white-space: nowrap;
}

.o-btn--brand:disabled,
.o-btn--supporting:disabled,
.o-btn--disabled {
	cursor: default;
	color: $white;

	&,
	&:hover,
	&:focus {
		background: $grey;
	}
}

.o-btn--bordered {
	color: $brand--dark;
	background-color: $white;
	border: 1px solid $brand--dark;

	&:hover,
	&:focus {
		color: $white;
		background-color: $brand--dark;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			cursor: default;
			color: $grey;
			background-color: $white;
			border-color: $grey;
		}
	}
}

@mixin portal-btn-bordered-theme($status: $status-teal-dark) {
	&.o-btn--bordered-portal {
		color: $status;
		background-color: $white;
		border: 1px solid $status;

		&:hover,
		&:focus {
			color: $white;
			background-color: $status;
		}

		&:disabled {
			&,
			&:hover,
			&:focus {
				cursor: default;
				color: $grey;
				background-color: $white;
				border-color: $grey;
			}
		}
	}
}

.o-portal-status--crystallised {
	@include portal-btn-bordered-theme($grey--darkest);
}
.o-portal-status--settled {
	@include portal-btn-bordered-theme($grey--darker);
}
.o-portal-status--overdue {
	@include portal-btn-bordered-theme($status-orange-light);
}
.o-portal-status--overdue-very {
	@include portal-btn-bordered-theme($status-red);
}
.o-portal-status--on-arrangement,
.o-portal-status--warning {
	@include portal-btn-bordered-theme($status-orange-dark);
}


.o-btn--hollow {
	@include font-16b;
	color: $white;
	background-color: transparent;
	border: 1px solid $white;

	&:hover,
	&:focus {
		background-color: $white;
		color: $brand;
	}
}

.o-btn--link {
	@include button-reset;

	margin: 0 0 $ssu;
	&:last-child {
		margin-bottom: 0;
	}

	@include font-14;

	.o-btn__icon {
		display: inline-block;
		font-size: 0.8em;
	}

	&:disabled {
		cursor: default;
		color: $grey;

		&,
		&:hover,
		&:focus {
			color: $grey;
		}
	}
}

.o-btn--link-bold {
	@extend .o-btn--link;
	@include font-14b;
}

.o-btn--small {
	@include font-14b;
	padding: $ssu $bsu;
}

.o-btn--large {
	@include font-16b;
	padding: 17px 15px;
}

.o-btn--huge {
	@include font-18bu;
	padding: 25px ($lsu * 1.5);
	border-radius: 36px;
}

.o-btn--multiline {
	@include flex-extend;
	align-items: center;
	text-align: left;

	padding-top: $tsu;
	padding-bottom: $tsu;
	min-height: 57px;

	.o-btn__icon {
		font-size: 1.2em;
		&:first-child {
			margin-right: $ssu;
		}
	}
	.o-btn__text {
		position: relative;
		top: -2px;
	}
}

.o-btn__badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include circle(33px);

    margin-top: -6px;
    margin-bottom: -6px;
    margin-right: -16px;
    margin-left: $ssu;
}

.o-btn-speedup {
	width: max-content;
	margin: 0 auto;

	@include bp-below("small") {
		font-size: 14px;
		min-height: 40px;
	}

	.o-btn__text {
		margin: 0 10px;
	}
}