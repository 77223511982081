﻿.c-two-column {
}

.c-two-column__layout {
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -$lsu;
}

.c-two-column__column {
	padding-left: $lsu;
	width: 100%;

	@include bp("large") {
		width: 50%;
	}

	&:nth-child(n+1) {
		> .o-control:first-child {
			margin-top: 0;
		}
	}
}