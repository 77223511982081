// OBJECT.FIT

.o-fit {
	position: relative;
	display: block;
	width: 100%;

	.c-hp-pathway__figure &,
	.o-pod & {
		height: 100%;
		padding-bottom: 0 !important;
	}
}

.o-fit__image {
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;

	.no-object-fit & {
		left: 50%;
		top: 50%;
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		transform: translate(-50%, -50%);
	}
}