﻿// CONTROL.IMAGE-CTA

@use "sass:math";

.c-image-cta-viewpoint {
	height: 0;
	padding-bottom: 100%; //mobile use square crop for now
	overflow: hidden;

	@include bp('large') {
		padding-bottom: percentage(math.div(384, 1366)); //desktop use hero crop for now
	}
}

.c-image-cta__item {
	position: relative;
}

.c-image-cta__body {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.c-image-cta__abstract {
	margin-top: $bsu;
	@include font-14;
}

.c-image-cta__btn {
	margin-top: $bsu;
}