@use "sass:math";

$portal-nav-icon-size: 24px;

.c-portal-nav {
	position: relative;
	z-index: 2;

	@include bp($portal-nav-bp) {
		position: absolute;
		top: 0;
		bottom: 0;

		&[aria-expanded="true"] {
			box-shadow: 0 6px 6px rgba($black, 0.2);
		}

		@include link-transition(box-shadow);
	}

	@include bp($portal-nav-open-bp) {
		box-shadow: 0 6px 6px rgba($black, 0.2);
	}
}

.c-portal-nav__sticky {
	@include bp($portal-nav-bp) {
		position: absolute;

		@supports (position: sticky) {
			position: sticky;
			top: 0;
			@media print {
				position: absolute;
			}
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			width: 100%;
			height: 100vh;
		}
	}
}

.c-portal-nav__spacer {
	width: $portal-nav-size-desktop--collapsed;
	flex-shrink: 0;

	@include bp-below($portal-nav-bp) {
		display: none;
	}
}

.c-portal-nav__spacer--mobile {
	height: $portal-nav-size-mobile;

	@include bp($portal-nav-bp) {
		display: none;
	}
}
.c-portal-nav__layout {}

.c-portal-nav__button-list {
	@include list-reset;
	@include flex-extend;

	@include bp-below($portal-nav-bp) {
		position: fixed;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: $portal-nav-size-mobile;
		padding: 0 5px;

		@media print {
			position: absolute;
		}

		background: $white;
		box-shadow: 0 0 12px rgba($black, 0.2);

		@supports (filter: drop-shadow(0 0 12px rgba($black, 0.2))) {
			background: transparent url("/content/images/interface/portal-nav.svg");
			background-position: center;
			box-shadow: none;
			filter: drop-shadow(0 0 12px rgba($black, 0.2));
		}
	}
}

.c-portal-nav__button-item {
	flex: 1 1 20%;
	@include flex-extend;
	justify-content: center;

	@include bp($portal-nav-bp) {
		display: none;
	}
}

.c-portal-nav__toggle {
	@include flex-extend;
	align-items: center;
	justify-content: center;

	@include button-reset;

	@include focus-visible;
	@include link-transition;

	&::before {
		@include font-icon;
		content: $iconf-menu;

		line-height: 1;

		// Applying an identity transformation ensures there is
		// no jump at the beginning or end of a rotate transition
		transform: scale(1);
		@include link-transition;
	}

	&[aria-expanded="true"] {
		&::before {
			transform: rotate(90deg);
		}
	}

	@include bp-below($portal-nav-bp) {
		@include circle(46px);

		@include flex-extend;
		align-items: center;
		justify-content: center;

		position: relative;
		top: -20px;

		&::before {
			font-size: 26px;
		}

		.c-portal-nav__menu & {
			display: none;
		}
	}

	@include bp($portal-nav-bp) {
		@include square($portal-nav-size-desktop--collapsed);

		&::before {
			font-size: 24px;
		}
	}

	@include bp($portal-nav-open-bp) {
		display: none;
	}
}

.c-portal-nav__button-link {
	@include flex-extend;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&[aria-current]:not([aria-current="false"]) {
		color: $grey--darkest;
	}
}

.c-portal-nav__button-link--disabled {
	&,
	&:hover,
	&:focus {
		color: $grey--dark;
	}
}

.c-portal-nav__button-icon {
	font-size: 16px;
}

.c-portal-nav__button-name {
	display: block;
	margin-top: 4px;
	@include font-10u;
}


.c-portal-nav__menu {
	@include bp-below($portal-nav-bp) {
		position: fixed;
		top: $portal-header-height-mobile;
		bottom: 0;
		width: 100%;
		transform: translateY(100%);

		.c-portal-nav[aria-expanded="true"] & {
			transform: translateY(0%);
		}
	}

	@include bp($portal-nav-bp) {
		z-index: 1;
		left: 0;
		bottom: 0;
		top: $portal-header-height-desktop;

		max-width: $portal-nav-size-desktop--collapsed;
		.c-portal-nav[aria-expanded="true"] & {
			max-width: $portal-nav-size-desktop--expanded;
		}
		overflow: hidden;
	}

	@include bp($portal-nav-open-bp) {
		max-width: $portal-nav-size-desktop--expanded;
	}

	@include link-transition;
}

.c-portal-nav__menu-inner {
	@include bp-below($portal-nav-bp) {
		position: absolute;
		top: 0;
		bottom: $portal-nav-size-mobile;
		width: 100%;

		overflow: auto;
	}

	@include bp($portal-nav-bp) {
		width: $portal-nav-size-desktop--expanded;
	}
}

.c-portal-nav__welcome {
	display: block;
	padding: $bsu;

	// Line up with icons
	padding-left: #{math.div(($portal-nav-size-desktop--collapsed - $portal-nav-icon-size), 2)};

	@include bp($portal-nav-bp) {
		display: none;
	}
}

.c-portal-nav__sections {
	@include list-reset;

	border-top: 1px solid $grey--light;
}

.c-portal-nav__section {
	border-bottom: 1px solid $grey--light;

	&[aria-current]:not([aria-current="false"]) {
		background: $white;

		.c-portal-nav__section-head {
			color: $grey--darkest;
		}

		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			border-left: 5px solid transparent;
		}
	}

	&[aria-current="false"],
	&:not([aria-current]) {
		.c-portal-nav__section-head:hover {
			background: $white;
			color: $grey--darkest;
		}
	}

	@include link-transition;
}

.c-portal-nav__section-head {
	@include button-reset;
	@include flex-extend;
	align-items: center;

	height: $portal-nav-size-desktop--collapsed;
	width: 100%;

	@include font-16b;

	position: relative;
	&::after {
		position: absolute;
		right: $bsu;
		top: 50%;
		transform: translateY(-50%);

		@include square(20px);
		font-size: 20px;
		line-height: 1;
	}

	&[aria-expanded="false"] {
		&::after {
			content: $iconf-plus;
		}
	}
	&[aria-expanded="true"] {
		&::after {
			content: $iconf-minus;
		}
	}

	@include focus-visible;
	@include link-transition;
}

.c-portal-nav__section-icon {
	@include flex-extend;
	align-items: center;
	justify-content: center;

	@include square($portal-nav-size-desktop--collapsed);
	margin-right: $ssu;

	font-size: 24px;
}

.c-portal-nav__section-name {}

.c-portal-nav__section-children {
	@include list-reset;

	padding-left: #{math.div(($portal-nav-size-desktop--collapsed - $portal-nav-icon-size), 2)};
	margin-bottom: $bsu;

	display: none;
	.c-portal-nav__section[aria-expanded="true"] & {
		display: block;
	}
}

.c-portal-nav__section-child-item {
	margin-top: $ssu;

	@include bp-below("large") {
		@media (hover: none) {
			margin-top: $bsu;
		}
	}
}

.c-portal-nav__section-child-link {
	display: block;

	&:hover,
	&:focus {
		color: $grey--darkest;
	}
	@include link-transition;

	&::before {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: center;

		font-size: 0.8em;

		margin-right: $ssu;
	}
}

.c-portal-nav__login-time {
	padding: 0 0 $bsu;
	font-size: 12px;
}

.c-portal-nav__actions {
	@include list-reset;
	padding: $bsu;
	// Line up with icons
	padding-left: #{math.div(($portal-nav-size-desktop--collapsed - $portal-nav-icon-size), 2)};

	@include bp($portal-nav-bp) {
		visibility: hidden;

		.c-portal-nav.allow-animations & {
			animation: 0.5s fadeout forwards,
			           0.5s becomeHidden forwards;
		}
		.c-portal-nav.allow-animations[aria-expanded="true"] & {
			animation: 0.5s fadein forwards,
			           0.5s becomeVisible forwards;
		}
	}

	@include bp($portal-nav-open-bp) {
		visibility: visible;
		.c-portal-nav.allow-animations & {
			animation: none;
		}
	}

	@include link-transition;
}

