﻿// CONTROL.PODS


// IMAGE POD
.c-pod__figure {
	position: relative;
	width: 100%;
	height: 285px;
	border-radius: 5px;
	overflow: hidden;

	> img {
		position: absolute;
		left: 50%;
		top: 50%;
		width: auto;
		min-width: 101%;
		min-height: 101%;
		transform: translate(-50%, -50%);
		transition: transform 0.2s ease-in-out;
	}

	/*&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
	}*/
}

.c-pod__image {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 120px;

		pointer-events: none;

		background: linear-gradient(0deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);
	}
}


// NO IMAGE POD
.c-pod__bg {
	@include gradient-bg__to-bottom-right;
	position: relative;
	border-radius: 5px;

	@include bp('medium') {
		height: 285px;
	}
}
