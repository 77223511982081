// OBJECT.CONTROL

.o-control {
	margin: $lsu 0;

	@include bp('extra-large') {
		margin: $lsu*2 0;
	}
}

.o-control__heading {
	position: relative;
	@include font-40b;
	margin-bottom: $lsu;
	text-align: center;

	&:after {
		display: block;
		position: relative;
		content: '';
		top: 10px;
		margin: 0 auto;
		text-align: center;
		width: 50px;
		height: 0;
		border-top: 3px solid $brand;
	}
}

.o-control__abstract {
	max-width: $layout-wrapper-narrow-width;
	margin: 0 auto;
	text-align: center;
}

.o-control__figcaption {
	@include ui-border(bottom);
	@include font-15;
	padding: 15px 0;

	@include bp('large') {
		padding: $bsu 0;
	}
}


//control gap customized
.c-rte + .c-loan-calculator-control {
	margin-top: -$bsu;

	@include bp('extra-large') {
		margin-top: -$bsu*2;
	}
}