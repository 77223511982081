﻿// MACRO.BLOCKQUOTE

.m-quote__text {
	@include font-20b;
	color: $brand;
	margin-bottom: $ssu;
}

.m-quote__citation {
	@include font-12b;
	color: $body-color;
}

a.m-quote__citation-link {
	color: $body-color;
}