/* COMPONENT.HEADER-NOTIFICATION */

.c-header-notification {
	background: $brand;
	color: $white;
	padding: $ssu 0;
	height: $notif-height--mobile;
	text-align: center;
	// use CSS var
	color: var(--supporting-1-b);
	background-color: var(--supporting-1-a);
	font-size: 14px;
	display: flex;
	align-items: center;
	overflow: hidden;

	position: relative;
	z-index: 2;

	@include bp('large') {
		height: auto;
		overflow: unset;
	}
}

.c-header-notification__layout {
	// o-layout-wrapper o-layout-wrapper--narrow
	@include layout-wrapper;
}

.c-header-notification__heading {
	margin-bottom: $ssu;
}

.c-header-notification__text {
	:last-child {
		margin-bottom: 0;
	}

	a {
		@include link-plain;
		text-decoration: underline;
	}
}
