﻿// CONTROL.IMAGE-CAROUSEL

.c-testimonial-carousel {
	position: relative;
	.homepage &{
		padding-bottom: 60px;
		margin-bottom: 0;
	}
	@include bp('large') {
		.homepage &{
			padding-bottom: 80px;
		}
	}
}

.c-testimonial-carousel-bg {
	display: none;
	@include bp('medium') {
		.homepage &{
			display: block;
			position: absolute;
			width: 65%;
			height: 60%;
			bottom: 0;
			right: 0;
			background: linear-gradient(to bottom right, transparent 0%, transparent 50%, rgba(128, 128, 128, 0.1) 50%, rgba(128, 128, 128, 0.1) 100%);
		}
	}
}

.c-testimonial-carousel__items {
	@include list-reset;
	@include clearfix;

	padding-bottom: $bsu;
	.flickity-page-dots {
		bottom: 0;
	}

	.flickity-prev-next-button {
		display: none;
	}

	@include bp(1480px) {
		.flickity-prev-next-button {
			display: block;

			&.previous {
				left: -50px;
			}

			&.next {
				right: -50px;
			}
		}

		&.hide-arrows .flickity-prev-next-button {
			display: none;
		}
	}
}

.c-testimonial-carousel__item {
	//display: inline-block;
	width: 100%;
	padding: 7px;
	padding-bottom: 30px;
	padding-top: 50px;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	@include bp('medium') {
		width: 50%;
		float: left;

		&:nth-child(n+2) {
			display: inline-block;
		}

		&:nth-child(n+3) {
			display: none;
		}
	}

	@include bp('large') {
		width: 25%;
		float: left;

		&:nth-child(n+3) {
			display: block;
		}

		&:nth-child(n+5) {
			display: none;
		}
	}
}

.c-testimonial-carousel__item-content {
	position: relative;
	margin: 0;
	padding: $bsu;
	padding-top: 50px;
	text-align: center;
	border-radius: 5px;
	@include gradient-bg__to-bottom-right;
	@include pod-shadow;
	@include pod-transition;

	&.is-info-carousel {
		padding-top: $bsu;
	}

	@include bp('medium') {
		background: $white;
	}

	&:hover, &:focus {
		/*@include gradient-bg__to-bottom-right;*/
		cursor: default;

		/*.c-testimonial-carousel__text {
			color: $white;
		}

		.c-testimonial-carousel__citation {
			color: $white;
		}

		.c-testimonial-carousel__title {
			color: $white;
		}*/
	}
}

.c-testimonial-carousel__logo {
	position: absolute;
	top: -25px;
	color: $brand;
	font-size: 20px;
	left: 50%;
	transform: translateX(-50%);

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-testimonial-carousel__logo-image {
	width: 65px;
	height: 65px;
	display: block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.c-testimonial-carousel__text {
	@include font-15;
	color: $white;

	@include bp('medium') {
		color: $brand;
	}
}

.c-testimonial-carousel__citation {
	display: block;
	@include font-12;
	color: $white;
	margin-top: $bsu;

	@include bp('medium') {
		color: $body-color;
	}
}


//for information carousel
.c-testimonial-carousel__title {
	@include font-20b;
	color: $white;
	margin-bottom: $bsu;

	@include bp('medium') {
		color: $brand;
	}
}