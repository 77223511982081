$portal-card-bp: "medium";

.c-portal-card {
	display: block;

	background: $white;
	@include bp-below("small") {
		padding: $ssu;
	}
	@include bp("small") {
		padding: $bsu;
	}
	border-radius: 4px;
	border: 1px solid $grey;


	&.o-portal-status--crystallised {
		border-color: $grey--darkest;
	}
	&.o-portal-status--settled {
		border-color: $grey--darker;
	}
	&.o-portal-status--success {
		border-color: $status-green;
	}
	&.o-portal-status--okay {
		border-color: $status-green;
	}
	&.o-portal-status--overdue {
		border-color: $status-orange-light;
	}
	&.o-portal-status--overdue-very {
		border-color: $status-red;
	}
	&.o-portal-status--on-arrangement,
	&.o-portal-status--warning {
		border-color: $status-orange-dark;
	}
}

.c-portal-card__layout {
	position: relative;

	@include flex-extend;
	align-items: stretch;

	@include bp-below($portal-card-bp) {
		flex-direction: column;
	}
}

.c-portal-card__head {
	@include bp-below($portal-card-bp) {
		position: absolute;
		right: 50%;
		transform: translateX(50%);

		@include flex-extend;
	}
}

.c-portal-card__heading {
	position: absolute;
	top: 0;
	left: 0;
	@include font-12b;
	line-height: 1;

	@include bp-below($portal-card-bp) {
		position: static;
		margin-right: $ssu;

		white-space: nowrap;
	}
}

.c-portal-card__media {
	@include flex-extend;
	flex-shrink: 0;
	justify-content: center;

	@include bp-below($portal-card-bp) {
		padding-top: $bsu;
	}
	@include bp($portal-card-bp) {
		width: 200px;
		margin: 0 $bsu;
	}
}

.c-portal-card__media + .c-portal-card__body {
	@include bp-below("small") {
		margin-top: $ssu;
	}
	@include bp("small") {
		@include bp-below($portal-card-bp) {
			margin-top: $bsu;
		}
	}
	@include bp($portal-card-bp) {
		margin-left: $lsu;
	}
}

.c-portal-card__body {
	flex-grow: 1;

	@include flex-extend;
	flex-direction: column;
	align-items: stretch;

	> :last-child {
		margin-bottom: 0;
	}
	@include bp($portal-card-bp) {
		position: relative;
	}
}

.c-portal-card__id {
	margin-bottom: $bsu;
	align-self: flex-start;

	font-size: 12px;
	color: $body-color;
	white-space: nowrap;
}

.c-portal-card__body-heading {
	display: block;
	margin-top: $ssu;
	@include bp($portal-card-bp) {
		margin-top: $ssu - 44px;
	}
	margin-bottom: $ssu;

	@include font-15;
	text-align: center;
}

.c-portal-card__notification-list {
	~ .c-portal-card__repayment .c-portal-card__body-heading {
		@include bp($portal-card-bp) {
			margin-top: $bsu;
		}
	}
}

.c-portal-card__body-text {
	@include font-15;
}

.c-portal-card__notification-list {
	.c-notification {
		display: none;

		&:first-of-type {
			display: flex;
		}
	}
}

.c-portal-card__notification-list-btn,
.c-portal-card__body-actions {
	margin-top: $ssu;
}