﻿// CONTROL.CTA-BUTTON

.c-cta-button__body {
	@include link-transition;
	display: block;
	text-align: center;

	&:hover,
	&:focus {
		border-color: $brand;
	}
}

.c-cta-button__heading {
	color: $brand;
}