﻿// CONTROL.STORY_ROTATOR

.c-story-rotator {
	padding-bottom: 30px;
	.flickity-page-dots {
		// Negative to break out of the viewport
		bottom: -30px;
	}
	@include bp('large') {
		padding-bottom: 0;
		.flickity-page-dots {
			display: none;
		}
	}
}

.c-story-rotator__viewport {
	position: relative;
	height: 520px;
	background-position: center;
	background-size: cover;

	.flickity-prev-next-button {
		display: none;
	}

	.flickity-prev-next-button {
		color: $white;

		&:disabled {
			color: $grey;
			cursor: auto;
			pointer-events: none;
		}
	}

	@include bp('large') {
		.flickity-prev-next-button {
			display: block;
		}

		&.hide-arrows .flickity-prev-next-button {
			display: none;
		}
	}
}

.c-story-rotator__item {
	position: absolute;
	height: 520px;
	width: 100%;
	z-index: 1;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	&:after {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
	}
}

.c-story-rotator__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	object-fit: cover;

	> .o-fit {
		padding: 0;
		height: 100%;
	}
}

.c-story-rotator-content {
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
	text-align: center;

	@include bp('large') {
		padding: 40px;
	}

	@include bp('extra-large') {
		padding: 0;
	}
}


.c-story-rotator-title {
	@include font-20b;
	margin-bottom: $bsu;
	color: $white;

	@include bp('large') {
		@include font-28b;
		margin-bottom: $bsu*2;
	}
}

.c-story-rotator-abstract {
	@include font-15;
	margin-bottom: $bsu;
	color: $white;

	@include bp('large') {
		margin-bottom: $bsu*2;
		@include font-20;
	}
}