﻿// CONTROL.IMAGE-FEATURE

.c-image-feature {
	padding: $bsu;
	background-color: $grey--lightest;

	@include bp('medium') {
		@include flex-extend;
		align-items: center;
		padding: 0;
	}
}

.c-image-feature--right {

	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-image-feature__figure {
	flex: 0 0 50%;
	margin-bottom: $bsu;

	@include bp('medium') {
		margin-bottom: 0;
	}
}

.c-image-feature__body {
	text-align: center;

	@include bp('medium') {
		padding: $lsu;
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-28b;
	margin-bottom: 0.7em;
}

.c-image-feature__text {
	margin-bottom: $bsu;

	p {
		@include font-14;
	}

	*:last-child {
		margin-bottom: 0;
	}
}