﻿// COMPONENT.INTERACTIVE-MAP

.c-map-container {
	width: 100%;
	height: 100%;

	position: relative;
}

.c-map__prompt {
	display: none;

	@include abs-center;
	z-index: 1;

	background: $white;
	@include ui-border('all');
	padding: $bsu;
	width: 300px;
	max-width: 100%;
}

.c-interactive-map-infowindow {
	//position: relative;
	@include pod-shadow;
	width: 240px;
	padding: 10px;
	background: $white;
	border-radius: 5px;

	&:after {
		@include pseudo-element;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 14px 7px 0 7px;
		border-color: $white transparent transparent transparent;
		z-index: 1;
	}
}

.c-interactive-map-infowindow__icon {
	position: absolute;
	right: 10px;
	bottom: 15px;
	color: $brand;
	font-size: 20px;
}

.c-map-infobox-branch-region {
	@include font-12;
}

.c-map-infobox-branch-title {
	@include font-20b;
	margin-top: $tsu;
}

.c-map-infobox-branch-address {
	@include font-12;
	position: relative;
	padding-left: $bsu;
	margin-top: $ssu;
}

.c-map-infobox-branch-address__icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 15px;
}

.c-map-infobox-branch-directions {
	@include font-12;
	position: relative;
	padding-left: $bsu;
	margin-top: $tsu;
}

.c-map-infobox-branch-contact {
	@include font-12;
	position: relative;
	padding-left: $bsu;
	margin-top: $ssu;
}

.c-map-infobox-branch-contact__icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 15px;
}

.infowindow > img {
	padding-right: 10px;
	padding-top: 10px;
}