.c-portal-header__spacer {
	height: $portal-header-height-mobile;
	@include bp($portal-nav-bp) {
		display: none;
	}
}

.c-portal-header {
	@include flex-extend;
	align-items: stretch;

	height: $portal-header-height-mobile;
	@include bp($portal-nav-bp) {
		height: $portal-header-height-desktop;
	}
	width: 100%;

	@include bp-below($portal-nav-bp) {
		position: fixed;
		z-index: 2;
		top: 0;
	}

	background: $white;
	box-shadow: 0 0 12px rgba($black, 0.2);
}

.c-portal-header__logo {
	@include flex-extend;
	align-items: center;

	margin-left: $bsu;
	margin-right: $lsu;

	@include bp($portal-nav-bp) {
		min-width: $portal-nav-size-desktop--expanded - $bsu - $lsu;
	}
}

.c-portal-header__logo-image {
	display: block;
	width: 192px;
}

.c-portal-header__welcome {
	@include bp-below($portal-nav-bp) {
		display: none;
	}

	@include bp($portal-nav-bp) {
		@include flex-extend;
		align-items: center;

		padding: 0 $lsu;

		strong {
			// Not sure why, exactly, but the spacing here is bad
			display: inline-block;
			margin-left: 3px;
		}
	}
}

.c-portal-header__action {
	@include bp-below($portal-nav-bp) {
		display: none;
	}
}
