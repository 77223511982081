// COMPONENT.COOKIE-CONFIRM

.c-cookie-confirm {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: none;
	background: rgba($grey--darkest, 0.7);
}

.c-cookie-confirm__body {
	padding: $tsu 0;
	color: $white;

	&, .c-cookie-confirm__message {
		a {
			&,
			&:hover,
			&:focus {
				color: $white;
				text-decoration: underline;
			}
		}
	}

	display: flex;
	align-items: center;

	@include bp("large") {
		position: relative;
		padding-right: $lsu;
	}
}

.c-cookie-confirm__message {
	margin-bottom: $ssu;
	margin-top: 0 !important;
	padding-right: $ssu;

	@include bp('medium') {
		flex: 1 1 auto;
		margin: 0;
	}
}

.c-cookie-confirm__button {
	flex: 0 1 auto;

	@include bp('medium') {
		margin-left: $ssu;
	}
}
