// OBJECT.CTA

.o-cta__body {
	padding: $bsu;
	margin: $bsu 0;

	> *:last-child {
		margin: 0;
	}
}

.o-cta__heading {
	@include font-20b;
	margin-bottom: 0.7em;
	color: $white;
}

.o-cta__text {
	@include font-14;
	color: $body-color;
}