.c-portal-app-progress {
	text-align: center;
}

.c-portal-app-progress__amount {
	display: block;

	@include font-40;
}

.c-portal-app-progress__status {
	display: block;
	margin-top: $ssu;

	@include font-12bu;
	color: $body-color;
}

.c-portal-app-progress__link-text {
	margin-top: $bsu;

	@include font-12b;
}