#modal-session-clear {
	.c-modal__body {
		padding: 0;
	}
}

.c-timeout {
	&:focus {
		outline: none;
	}

	@include flex-extend;
	flex-direction: column;
	align-items: center;

	max-width: 500px;

	padding-bottom: 20vh;
	max-height: 100vh;
	overflow: hidden;
}

.c-timeout__logo {
	display: block;
	margin: 0 auto;

	width: 300px;

	.c-timeout__logo-image, & img {
		padding: 0;
		position: relative;
		background-color: transparent !important;
	}
}

.c-timeout__logo + .c-timeout__text {
	margin-top: $bsu;
}

.c-timeout__text {
	> p:last-child {
		margin-bottom: 0;
	}
}
