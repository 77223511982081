﻿// CONTROL.POD-CAROUSEL


.c-pod-carousel {

	@include bp('extra-large') {
		display: flex;
		padding-left: 15%;
		.flickity-page-dots {
			display: none;
		}
	}
}
.c-pod-carousel-header {
	text-align: center;
	margin-bottom: $bsu;

	@include bp('extra-large') {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: left;
		width: 25%;
		padding-right: 50px;
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.c-pod-carousel-heading {
	@include font-28b;
	margin-bottom: $bsu;
}

.c-pod-carousel-abstract {
	display: none;

	@include bp('extra-large') {
		display: block;
		text-align: left;
		margin-bottom: $bsu;
	}
}

.c-pod-carousel__items {
	@include list-reset;
	@include clearfix;
	margin-bottom: 50px;

	padding-bottom: 30px;
	.flickity-page-dots {
		bottom: 0;
	}

	@include bp('extra-large') {
		width: 75%;
		margin-bottom: 0;
	}
}

.c-pod-carousel__item {
	//display: inline-block;
	width: 100%;
	padding: $ssu;
	padding-bottom: 10px;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	@include bp('medium') {
		width: 50%;
		float: left;
		padding-bottom: 30px;

		&:nth-child(n+2) {
			display: inline-block;
		}

		&:nth-child(n+3) {
			display: none;
		}
	}

	@include bp('large') {
		width: 33.33%;
		float: left;

		&:nth-child(n+3) {
			display: block;
		}

		&:nth-child(n+4) {
			display: none;
		}
	}
}

.c-pod-carousel__item-body {
	position: relative;
	top: 0;
	margin: 0;
	border-radius: 5px;
	@include pod-shadow;

	@include bp('medium') {
		background: $white;
	}

	&:hover, &:focus {
		top: -3px;
	}
}

.c-pod-carousel__item-content {
	padding: 30px $bsu $bsu;
}

.c-pod-carousel__title {
	@include font-20b;
	color: $brand;
}

.c-pod-carousel__text {
	@include font-15;
	color: $body-color;
}

.c-pod-carousel__item-img {
	position: relative;
}

.c-pod-carousel-tag {
	@include font-12;
	position: absolute;
	bottom: -15px;
	left: $bsu;
	padding: 8px $bsu;
	color: $white;
	background-color: $brand;
	border: 0;
	border-radius: 30px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
}

.c-pod-carousel__view-all-large {
	display: none;

	@include bp('extra-large') {
		display: inline-block;
	}
}

.c-pod-carousel__controls {
	display: none;

	@include bp('extra-large') {
		display: block;
	}

}

.c-pod-carousel__arrow {
	color: $brand;
	cursor: pointer;
	font-size: 30px;

	& + & {
		margin-left: $bsu;
	}

	&.is-disabled {
		color: $grey;
		cursor: auto;
		pointer-events: none;
	}
}