﻿
.c-cta-tiles__container {
	display: flex;
	justify-content: space-around;
	padding-top: 20px;
	@include bp('medium') {
		padding-top: 30px;
	}
}
.c-cta-tiles__tile{
	text-align:center;
	padding: 0 15px;
	margin-bottom:20px;
	@include bp('medium'){
		padding:0 20px;
		margin-bottom:40px;
	}
	a{
		display:block;
	
		font-size:16px;
		
		margin:0 auto;
		@include bp('medium'){
				width:220px;
		padding:15px ;
		}
	}
}
.c-cta-tiles__icon{
	width:100px;
	height:75px;
	margin:0 auto;
	margin-bottom:20px;
}