﻿// CONTROL.FILTER(in Listing)

@use "sass:math";

.c-filter {
	margin-bottom: $bsu;

	.c-form__filter {
		max-width: none;
	}

	@include bp('medium') {
		margin-bottom: $bsu*2;

		.c-form__filter {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.c-filter__field {
	margin-bottom: $bsu;

	@include bp('medium') {
		display: inline-flex;
		align-items: center;
		margin-bottom: 0;
	}
}

.c-filter__label {
	display: block;
	@include font-20b;
	margin-bottom: math.div($ssu, 2);

	@include bp('medium') {
		display: inline;
		margin-right: $bsu;
		margin-bottom: 0;
	}
}

.c-filter__dropdown {
	@include bp('medium'){
		display: inline-block;
	}
}

.c-filter__btn {
	display: block;
	margin-left: auto;

	@include bp('medium') {
		//display: inline-block;
		margin-left: $bsu;;
	}
}
