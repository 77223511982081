﻿// CONTROL.CTA-VERBOSE

.c-cta-verbose {

}

.c-cta-verbose__body {
	@include gradient-bg__to-right;
	border-radius: 5px;
	text-align: center;

	@include bp('medium') {

		.o-layout-main & {
			@include flex-extend;
			padding: $bsu $bsu;
			align-items: center;
			text-align: left;
		}
	}

	@include bp('extra-large') {

		.o-layout-main & {
			padding: $bsu $lsu;
		}
	}

}

.c-cta-verbose__body--no-bg {
	@include flex-extend;
	background: none;
	padding: $bsu 0;
	align-items: center;
	text-align: center;
	justify-content: center;
	flex-direction: column;

	@include bp('medium') {
		justify-content: flex-start;
		text-align: left;
		flex-direction: row;
	}
}

.c-cta-verbose__body--regular {
	@include flex-extend;
	background: none;
	padding: 0;
	align-items: center;
	text-align: center;
	flex-direction: column;

	@include bp('medium') {
		text-align: left;
		flex-direction: row;
	}
}

.c-cta-verbose__body-wrapper {
	margin-bottom: $bsu;

	@include bp('medium') {

		.o-layout-main & {
			flex: 1 1 auto;
			padding-right: $lsu;
			margin-bottom: 0;
		}

		.c-cta-verbose__body--no-bg & {
			flex: 1 1 auto;
			padding-right: $lsu;
			margin-bottom: 0;
		}

		&.c-cta-verbose__heading-only {
			.c-cta-verbose__body--no-bg & {
				flex: inherit;
			}
		}

		.c-cta-verbose__body--regular & {
			flex: inherit;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta-verbose__body--no-bg .o-cta__heading {
	color: var(--primary-a);
	text-align: center;

	@include bp('medium') {
		text-align: left;
	}
}

.c-cta-verbose__text {
	@include font-16;
	color: $white;
	margin-bottom: 2em;

	.c-cta-verbose__body--no-bg & {
		@include font-16;
		color: $brand;
	}

	.c-cta-verbose__body--regular & {
		@include font-16;
		color: $black;
	}
}

.c-cta-verbose__link {

	@include bp('medium') {
		white-space: nowrap;
	}
}