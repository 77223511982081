﻿// CONTROL.IMAGE-CAROUSEL

@use "sass:math";

.c-image-carousel__items {
	@include list-reset;
	@include clearfix;

	.flickity-button {
		color: $body-color;
	}
}

.c-image-carousel__item {
	display: inline-block;
	width: 100%;
	padding: math.div($ssu, 2);

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	@include bp('medium') {
		width: 50%;
		float: left;

		&:nth-child(n+2) {
			display: inline-block;
		}

		&:nth-child(n+3) {
			display: none;
		}
	}

	@include bp('large') {
		width: 25%;
		float: left;

		&:nth-child(n+3) {
			display: block;
		}

		&:nth-child(n+5) {
			display: none;
		}
	}
}

.c-image-carousel__slide-link {
	/*@include ui-border('all');
	padding: $lsu;*/
}

