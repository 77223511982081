// COMPONENT.HEADER

@use "sass:math";

$header-height--mobile: 50px;
$header-height--desktop: 110px;

.c-header__spacer {
	height: $header-height--mobile;
	@include bp("large") {
		display: none;
	}
}

.c-header {
	position: absolute;
	height: $header-height--mobile;
	width: 100%;
	transition: all 0.25s ease-in-out;
	background-color: $white;
	z-index: $z-header;

	&.is-up, &.is-down {
		// Disable sticky header
		position: absolute;
	}

	.nav-open & {
		// Keep it sticky while the nav's open
		&, &.is-up, &.is-down {
			position: fixed;
		}
	}

	@include bp('large') {
		position: fixed;
		top: 0;
		height: 65px;
		background-color: $white;
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		&.is-up, &.is-down {
			position: fixed;
		}

		.c-header__wrapper {
			padding-top: 12px;
			padding-bottom: 12px;
		}

		body.has-notification & {
			position: absolute;
			top: auto;

			&.is-down {
				position: fixed;
				top: 0;
			}
		}
	}

	@include bp('extra-large') {
		height: $header-height--desktop;
		background-color: transparent;
		box-shadow: none;

		.c-header__wrapper {
			padding-top: $bsu;
			padding-bottom: $bsu;
		}

		&.is-up {
			//transform: translateY(calc(-100% - 6px)); this css not support in IE 11
			transform: translateY(-100%) translateY(-6px);
		}

		&.is-down, .no-hero & {
			height: 65px;
			background-color: $white;
			box-shadow: 0 8px 6px -6px rgba($black, 0.2);

			.c-header__wrapper {
				padding-top: 12px;
				padding-bottom: 12px;
			}

			.c-header__logo-large {
				display: none;
			}

			.c-header__logo-small {
				display: block;
			}

			.c-header__menu-text {
				color: $green;
			}

			.c-header__nav-toggle b {
				border-color: $green;
			}

			.c-header__apply-btn {
				background: $orange;
				border-color: $orange;
				color: $white;

				&:hover, &:focus {
					background: $white;
					color: $orange;
				}
			}

			.c-header__myif-btn {
				background-color: $white;
				color: $brand;
				border-color: $brand;

				&:hover, &:focus {
					background: $brand;
					color: $white;
				}
			}
		}
	}
}

.c-header__wrapper {
	@include flex-extend;
	position: relative;
	align-items: center;
	justify-content: space-between;
	padding: 6px $bsu;

	@include bp('large') {
		padding: $bsu;
	}
}

.c-header__logo-large {
	display: none;

	@include bp('extra-large') {
		display: block;

		img {
			height: 67px;
		}
	}
}

.c-header__logo-small {

	img {
		height: 30px;
	}

	@include bp('extra-large') {
		display: none;
	}
}

.c-header__actions {
	@include flex-extend;
	align-items: center;
}

// Mobile buttons
$menu-button-size: 30px;
$menu-button-color: $green;
$menu-button--hover-color: $brand--dark;
$menu-button-font-size: 20px;
$menu-button-padding: math.div(($menu-button-size - $menu-button-font-size), 2);
.c-header__menu {
	@include button-reset;
	text-align: center;
	cursor: pointer;


	@include bp('large') {
		&:hover, &:focus {
			.c-header__menu-text {
				color: $menu-button--hover-color;
			}

			b {
				border-color: $menu-button--hover-color;
			}
		}
	}

	position: relative;
	&::before {
		content: "";
		@include abs-center;
		width: 44px;
		height: 44px;
	}
}
.c-header__menu-icon {
	position: relative;
	width: $menu-button-size;
	height: $menu-button-size;
	padding: $menu-button-padding 0;
	border: 0;
	background: none;
	cursor: pointer;
}
.c-header__menu-text {
	@include font-8u;
	position: relative;
	top: -2px;
	color: $green;
	transition: color 0.3s ease-in-out;

	@include bp('large') {
		@include font-10u;
	}

	@include bp('extra-large') {
		color: $white;
	}
}
// Nav Toggle
.c-header__nav-toggle {

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: 5px;
		display: block;
		width: 20px;
		height: 0;
		border-top: 2px solid $green;

		&:nth-child(1) {
			top: 8px;
		}

		&:nth-child(2) {
			top: 15px;
		}

		&:nth-child(3) {
			top: 22px;
		}
	}

	[aria-expanded="true"] & {

		b {
			&:nth-child(1) {
				transform: translateY(7px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-7px) rotate(-45deg);
			}
		}
	}


	[aria-expanded="true"] & {
		b {
			border-color: $menu-button--hover-color;
		}
	}

	@include bp('large') {
		b {
			width: 26px;
			left: 2px;
		}
	}

	@include bp('extra-large') {
		b {
			border-color: $white;
		}
	}
}

.c-header__btns {
	display: block;
	margin-right: $ssu;
	@include bp("large") {
		margin-right: $ssu*3;
	}
}

.c-header__apply-btn {
	@include bp-below("large") {
		display: none;
	}
}

.c-header__myif-btn {
	@include bp-below("medium") {
		padding: $ssu;
		white-space: nowrap;
	}
}

.c-header__apply-btn {
	background: $orange;
	border: 1px solid $orange;
	color: $white;

	&:hover, &:focus {
		background: $white;
		color: $orange;
	}

	@include bp('extra-large') {
		background: $white;
		border-color: $brand;
		color: $brand;
		&:hover, &:focus {
			background: $orange;
			border-color: $orange;
			color: $white;
		}
	}
}

.c-header__myif-btn {
	background-color: $white;
	color: $brand;
	border: 1px solid $brand;
	margin-left: $ssu;

	&:hover, &:focus {
		background: $brand;
		color: $white;
	}

	@include bp('extra-large') {
		background-color: transparent;
		color: $white;
		border-color: $white;

		&:hover, &:focus {
			background: $orange;
			border-color: $orange;
		}
	}
}

@include bp-below('medium') {
	.c-header.is-up + .c-breadcrumb__mobile {
		padding-top: 0;
	}

	.c-header.is-up + .o-layout-page {
		padding-top: 0;
	}
}
