﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	margin-top: $lsu;
	margin-bottom: $lsu;
}

.c-breadcrumb__item {
	color: $brand;
}

.c-breadcrumb__link {
	@include link-brand;
	@include font-12;
}

.c-breadcrumb__current {
	@include link-plain;
	@include font-12;
	color: $body-color;
}

.c-breadcrumb__mobile {
	.c-breadcrumb {
		margin-top: 0;
		margin-bottom: $ssu;
	}

	@include bp('large') {
		display: none;
	}
}

.c-breadcrumb__desktop {
	display: none;

	@include bp('large') {
		display: block;
	}
}