$loan-info-bp: "medium";

.c-portal-loan {}

.c-portal-loan__head {
	@include flex-extend;
	align-items: center;
	justify-content: center;

	padding: $bsu 0;
}

.c-portal-loan__intro {
	max-width: 760px;
	margin: 0 auto $bsu;
}

//////////
// Info //
//////////
.c-portal-loan__info {
	@include flex-extend;
	flex-wrap: wrap;

	@include bp($loan-info-bp) {
		margin-left: -$hsu;
	}

	margin-bottom: $lsu;
}

.c-portal-loan__info-column {
	width: 100%;
	margin-top: 0;
	@include bp($loan-info-bp) {
		margin-top: -$bsu;
		width: 50%;
		padding-left: $hsu;
	}
}

.c-portal-loan__info-item {
	padding-top: $ssu;
	@include bp($loan-info-bp) {
		padding-top: $bsu;
	}
	break-inside: avoid;

	@include flex-extend;
}

.c-portal-loan__info-label {
	width: 135px;
	flex-shrink: 0;
	padding: $ssu $ssu $ssu 0;

	@include font-15;

	.c-tooltip {
		top: -4px;
		margin-left: 13px;
	}
}

.c-portal-loan__info-value {
	flex-grow: 1;

	background: $grey--lightest;
	padding: $ssu;
	border-radius: 2px;

	@include font-15;

	p {
		margin-bottom: 0;
	}
}

//////////////////
// TRANSACTIONS //
//////////////////
.c-portal-loan__transactions {
	background: $grey--lightest;
	padding: $lsu 0;
}

.c-portal-loan__transactions-head {
	position: relative;
}

.c-portal-loan__transactions-head-detail {
	margin-top: $bsu;

	@include flex-extend;
	align-items: flex-start;
	justify-content: space-between;
}

.c-portal-loan__transactions-heading {
	@include font-28b;
}

.c-portal-loan__transactions-meta {
	display: block;

	@include font-12;

	margin-bottom: $bsu;
	@include bp("medium") {
		margin-bottom: 0
	}
}

.c-portal-loan__transactions-statement {
	@include bp-below($loan-info-bp) {
		margin-left: $ssu;
	}
	@include bp($loan-info-bp) {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.c-portal-loan__transactions-table {
	margin: $bsu 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	width: 100%;

	background: $white;
	border-radius: 8px;
	box-shadow: 0 12px 12px rgba($black, 0.2);

	border-collapse: collapse;

	th {
		padding: $ssu;
		@include bp("small") {
			padding: $bsu;
		}
		text-align: left;

		@include font-15b;
	}
	td {
		padding: $tsu $ssu;
		@include bp("small") {
			padding: $ssu $bsu;
		}

		@include font-15;
	}

	tbody {
		tr:nth-child(2n+1) {
			background: $grey--lightest;
			td {
				border-top: 1px solid $grey--light;
				border-bottom: 1px solid $grey--light;
			}
		}
		tr:last-child {
			border-radius: 0 0 8px 8px;
		}
	}

	.c-portal-loan__transactions-amount {
		text-align: right;
	}
}

.c-portal-loan__transactions-table-th {
	// Use a class inside the <th> so as not to disturb table layout
	display: inline;
	display: inline-flex;
	align-items: center;

	.c-tooltip {
		margin-left: $ssu;
	}
}
