// COMPONENT.TOOLTIP

@use "sass:math";

$tooltip-bp: "medium";

$tooltip-trigger-size: 24px;
$tooltip-trigger-size--small: 15px;
$tooltip-arrow-size: 10px;
$tooltip-width: 370px;

@keyframes tooltipAttentionGrabber {
	  0% { transform: scale(1); }
	 50% { transform: scale(1.5); }
	100% { transfrom: sclae(1); }
}

.c-tooltip {
	position: relative;

	display: inline-block;
	height: $tooltip-trigger-size;

	&.c-tooltip--attention-grabber {
		.c-tooltip__trigger {
			animation: 0.5s tooltipAttentionGrabber forwards;
		}
	}
}

.c-tooltip__trigger {
	position: relative;
	display: block;

	width: $tooltip-trigger-size;
	height: $tooltip-trigger-size;

	border: 0;
	border-radius: 100%;

	text-decoration: none;
	line-height: 1;
	color: $white;
	background: $brand;

	cursor: pointer;
	// Needed so iOS doesn't turn it into an ellipse instead of a circle
	font-size: 0;

	&::before {
		content: "?";

		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		font-size: math.div($tooltip-trigger-size, 1.5);
		font-weight: $weight--bold;
		text-align: center;
		line-height: $tooltip-trigger-size;
	}

	&:hover,
	&:focus {
		background-color: $brand--dark;
	}
	@include focus-visible;

	position: relative;
	&::after {
		content: "";
		@include abs-center;
		width: 44px;
		height: 44px;
	}
}

.c-tooltip__trigger--link {
	margin-top: 5px;
}

.c-tooltip--small {
	height: $tooltip-trigger-size--small;
	.c-tooltip__trigger {
		width: $tooltip-trigger-size--small;
		height: $tooltip-trigger-size--small;
		font-size: 0;

		&::before {
			font-size: math.div($tooltip-trigger-size--small, 1.5);
			line-height: $tooltip-trigger-size--small;
		}
	}
	@include bp($tooltip-bp) {
		.c-tooltip__body {
			margin-left: math.div($tooltip-trigger-size--small, 2);
		}
	}
}

.c-tooltip__body {
	position: absolute;
	z-index: $z-tooltip;
	visibility: hidden;

	width: $tooltip-width;

	padding: $bsu;
	margin-top: -($ssu + 3px);
	margin-left: math.div($tooltip-trigger-size, 2);

	line-height: lh($base-line-height, $base-font-size);
	color: $white;
	a {
		@include link-plain;
	}
	@include gradient-bg__to-bottom-right;

	transform: translateY($tooltip-trigger-size) translateX(-50%);


	@include bp-below($tooltip-bp) {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		transform: none;
		margin: 0;
		padding-top: $lsu;
	}

	box-shadow: 0 5px 10px 3px rgba($black, 0.2);

	@include bp($tooltip-bp) {
		&::before {
			content: "";

			position: absolute;
			width: 0;
			height: 0;
			top: -$tooltip-arrow-size;
			left: 50%;
			margin-left: -$tooltip-arrow-size;
			margin-top: 0;

			border-left: $tooltip-arrow-size solid transparent;
			border-bottom: $tooltip-arrow-size solid var(--primary-a);
			border-right: $tooltip-arrow-size solid transparent;
			border-top: 0;
		}
	}

	.c-tooltip__close + * {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	img {
		max-width: 100%;
	}
}
@include bp-below($tooltip-bp) {
	.mobile-show-bottombar {
		.c-tooltip__body {
			bottom: $mobile-footer-bottombar-height - 1px;
		}
	}
}

.c-tooltip {
	&[aria-expanded="false"] {
		.c-tooltip__body {
			animation: 0.3s becomeHidden forwards;
		}
	}
	&[aria-expanded="true"] {
		.c-tooltip__body {
			animation: 0.3s becomeVisible forwards;
		}
	}
	@include bp-below($tooltip-bp) {
		.c-tooltip__body {
			transition: 0.3s transform;
		}
		&[aria-expanded="false"],
		&:not([aria-expanded]) {
			.c-tooltip__body {
				transform: translateX(100%);
			}
		}
		&[aria-expanded="true"] {
			.c-tooltip__body {
				transform: translateX(0);
			}
		}
	}
	@include bp($tooltip-bp) {
		.c-tooltip__body {
			transition: 0.3s opacity;
		}
		&[aria-expanded="false"],
		&:not([aria-expanded]) {
			.c-tooltip__body {
				opacity: 0;
			}
		}
		&[aria-expanded="true"] {
			.c-tooltip__body {
				opacity: 1;
			}
		}
	}

	&.c-tooltip--no-animation {
		.c-tooltip__body {
			animation: none !important;
		}
	}
}

@include bp($tooltip-bp) {
	.c-tooltip.c-tooltip--top {
		.c-tooltip__body {
			margin-top: -($tooltip-trigger-size + $tooltip-arrow-size + $ssu);
			transform: translateY(-100%) translateX(-50%);

			&::before {
				top: auto;
				bottom: -$tooltip-arrow-size;
				border-bottom: 0;
				border-top: $tooltip-arrow-size solid var(--primary-a);
			}
		}
	}

	.c-tooltip.c-tooltip--left,
	.c-tooltip.c-tooltip--right {
		.c-tooltip__body {
			margin-top: $tooltip-arrow-size + $ssu;
			margin-left: $tooltip-trigger-size + $bsu;

			&::before {
				top: -$tooltip-arrow-size;
				bottom: auto;
				border-left: $tooltip-arrow-size solid transparent;
				border-right: $tooltip-arrow-size solid transparent;
				border-top: 0;
			}
		}

		&.c-tooltip--small {
			.c-tooltip__body {
				margin-left: $tooltip-trigger-size--small + $bsu;
			}
		}

		&.c-tooltip--top {
			.c-tooltip__body {
				margin-top: -($tooltip-trigger-size + $tooltip-arrow-size + $ssu);

				&::before {
					top: auto;
					bottom: -$tooltip-arrow-size;

					border-bottom: 0;
				}
			}

			&.c-tooltip--small {
				.c-tooltip__body {
					margin-bottom: $tooltip-trigger-size--small + $tooltip-arrow-size + $ssu;
				}
			}
		}
	}

	.c-tooltip.c-tooltip--left {
		.c-tooltip__body {
			transform: translateY(0) translateX(-100%);

			&::before {
				left: auto;
				right: $bsu - $tooltip-arrow-size + math.div($tooltip-trigger-size, 2);

				border-bottom: $tooltip-arrow-size solid $gradient-mid-color--right-top;
			}
		}

		&.c-tooltip--small {
			.c-tooltip__body {
				&::before {
					right: $bsu - $tooltip-arrow-size + math.div($tooltip-trigger-size--small, 2);
				}
			}
		}

		&.c-tooltip--top {
			.c-tooltip__body {
				transform: translateY(-100%) translateX(-100%);

				&::before {
					border-top: $tooltip-arrow-size solid $gradient-mid-color--right-bottom;
				}
			}
		}
	}

	.c-tooltip.c-tooltip--right {
		.c-tooltip__body {
			transform: translateY(0) translateX(0);

			&::before {
				left: $bsu - $tooltip-arrow-size + math.div($tooltip-trigger-size, 2);
				right: auto;

				border-bottom: $tooltip-arrow-size solid $gradient-mid-color--left-top;
			}
		}

		&.c-tooltip--small {
			.c-tooltip__body {
				&::before {
					left: $bsu - $tooltip-arrow-size + math.div($tooltip-trigger-size--small, 2);
				}
			}
		}

		&.c-tooltip--top {
			.c-tooltip__body {
				transform: translateY(-100%) translateX(0%);

				&::before {
					border-top: $tooltip-arrow-size solid $gradient-mid-color--left-bottom;
				}
			}
		}
	}
}

.c-tooltip__close {
	@include button-reset;
	@include font-icon;

	position: absolute;
	right: 3px;
	top: 3px;

	padding: math.div((44px - 16px), 2);
	font-size: 16px;
	color: $white;

	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $white;
	}
	&:focus {
		@include focus-outline($white);
	}

	&::before {
		content: $iconf-close;
	}
}

.c-tooltip__hide {
	margin-top: $bsu;

	@include bp($tooltip-bp) {
		display: none;
	}
}

.c-tooltip__text {
	padding-right: 24px;
}

// Use nth-child because the first and last elements are buttons
.c-tooltip__text,
.c-tooltip__image {
	margin: $ssu 0;

	:first-child,
	&:nth-child(2) {
		margin-top: 0;
	}

	:last-child,
	&:nth-last-child(2) {
		margin-bottom: 0;
	}
}
