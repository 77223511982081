/* OBJECT.LAYOUT */
$header-height--mobile: 50px;
$notif-height--mobile: 50px;

body {
	/*max-width: 1920px;
	margin: 0 auto;*/
	&.nav-open {
		//height: calc(100vh + 10px);
		//overflow: hidden;
	}
}

.o-layout-wrapper {
	@include layout-wrapper;

	// Remove padding on sides if a layout-wrapper is nested within another layout-wrapper
	.o-layout-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.o-layout-wrapper--relative {
	position: relative;
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
}

.o-layout-page {
	position: relative;
	z-index: $z-page;

	@include bp('large') {
		.no-hero & {
			padding-top: 65px;
		}
	}
}

.o-layout-content--has-aside {

	@include bp('large') {
		@include flex-extend;
	}
}

.o-layout-main {
	display: block;


	.o-layout-content--has-aside & {

		@include bp('large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp('large') {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}
}

.o-layout-header {
	margin-bottom: $bsu;
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}

	@include bp('large') {
		margin-bottom: $hsu;
	}
}

.o-layout-header__heading {

	color: $body-color;
	@include font-50sbu;
	margin-bottom: 0.3em;
}

.o-layout-header__lede {
	@include font-20;
	max-width: calc(#{$layout-wrapper-narrow-width} - 40px);

	margin: 0 auto;
	text-align: left;
	color: $brand;

	a {
		@include link-brand;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header__date {
	@include font-12;
	margin-bottom: 2em;
}

//Branch Listing
.o-layout-page__map {
	display: flex;

	.c-breadcrumb {
		margin-top: $bsu;
		margin-bottom: $bsu;
	}

	@include bp('large') {
		min-height: 100vh;
	}
}

.o-layout-page__map-map__container {
	display: none;

	@include bp('large') {
		display: block;
		width: 450px;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
	}

	@include bp('extra-large') {
		width: 450px;
	}
}

.o-layout-page__map-content {
	max-width: 100%;
	width: 100%;

	.o-layout-header__heading {
		margin-bottom: $bsu;
	}

	@include bp('large') {
		width: calc(100% - 450px);
		margin-left: auto;
		margin-right: 0;
	}

	@include bp('extra-large') {
		width: calc(100% - 450px);
		padding-left: 50px;
		padding-right: 5%;

		.o-layout-header__heading {
			margin-bottom: $bsu*2;
		}
	}
}

// IF-113 iPad-only template has a horizontal scrolling issue
.o-layout-ipad {
	width: 100%;
	overflow-x: hidden;
}
