.c-tab-nav {
	@include bp-below($tab-nav-bp) {
		display: none;
	}
}

.c-tab-nav--mobile {
	display: none;

	@include bp-below($tab-nav-bp) {
		display: block;
		margin: $bsu 0;

		.c-tab-nav__heading {
			display: block;
			margin-bottom: $bsu;

			@include font-16b;
		}

		.c-tab-nav__list {
			@include list-reset;
		}

		.c-tab-nav__item {
			margin-bottom: $tsu;
			&:last-child {
				margin-bottom: 0;
			}
		}

		.c-tab-nav__link {
			display: block;
			padding: $ssu $lsu $ssu $bsu;

			@include font-16b;

			border: 1px solid $grey--light;
			border-radius: 4px;

			box-shadow: 0 4px 8px rgba($black, 0.1);

			position: relative;
			&::after {
				position: absolute;
				top: 50%;
				right: $bsu;
				transform: translateY(-50%);

				font-size: 20px;

				@include font-icon;
				content: $iconf-arrow-right;

				@include link-transition;
			}

			&:hover,
			&:focus {
				&::after {
					transform: translate($ssu, -50%);
				}
			}
		}

		.c-tab-nav__item[aria-current="page"] {
			display: none;
		}
	}
}

@include bp($tab-nav-bp) {
	.c-tab-nav {
		background: $grey--lightest;
		margin-bottom: $lsu;
	}

	.c-tab-nav__heading {
		display: none;
	}

	.c-tab-nav__list {
		@include list-reset;
		@include flex-extend;
		align-items: stretch;

		margin-left: -$bsu;
		margin-right: -$bsu;
	}

	.c-tab-nav__item {
		@include flex-extend;
		align-items: stretch;
		justify-content: center;
		text-align: center;
	}

	.c-tab-nav__link {
		display: block;
		padding: $bsu $bsu;

		@include font-16b;
		color: $grey--darkest;

		position: relative;
		.c-tab-nav__item[aria-current="page"] & {

			&::before {
				content: "";
				position: absolute;

				bottom: 0;
				left: $bsu;
				right: $bsu;

				border-bottom: 5px solid transparent;
			}
		}

		@include link-transition;
	}
}
