@use "sass:math";

.c-loan-calculator,
.c-loan-calculator--skeleton {
    max-width: 500px;

    @include bp("extra-large") {
        max-width: 550px;
    }

    position: relative;
    z-index: 1;
}

.c-loan-calculator {
}

.c-loan-calculator--skeleton {
    .c-form__range-input-wrap {
        padding-bottom: 3px;
    }
}

.c-loan-calculator__skeleton-item {
    &::before {
        display: inline-block;
        content: " ";
    }

    display: inline-block;
    vertical-align: bottom;
    background: rgba($black, 0.03);
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        background: rgba($white, 0.3);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2ch;
        height: 100%;
        transform: skew(-30deg);
        animation: 1s linear infinite skeleton-text;
        animation-direction: forwards;
    }
}

.c-loan-calculator__continue {
    position: relative;
    top: 30px;
    background: $brand;
    color: $white;

    :focus {
        @include focus-outline($white);
    }

    padding: 20px 50px 45px 30px;

    @include flex-extend;
    flex-wrap: wrap;
    align-items: center;

    @include bp("medium") {
        border-radius: 30px 30px 0 0;
        flex-wrap: nowrap;
    }
}

.c-loan-calculator__continue-text {
    @include font-15;
    margin-bottom: 0;
    margin-right: $ssu;
}

.c-loan-calculator__continue-close {
    @include button-reset;
    position: absolute;
    top: 5px;
    right: 0;
    padding: 14px;

    &::before {
        display: block;

        @include font-icon;
        content: $iconf-close;
        color: $white;
        font-size: 16px;
        // Stop it jumping when changing scale
        transform: scale(1.001);
        @include link-transition(transform);
    }

    &:hover,
    &:focus {
        &::before {
            transform: scale(1.5);
        }
    }
}

.c-loan-calculator__continue-button {
    @include bp-below("medium") {
        width: 100%;
        margin-top: $ssu;
    }
}

.c-loan-calculator__continue-mobile {
    display: none;
}



// Panel //
.c-loan-calculator__panel {
    background: $status-teal-dark--pale;
    background: var(--supporting-3-a);
    padding: $bsu $bsu ($lsu * 1.5);

    @include bp("medium") {
        border: 10px solid $brand--mid;
        border: 10px solid var(--primary-a);
        border-radius: 30px;
        padding: $lsu $lsu ($lsu * 1.5);
        box-shadow: 0 10px 10px 5px rgba($black, 0.2);
    }

    position: relative;
    z-index: 2;
}

.c-loan-calculator__title-label {
    @include font-16bu;
    display: none;
    color: $brand;
    margin-bottom: $ssu;
    text-align: center;

    @include bp("medium") {
        display: block;
    }
}

.c-loan-calculator__title {
    display: block;
    @include font-20b;
    text-align: left;
    position: relative;
    padding-bottom: $bsu;
    margin-bottom: $bsu;

    @include bp("medium") {
        text-align: center;
        margin-bottom: $ssu;
    }

    &::after {
        content: "";
        @include abs-center-h;
        bottom: 0;
        border-bottom: 3px solid $brand;
        width: 50px;
        max-width: 10%;
    }
}

.c-loan-calculator__form {
    // Offset some of the top margin of the first range input
    margin-top: -($ssu);

    @include bp("medium") {
        margin-top: -$bsu;
    }
}

.c-loan-calculator__range {
    .c-form__range {
        background: $white;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .c-form__range-label {
        @include font-12bu;
    }

    .c-form__range-label--name {
        order: 1;
        width: 100%;
    }

    .c-form__range-label--min {
        order: 3;
        width: 50%;
        margin: 0;
        text-align: left;
    }

    .c-form__range-input-wrap {
        order: 2;
        width: 100%;
        margin: 0;
    }

    .c-form__range-label--max {
        order: 4;
        width: 50%;
        margin: 0;
        text-align: right;
    }

    @include bp-below("small") {
        .c-form__range {
            padding: 10px;
            margin-top: 23px;
        }

        .c-form__range-label--min {
            margin-top: -5px;
        }

        .c-form__range-label--max {
            margin-top: -5px;
        }

        .c-form__range-tooltip {
            padding: 7px 10px;
        }
    }
}

.c-loan-calculator__error {
    margin-top: $bsu;
}

.c-loan-calculator__results {
    @include bp-below("medium") {
        @include flex-extend;
        flex-direction: column;
    }
}

.c-loan-calculator__payment-list {
    @include flex-extend;
    margin: $ssu 0;
}

.c-loan-calculator__payment-secondary {
    @include flex-extend;
    justify-content: space-between;
    width: percentage(math.div(1, 2));

    @include bp-below("medium") {
        margin-left: $bsu;
        flex-direction: column;
        justify-content: flex-start;
        width: auto;

        > .c-loan-calculator__payment {
            margin-bottom: $ssu;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.c-loan-calculator__payment-primary {
    color: $brand;

    .c-loan-calculator__payment-amount {
        @include font-40b;

        @include bp("large") {
            @include bp-below("extra-large") {
                @include font-32b;
            }
        }

        padding-bottom: 0;
    }

    .c-loan-calculator__payment-unit {
        display: inline-block;
        vertical-align: middle;

        @include font-28b;
        position: relative;

        @include bp("medium") {
            bottom: 3px;
        }
    }

    .c-loan-calculator__payment-amount-text {
        display: inline-block;
        vertical-align: bottom;
    }

    @include flex-extend;
    justify-content: flex-end;
    width: percentage(math.div(1, 2));

    @include bp-below("medium") {
        align-items: flex-start;
        width: auto;
        flex-grow: 1;
    }

    @include bp-below("small") {
    }
}

.c-loan-calculator__payment {
    @include flex-extend;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.c-loan-calculator__payment-frequency {
    @include font-12b;
    order: 2;
}

.c-loan-calculator__payment-amount {
    @include bp("medium") {
        padding-bottom: 5px;
    }

    @include font-20b;

    @include bp("large") {
        @include bp-below("extra-large") {
            @include font-18b;
        }
    }

    order: 1;
}

.c-loan-calculator__total {
    @include font-14;
    margin: 0;

    @include bp-below("medium") {
        @include flex-extend;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 50%;
        margin-top: -40px;
        align-self: flex-end;
    }
}

.c-loan-calculator__total-text {
    @include bp-below("medium") {
        width: 100%;
        order: 2;
        text-align: right;
    }
}

.c-loan-calculator__total-amount {
    @include font-14b;
    color: $brand;

    @include bp-below("medium") {
        order: 1;
    }
}

.c-loan-calculator__total-amount-unit,
.c-loan-calculator__total-amount-text {
    display: inline-block;
    vertical-align: bottom;
}

.c-loan-calculator__apply-wrapper {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 50%);
}

.c-loan-calculator__apply {
    @include font-20bu;
    padding: $bsu $lsu;
    border-radius: 70px;
}

.c-loan-calculator__fine-print-wrapper {
    @include flex-extend;
    align-items: center;
}

.c-loan-calculator__fine-print {
    margin-top: $lsu;
    padding: 0 10px;

    @include font-12;
    text-align: justify;
}

.c-loan-calculator__fine-print-button {
    margin-left: $bsu;
    white-space: nowrap;
}


// Homepage
.c-hero__calculator {
    .c-loan-calculator__apply {
        animation: wiggleCentre 1s 1.5s;

        @include bp("medium") {
            animation: wiggle 1s 1.5s;
        }
    }

    @include bp("medium") {
        .c-loan-calculator__panel {
            padding: $bsu $lsu $lsu;
        }
    }

    @include bp("extra-large") {
        .c-form__range {
            margin-top: $ssu*3;
            padding: $ssu*1.5;
        }

        .c-loan-calculator__title {
            margin-bottom: $bsu;
        }
    }

    @include bp("large") {
        @include bp-below("extra-large") {
            .c-loan-calculator__panel {
                padding: $bsu $lsu $lsu;
            }

            .c-loan-calculator__title {
                margin-bottom: $bsu;
            }

            .c-form__range {
                margin-top: $bsu * 1.5;
                padding: $ssu * 1.5;
            }

            .c-loan-calculator__payment-list {
                margin: $ssu;
            }
        }
    }

    @include bp-below("large") {
        .c-loan-calculator {
            margin: $bsu auto 0;
        }

        .c-loan-calculator__fine-print-wrapper {
            display: none;
        }
    }

    @include bp-below("medium") {
        .c-loan-calculator {
            max-width: none;
            width: 100%;
            position: static;
            margin-top: 0;
        }

        .c-loan-calculator__panel {
            background: transparent;
            box-shadow: none;
            border: none;
            padding: 0;
            position: static;
        }

        .c-loan-calculator__title-label,
        .c-loan-calculator__title,
        .c-loan-calculator__payment-list,
        .c-loan-calculator__payment-primary,
        .c-loan-calculator__total,
        .c-loan-calculator__total-amount {
            color: $white;
        }

        .c-loan-calculator__title {
            margin-top: $ssu;

            @include bp("medium") {
                margin-top: 0;
            }

            padding-bottom: 0;

            &::after {
                display: none;
            }
        }

        .c-loan-calculator__results {
            @include flex-extend;
            flex-direction: column;
            max-width: 600px;
            margin: 0 auto;
        }

        .c-loan-calculator__payment-list {
            width: 100%;
        }

        .c-loan-calculator__payment-primary {
            align-items: flex-start;
        }

        .c-loan-calculator__total {
            width: 50%;
            padding-left: $bsu;
            align-self: flex-end;

            @include bp-below("medium") {
                position: relative;
                top: -$lsu;
                margin-top: 0;
            }
        }

        .c-loan-calculator__apply {
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: 50%;
            transform: translate(50%, 50%);
        }
    }

    .c-loan-calculator__continue {
        @include bp-below("large") {
            display: none;
        }
    }

    .c-loan-calculator__continue-mobile {
        display: block;
        width: 100%;
        margin: $ssu auto;
        max-width: 300px;

        @include bp("large") {
            display: none;
        }
    }
}

.c-hero__calculator-fine-print-wrapper {
    margin-top: 34px + $bsu;
    display: block;

    @include bp("small") {
        @include flex-extend;
        align-items: flex-start;
    }

    @include bp("large") {
        display: none;
    }
}

.c-hero__calculator-fine-print {
    @include font-12;
    text-align: justify;

    @include bp("small") {
        order: 1;
    }
}

.c-hero__calculator-fine-print-button {
    display: block;
    white-space: nowrap;
    float: right;
    margin-left: $bsu;
    margin-bottom: $ssu;

    @include bp("small") {
        float: none;
        order: 2;
        margin: 0 0 0 $bsu;
    }
}
