﻿// COMPONENT.SHARE

@use "sass:math";

$share-icon-size: 32px;
$share-icon-font-size: 16px;

$share: #51ae5e;
$facebook: #3b5997;
$twitter: #55acef;
$pinterest: #bd2126;
$google: #d34836;
$weibo: #fa7d3c;

.c-share {
	margin: $lsu auto;

}

.c-share-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share-icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share-icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	@include font-15;
	display: block;
	margin: 0 $ssu 0 0;

	.c-share--floating & {
		display: none;
	}
}

.c-share__items {
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;
	margin: -#{$ssu} 0 0 -#{math.div($ssu, 2)};
}

.c-share__item {
	padding: $ssu 0 0 math.div($ssu, 2);
}

.c-share__icon {
	@include link-transition;
	@include circle($share-icon-size);
	display: block;
	border: 1px solid $brand;
	font-size: $share-icon-font-size;
	text-align: center;
	vertical-align: middle;
	color: $white;

	&:focus {
		outline: none;
	}

	&.iconf-facebook {
		background: $facebook;
		border-color: $facebook;

		&:hover, &:focus {
			color: $facebook;
			background: $white;
		}
	}

	&.iconf-twitter {
		border-color: $twitter;
		background: $twitter;

		&:hover,
		&:focus {
			color: $twitter;
			background: $white;
		}
	}


	&.iconf-email {
		color: var(--primary-b);
		border-color: var(--primary-a);
		background: var(--primary-a);

		&:hover,
		&:focus {
			color: var(--primary-a);
			background: var(--primary-b);
		}
	}

	&.iconf-print {
		cursor: pointer;
		color: var(--secondary-b);
		border-color: var(--secondary-a);
		background: var(--secondary-a);

		&:hover,
		&:focus {
			color: var(--secondary-a);
			background: var(--secondary-b);
		}
	}
}
