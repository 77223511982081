// COMPONENT.HERO

.c-hero__figure {
	@include bp-below("large") {
		display: none;
	}
}

.c-hero-feature-image {
	max-width: 350px;
	margin: -120px 0 0 calc(100vw - 350px);

	@include bp("large") {
		margin: 0;
		max-width: 600px;
	}
}

.c-hero__content.has-feature {
	padding-bottom: 80px;

	@include bp("medium") {
		padding-bottom: 140px;
	}
}

.c-hero__mobile-cta {
	margin: 20px 0;
}
