﻿// CONTROL.EXPAND-COLLAPSE

.c-expand-collapse__item {
	@include pod-shadow;
	margin-bottom: $bsu;
	border-radius: 5px;
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: $ssu $bsu;
	padding-left: $bsu * 2;
	background-color: $white;
	cursor: pointer;
	outline: none;
	border-radius: 5px;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-heading {
	@include font-20b;
}

.c-expand-collapse__item-icon {
	&::after {
		@include font-icon;
		content: $iconf-plus;
		position: absolute;
		left: 12px;
		top: calc(50%);
		font-family: 'font-icons';
		font-size: 16px;
		transform: translateY(-50%);
		transition: transform 0.3s linear;
		color: $brand;
	}



	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] &::after {
		content: $iconf-minus;
		transform: translateY(-50%) rotate(180deg);
	}
}

.c-expand-collapse__item-body {
	padding: $ssu $bsu $bsu;

	.js & {
		display: none;
	}

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
