﻿@use "sass:math";

// COMPONENT.HOW-DOES-IT-WORK

.c-hdiw-step {
	margin-bottom: $bsu*2;

	& + & {
		@include ui-border('top');
		padding-top: $bsu*2;
	}

	@include bp('extra-large') {
		margin-bottom: $bsu*4;

		& + & {
			padding-top: $bsu*4;
		}
	}
	img{
		max-width: 100%;
	}
}

.c-hdiw-step-title {
	@include font-40b;
	margin-bottom: $bsu;
}

.c-hdiw-step-intro {
	@include font-20;
	margin-bottom: math.div($ssu, 2)*3;
}

.c-hdiw-step__sub-title {
	@include font-28bu;
	color: $brand;
}

.c-hdiw-step1-right-pod {
	position: relative;
	height: 100%;
	background-image: url('/Content/images/interface/nzPassport_Path.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	padding-top: 30px;
}

.c-hdiw-step1-list-items {
	position: relative;
	margin-top: $bsu;
	padding-left: $bsu;
	z-index: 1;
	@include bp('large') {
		padding-left: 0;
		margin-right: 80px;
	}
}

.c-hdiw-step1-list-item {
	@include pod-shadow;
	@include font-15b;
	position: relative;
	padding: $bsu $bsu $bsu $bsu*2;
	margin-bottom: $bsu;
	border-radius: 5px;
	background: $white;

	&:nth-child(even) {
		margin-left: 30px;
	}
}

.c-hdiw-step1-list-item__icon {
	@include circle(40px);
	position: absolute;
	background: $orange;
	color: $white;
	padding: 10px;
	left: -20px;
	top: 50%;
	transform: translateY(-50%);

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-hdiw-step1-passport__img {
	display: none;

	@include bp('large') {
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

.c-hdiw-step2 {
	.c-loan-calculator {
		top: 0;
		margin: 0 auto;
		left: -20px;
		width: calc(100% + 40px);
	}

	@include bp('medium') {
		.c-loan-calculator {
			left: 0;
			width: 100%;
		}
	}

	@include bp('extra-large') {
		.c-loan-calculator {
			max-width: none;
		}
	}
}

.c-hdiw-step2-bg {
	@include bp('large') {
		background-image: url('/Content/images/interface/calculator_pattern.svg');
		background-position: top;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.c-hdiw-step2-calculator {
	margin-top: 30px;

	@include bp('large') {
		margin-top: 0;
	}
}

.c-hdiw-step2-content-grid {
	
	@include bp('large') {
		align-self: center;
	}
}