.o-status {
	display: block;
	position: fixed;
	bottom: 40px;
	right: 40px;
	pointer-events: none;

	@include font-12;

	visibility: hidden;
	opacity: 0;

	background: $white;
	box-shadow: 0 0 10px rgba($black, 0.2);
	padding: $ssu $bsu;
	border-radius: 20px;

	&::after {
		@include font-icon;

		display: inline-block;
		vertical-align: middle;
		margin-left: $ssu;

		width: 20px;
		height: 20px;
		border-radius: 20px;

		line-height: 20px;
		text-align: center;
		font-size: 10px;

		color: $white;
	}
	&.success,
	&.error {
		animation: 0.5s becomeVisible forwards,
		           // Make sure the delay here matches the delay in status.js
		           0.5s 0.1s fadein forwards,

		           0.5s 3.6s fadeout forwards,
		           0.5s 3.6s becomeHidden forwards;
	}
	&.success {
		color: $success;
		&::after {
			background: $success;
			content: $iconf-tick;
		}
	}
	&.error {
		color: $error;
		&::after {
			background: $error;
			content: $iconf-close;
			font-size: 0.7em;
		}
	}
}

.o-status--absolute {
	position: absolute;
	bottom: 0;
	right: 0;
}

.o-status--in-place {
	position: absolute;
	bottom: auto;
	right: auto;
}
