$portal-layout-width: 905px;

$portal-header-height-mobile: 50px;
$portal-header-height-desktop: 74px;

$portal-nav-size-mobile: 43px;
$portal-nav-size-desktop--collapsed: 70px;
$portal-nav-size-desktop--expanded: 280px;
$portal-nav-open-bp: $portal-layout-width + $portal-nav-size-desktop--expanded * 2;


.c-portal {
	.c-tooltip__body {
		@include bp-below($tooltip-bp) {
			padding-bottom: $bsu + $portal-nav-size-mobile;
		}
		z-index: 4;
	}

	.c-pod__bg {
		@include bp("medium") {
			height: 230px;
		}
	}
}

.c-portal .o-layout-wrapper {
	@include bp-below("small") {
		@include layout-wrapper($portal-layout-width, $ssu);
	}
	@include bp("small") {
		@include layout-wrapper($portal-layout-width);
	}

	.o-layout-wrapper {
		padding: 0;
	}
}

.c-portal .o-control {
	margin: $bsu 0;
	@include bp("medium") {
		margin: $lsu 0;
	}

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.c-portal .c-portal__body > .o-layout-wrapper > .o-control:first-child {
	margin-top: $bsu;
	@include bp("medium") {
		margin-top: $lsu;
	}
}

.c-portal__layout {
	@include bp($portal-nav-bp) {
		position: relative;

		@include flex-extend;
		min-height: calc(100vh - #{$portal-header-height-desktop});
		align-items: stretch;
	}
}

.c-portal__main {
	flex-grow: 1;

	position: relative;
	z-index: 1;

	@include flex-extend;
	flex-direction: column;
}

.c-portal__body {
	flex-grow: 1;
}

.c-portal__introduction {
	margin-top: $lsu;
}

.c-portal__footer {
	@include layout-wrapper;
	width: 100%;
	max-width: $layout-wrapper-max-width + 380px;

	@include flex-extend;
	align-items: center;
	justify-content: space-between;

	padding-bottom: $bsu;
	padding-top: $lsu;

	@include bp($portal-nav-open-bp) {
		max-width: $portal-layout-width;
	}
}

.c-portal__footer-main {
	@include flex-extend;
}

.c-portal__footer-copyright {
	@include font-12;
	margin-right: $bsu;
}

.c-portal__footer-tb {
	@include font-12;
}

.c-portal__footer-back-to-top {
	@include flex-extend;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;
}

.c-portal__footer-back-to-top-icon {
	@include link-transition;
}

.c-portal__log-in {
	padding: $lsu $lsu 0;
	text-align: center;
}
