﻿// CONTROL.IMAGE-GALLERY
.c-image-gallery {
	margin-bottom: 70px;

	.flickity-page-dots {
		display: none;

		@include bp('medium') {
			display: block;
		}

		@include bp('extra-large') {
			display: none;
		}
	}
}

.c-image-gallery__slides {
	@include list-reset;
}

.c-image-gallery__slide {
	width: 100%;
}


.c-image-gallery__caption {
	@include flex-extend;
	justify-content: flex-end;
}

.c-image-gallery__caption-text {
	flex-grow: 1;
}

.c-image-gallery__caption-count {
	@include font-12b;
	padding-left: $lsu;
	white-space: nowrap;
}


.c-image-gallery__controls {

	@include bp('medium') {
		display: none;
	}

	@include bp('extra-large') {
		display: block;
	}
}

.c-image-gallery__arrow {
	position: absolute;
	color: $brand;
	cursor: pointer;
	font-size: 40px;
	top: 50%;
	transform: translateY(-50%);

	&.is-prev {
		left: -80px;
	}

	&.is-next {
		right: -80px;
	}

	&.is-disabled {
		color: $grey;
		cursor: not-allowed;
	}

	@include bp-below('medium') {
		&.is-prev {
			top: calc(100% + 20px);
			left: 0;
		}

		&.is-next {
			top: calc(100% + 20px);
			right: 0;
		}
	}
}