﻿// CONFIG

@use "sass:math";

// Layout
// Max width of layout wrapper which contains content
// Width of sidebar (default is to float at 'large' breakpoint)
$layout-wrapper-max-width: 1400px;
$layout-aside-width: 280px;
$layout-aside-gutter-width: 60px;
$layout-wrapper-narrow-width: $layout-wrapper-max-width - ($layout-aside-width + $layout-aside-gutter-width);

// Spacings
// $bsu: Base spacing unit
// $tsu: Tiny spacing unit
// $ssu: Small spacing unit
// $lsu: Large spacing unit
// $hsu: Huge spacing unit
$bsu: 20px;
$tsu: math.div($bsu, 4);
$ssu: math.div($bsu, 2);
$lsu: $bsu * 2;
$hsu: $bsu * 4;

// Breakpoints
// Suffixes used for namespaced helpers
// Pixel values will be output as em units
$breakpoints: (
	"extra-small" (
		"suffix": "xs",
		"start": 0,
		"end": 479px
	),
	"small" (
		"suffix": "s",
		"start": 480px,
		"end": 767px
	),
	"medium" (
		"suffix": "m",
		"start": 768px,
		"end": 1023px
	),
	"large" (
		"suffix": "l",
		"start": 1024px,
		"end": 1279px
	),
	"extra-large" (
		"suffix": "xl",
		"start": 1280px,
		"end": 1679px
	),
	"super-large" (
		"suffix": "sl",
		"start": 1680px,
		"end": 9000px
	)
);

$portal-nav-bp: "large";
$tab-nav-bp: "medium";

// Columns
// By default we will create wholes, halves, thirds, quarters, and fifths
// Predefine this map to override
$columns: (1, 2, 3, 4, 5, 8);

// Z-Index
// 1
$z-page: 1;

// 2
$z-header: 3;
$z-share: 2;
$z-slick-arrows: 2;
$z-navigation: 2;

// 3
$z-tooltip: 3;

// 4
$z-back-to-top: 4;



$form-field-width: 500px;

:root {
	interpolate-size: allow-keywords;
}