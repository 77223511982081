// COMPONENT.FOOTER

@use "sass:math";

$footer-logo-width: 150px;
$mobile-footer-bottombar-height: 55px;

.c-footer__heading {
	display: none;
}
.homepage .c-footer__heading {
	@include bp("large") {
		display: block;
	}
}

.c-footer {
	@include ui-border('top');
	position: relative;
	background-color: $white;

	.mobile-show-bottombar & {
		padding-bottom: $mobile-footer-bottombar-height;

		@supports (-webkit-overflow-scrolling: touch) {
			/* CSS specific to iOS devices */
			padding-bottom: 100px;
		}
	}

	@include bp('large') {
		padding-bottom: 0;
		z-index: 2;

		.mobile-show-bottombar & {
			padding-bottom: 0;
		}
	}
}

.c-footer__primary {
	padding: $bsu*2 0;
	background: $grey--lightest;

}

.c-footer__primary-content {
	@include bp("medium") {
		@include flex-extend;
		flex-wrap: wrap;
	}
}

.c-footer__secondary {
	padding: $ssu 0;
	background-color: $blue--grey;
}

.c-footer__secondary-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.c-footer__navigation {
	margin-top: $bsu;
	margin-bottom: $bsu;

	@include bp('medium') {
		/*flex: 0 0 auto;
		width: calc(50% - #{math.div($footer-logo-width, 2)});
		padding-left: $lsu;
		margin-bottom: 0;*/
		margin-top: 0;
		flex-grow: 1;
		width: 50%;
		padding-left: $ssu;
	}

	@include bp('large') {
		column-count: 3;
		column-gap: $lsu;
		/*width: calc(75% - #{$footer-logo-width});
		padding-left: $hsu;*/
		margin-bottom: 0;
	}

	@include bp('extra-large') {
		flex-grow: 0;
		width: 37.5%;
		padding-left: 0;
		margin-bottom: 0;
	}
}

.c-footer__navigation-item {
	padding-left: 15px;
	position: relative;

	@include bp('large') {
		padding-left: 0;
		page-break-inside: avoid;
	}
}

.c-footer__navigation-link {
	@include font-18b;
	color: $brand;
	display: inline-block;
	padding: $tsu 0;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}
.c-footer__navigation-expander {
	@include font-18b;

	display: inline-block;
	color: $brand;
	padding: $tsu 0;
	outline: none;
	cursor: pointer;
}

.c-footer__navigation-link__icon {
	display: block;
	position: absolute;
	left: 0;
	top: 11px;
	font-size: 10px;
	transition: transform 0.3s linear;

	.c-footer__navigation-item[aria-expanded="true"] & {
		@extend .iconf-minus;
		transform:  rotate(180deg);
	}
}

.c-footer__navigation-link--child__items {
	.js & {
		display: none;
	}

	.c-footer__navigation-item.is-expanded &,
	.c-footer__navigation-item[aria-expanded="true"] & {
		display: block;
	}

	@include bp('large') {
		.js & {
			display: block;
		}
	}
}
.c-footer__navigation-link--child {
	@include font-14;
	display: inline-block;
	color: $body-color;
	padding: $tsu 0;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}


.c-footer__contact-social {
	@include bp("medium") {
		width: 50%;
		max-width: 400px;
		padding-right: $ssu;
	}
	@include bp('extra-large') {
		width: 25%;
		max-width: none;
		padding-right: 0;
	}
}

.c-footer__contact-info {
	@include font-18;

	a {
		color: $brand;

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	p strong:first-of-type {
		display: block;
	}
}

.c-footer__free-phone {
	a {
		color: $body-color;

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}
}

.c-footer__contact-email {
	margin-top: $ssu;
}

.c-footer__social {
	margin-top: $bsu;
}

.c-footer__social-list {
	@include flex-extend;
	flex-wrap: wrap;
	align-items: center;
}


.c-footer__social-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer__social-link {
	@include circle(50px);
	@include link-transition;
	border: 1px solid $brand;
	color: $brand;
	font-size: 20px;
	background-color: transparent;


	&:hover,
	&:focus {
		color: $white;
		background-color: $brand;
	}
}

.c-footer__social-shielded {
	@include button-reset;
	@include square(50px);
	display: block;
	margin: 0;
	padding: 0;

	background: url(/content/images/interface/logo/logo-shielded.png) no-repeat;
	background-size: cover;
}

.c-footer__disclaimer {
	@include bp("medium") {
		width: 100%;
		margin-top: $ssu;
	}
	@include bp('extra-large') {
		width: 37.5%;
		margin-top: 0;
	}

	.o-body-text {
		h3 {
			@include font-14b;
		}
		p{
			@include font-12;
		}
	}
}

.c-footer__legal {
	margin-bottom: $bsu;
	text-align: center;

	@include bp('small') {
		@include flex-extend;
		align-items: center;
		justify-content: space-between;
	}
}

.c-footer__legal-copyright {
	@include font-12;
	color: $white;
}

.c-footer__legal-tools {
	@include flex-extend;
}

.c-footer__legal-links-item {
	display: inline-block;
	padding: 0 $tsu;
}

.c-footer__legal-link {
	@include font-12;
	color: $white;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	@include font-12;
	text-align: center;
	color: $white;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}

	@include bp('medium') {
		margin-right: $bsu;
	}
}

.c-footer-back-to-top {
	@include font-12;
	margin: 0 $bsu;
	margin-right: 0;
	color: $white;

	position: relative;
	&.sticky-on-desktop {
		&::before {
			content: "";
			@include abs-center;
			min-width: 44px;
			min-height: 44px;
		}
	}
}

.c-footer-back-to-top__text {
	display: none;
	@include bp('medium') {
		display: inline;
		margin-right: math.div($ssu, 2);
	}
}

.c-footer__quicklinks {
	display: none;

	.mobile-show-bottombar & {
		@include ui-border('top');
		display: flex;
		align-items: center;
		position: fixed;
		bottom: 0;
		width: 100%;
		background: $white;
		z-index: 10;
	}

	.nav-open.mobile-show-bottombar & {
		z-index: 0;
	}

	@include bp('large') {
		display: flex;
		@include ui-border('bottom');
		position: static;

		.mobile-show-bottombar & {
			position: static;
			border-top: none;
		}
	}
}

.c-footer__quicklinks-item {
	flex: 1 1 0;
	align-self: stretch;
	text-align: center;

	&:last-child {
		border-right: none;
	}

	.c-footer__quicklinks-content {
		@include button-reset;
		@include flex-extend;
		flex-direction: column;
		align-items: center;
		padding: $ssu $tsu;
		height: 100%;
		width: 100%;
	}
	/*@include bp('medium') {


		.c-footer__quicklinks-content {
			padding: $bsu;
		}
	}*/
	@include bp('large') {
		border-right: 1px solid $base-ui-color;

		.c-footer__quicklinks-content {
			padding: $bsu;
		}
	}

	@include bp('extra-large') {
		.c-footer__quicklinks-content {
			padding: 35px 40px;
		}
	}
}

.c-footer__quicklinks-item--continue {
	margin-right: $ssu;

	.c-footer__quicklinks-content {
		min-height: 54px;
		padding: 0 $ssu;
		flex-direction: row;
	}

	.c-footer__quicklinks-title {
		margin-top: 0;
		margin-left: $ssu;

		@include font-14b;
		text-transform: none;
		text-align: left;
	}
}

.c-footer__quicklinks-link {
	@include link-transition;

	&:hover {
		background: $brand;

		.c-footer__quicklinks-icon {
			color: $white;
		}

		.c-footer__quicklinks-title, .c-footer__quicklinks-abstract {
			color: $white;
		}
	}
}

.c-footer__quicklinks-icon {
	color: $brand;
	font-size: 20px;

	@include bp('large') {
		font-size: 50px;
	}
}

.c-footer__quicklinks-title {
	@include font-6u;
	color: $brand;
	margin-top: 3px;

	.desktop-text {
		display: none;
	}

	@include bp('large') {
		@include font-18b;
		margin-top: $ssu*3;
		color: $body-color;

		.mobile-text {
			display: none;
		}

		.desktop-text {
			display: block;
		}
	}
}

.c-footer__quicklinks-abstract {
	@include font-14;
	display: none;
	margin-top: $bsu;
	color: $body-color;


	@include bp('large') {
		display: block;
	}
}

.c-footer__quicklinks-apply__btn {
	@include bp('large') {
		display: none;
	}

	&:first-child {
		padding-left: $ssu;
	}
	&:last-child {
		padding-right: $ssu;
	}
}


.c-footer__badge {
	float: right;

	width: 70px;
	@include bp("medium") {
		float: none;
		margin-bottom: $bsu;
	}
	@include bp('extra-large') {
		width: 120px;
	}
}

.c-footer__logos-wrapper {
	@include flex-extend;
	justify-content: center;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;

	@include bp("large") {
		width: 50%;
		margin: $bsu auto;
	}

	@include bp("extra-large") {
		width: 25%;
		margin-top: 0;
	}
}

.c-footer__logo-item {
	text-align: center;
	padding: 10px;
	width: 50%;

	@include bp("small") {
		width: 25%;
	}

	@include bp("large") {
		width: 50%;
	}
}

.c-footer__logo-item-link {
	display: block;
}

.c-footer__logo-image-container {
	display: block;
	margin-bottom: $bsu;
	margin-left: auto;
	margin-right: auto;

	max-width: 80px;
	@include bp("extra-large") {
		max-width: 100px;
	}
}

.c-footer__logo-image {
	display: block;
}

.c-footer__logo-item-content{
	@include font-12;
	color: $body-color;

	@include bp("small") {
		@include font-14;
	}

	@include bp("extra-large") {
		@include font-16;
	}
}

.c-footer__logo-item-link {
	color: $body-color;

	&:hover,
	&:focus {
		.c-footer__logo-item-content {
			text-decoration: underline;
		}
	}
}