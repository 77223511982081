﻿// CONTROL.REFER

.c-refer__content {
	margin-bottom: $lsu;

	@include bp('large') {
		margin-bottom: 0;
	}
}

.c-refer__heading {
	@include font-28b;
	margin-bottom: $bsu;

	@include bp('medium') {
		margin-bottom: $lsu;
	}

	@include bp('large') {
		margin-top: 50px;
	}
}

.c-refer__form-heading {
	@include font-28b;
	position: relative;
	padding-bottom: 25px;
	margin-bottom: 25px;

	&:after {
		display: block;
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		text-align: center;
		width: 60px;
		height: 0;
		border-top: 3px solid $brand;
	}
}

.c-refer__form-wrapper {
	position: relative;
	z-index: 2;
	padding: $bsu;
	padding-bottom: $bsu * 2;
	margin-bottom: 25px;
	background-color: $cream;

	@include bp("medium") {
		border: 10px solid $white;
		border-radius: 30px;
		padding: $lsu;
		padding-bottom: $lsu - $ssu;
		box-shadow: 0 10px 10px 5px rgba($black, 0.2);
	}
}

.c-refer__form-submit {
	position: absolute;
	right: $bsu;
	bottom: 0;
	transform: translateY(50%);

	@include bp("medium") {
		right: $lsu;
		bottom: -5px;
	}
}