/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

.c-megamenu {
	display: none;
	background: $white;

	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;
	}

	&[aria-expanded="true"] {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp('large') {
		transition: max-height 0.5s ease-in-out;
		position: absolute;
		left: 0;
		display: block;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		text-align: left;
		background: rgba($grey--lightest, 0.98);
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			max-height: 500px;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}
	}
}

.c-megamenu__wrapper {
	@include clearfix;

	@include bp('large') {
		max-width: em($layout-wrapper-max-width);
		padding: 0 $bsu;
		padding-top: $bsu * 3;
		padding-bottom: $bsu * 3;
		margin: 0 auto;
	}
}

.c-megamenu__intro {
	width: $megamemu__intro-width;
	float: left;
	padding-right: $hsu;
}

.c-megamenu__intro-heading {
	display: block;
	margin-bottom: $bsu;
	font-size: rem(24px);
}

.c-megamenu__items {
	padding: $ssu 0 $ssu $bsu;

	@include bp('large') {
		width: calc(100% - #{$megamemu__intro-width});
		float: right;
		columns: 2;
		column-gap: $lsu;
		padding: 0;
	}

	@include bp('extra-large') {
		columns: 3;
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp('large') {
		@include ui-border('bottom');
		margin: 0;
	}
}

.c-megamenu__link {
	display: block;
	padding: $tsu 0;

	&:before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;
		font-size: 14px;
		font-weight: $weight--bold;
		text-transform: uppercase;

		&::before {
			@include abs-center-v;
			right: $bsu;
			display: block;
			color: $brand--alt;
			opacity: 0;
			transition: all 0.3s ease;
		}

		&:hover::before {
			right: $ssu;
			opacity: 1;
		}
	}
}