﻿/* OBJECT.TILE */

@use "sass:math";

.o-tile {
	@include pod-shadow;
	@include pod-transition;
	display: block;
	border-radius: 5px;
	min-height: 100%;
	margin-bottom:$bsu;
	// Create new stacking context

}

// Figure sits alongside body, is full height
.o-tile__figure {
	position: relative;
	width: 100%;

	&.default-bg {
		@include gradient-bg__to-bottom-right;
		padding-bottom: percentage(math.div(9, 16));
	}
}

.o-tile__image {
	@include link-transition;
	display: block;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
}

// Body sits alongside media, has internal padding
.o-tile__body {
	padding: 30px $bsu $bsu;

	> :last-child {
		margin-bottom: 0;
	}
}


.o-tile__heading {
	@include font-20b;
	color: $brand;
}

.o-tile__tag {
	@include font-12;
	position: absolute;
	bottom: -15px;
	left: $bsu;
	padding: 8px $bsu;
	color: $white;
	background-color: $brand;
	border: 0;
	border-radius: 30px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
}


.o-tile__text {
	@include font-15;
	margin-top: $ssu;
	color: $body-color;

	&[draggable="false"] {
		display: inline;
	}
}
