// COMPONENT.HERO

@use "sass:math";

.c-hero {
	@include bp('large') {
		position: relative;
		overflow: hidden;
	}

	margin-bottom: $bsu;
}

.c-hero__figure {
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 130px;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100,7db9e8+100&0.6+0,0+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
	}
}

.c-hero__heading {
	@include font-50sbu;
	color: $white;

	@include bp('large') {
		text-shadow: 1px 1px 10px $black;
	}
}



.c-hero__stripe-desktop {
	display: none;
	position: absolute;
	left: -1px;
	bottom: -1px;
	width: calc(100% + 2px);

	@include bp('large') {
		display: block;
	}
}

.c-hero__stripe-mobile {
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;
	z-index: 1;
	display: block;

	@include bp('large') {
		display: none;
	}
}

.c-hero__content {
	@include gradient-bg__to-bottom-left;
	position: relative;
	padding: $bsu $bsu $bsu*2;
	margin-bottom: $bsu;

	&.has-feature {
		padding-bottom: 160px;
		margin-bottom: 0;
	}

	@include bp('small') {
		padding-bottom: $bsu*4;
	}

	@include bp('medium') {
		padding-bottom: $bsu*6;
		margin-bottom: 0;
	}

	@include bp('large') {
		padding: 0;
		padding-top: 0;
		max-width: 600px;
		align-self: center;
		background: none;

		&.has-feature {
			padding-bottom: 0;
		}
	}

	@include bp('extra-large') {
		max-width: 680px;
	}

	@include bp(1800px) {
		&.with-video {
			max-width: 350px;
		}
	}
}

.c-hero__body {
	@include bp('large') {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.c-hero__body-wrapper {
	@include bp('large') {
		@include layout-wrapper;
		height: 100%;
		display: flex;
		justify-content: space-between;
		padding-top: 75px;
	}
}

.c-hero__abstract {
	@include font-20b;
	color: $white;
	margin-top: 20px;

	&::before {
		margin-bottom: 20px;
		content: '';
		display: block;
		height: 1px;
		width: 100%;
		background-color: $white;
	}

	@include bp('large') {
		text-shadow: 1px 1px 10px $black;
	}

	@include bp('extra-large') {
		margin-top: 20px;
		@include font-28b;

		&::before {
			margin-bottom: 20px;
		}
	}

	@include bp(1400px) {
		@include font-32b;
	}
}

.c-hero__mobile-cta {
	margin-top: $bsu;
	margin-bottom: $lsu;

	@include bp("medium") {
		display: none;
	}
}

.c-hero-feature-image {
	position: relative;
	padding: 0 $bsu;
	margin-top: -140px;
	z-index: 1;
	text-align: center;

	picture {
		display: block;
		padding-bottom: percentage(math.div(9, 16));
		position: relative;
	}

	img {
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		object-fit: contain;
		width: 100%;
		height: 100%;
	}

	@include bp('large') {
		position: static;
		align-self: flex-end;
		margin-top: 0;
		width: 50%;
		padding: 0;
	}

	@include bp('extra-large') {
		width: 50%;

		img {
			max-height: 400px;
		}
	}

	@include bp('extra-large') {

		img {
			max-height: 480px;
		}
	}

	@include bp('super-large') {
		img {
			max-height: 600px;
		}
	}
}


//for video
.c-hero-video-container {
	position: relative;
	padding: 0 $bsu;
	margin-top: -60px;
	z-index: 1;

	@include bp('medium') {
		margin-top: -80px;
	}

	@include bp('large') {
		position: static;
		margin-left: 40px;
		margin-top: 0;
		padding: 0;
		width: calc(100% - 315px);
		max-width: 450px;
		align-self: flex-end;
	}

	@include bp('extra-large') {
		max-width: 600px;
	}

	@include bp(1580px) {
		max-width: 750px;
	}

	@include bp(1800px) {
		max-width: 850px;
	}
}

.c-hero-video__embed {
	margin-bottom: $bsu;
	padding-bottom: percentage(math.div(9, 16));

	@include bp('large') {
		margin-bottom: 0;
	}
}

.c-hero-video__cover {
	@include abs-center;
	width: 100%;

	&.is-playing {
		display: none;
	}
}

.c-hero-video__icon {
	@include abs-center;
	font-size: 40px;
	color: $white;

	@include bp('small') {
		font-size: 60px;
	}

	@include bp('medium') {
		font-size: 80px;

		&.thumbnail-icon {
			font-size: 40px;
		}
	}
}

.c-hero-video__icon-thumbnail {
	@include abs-center;
	font-size: 40px;
	color: $white;

	.is-selected & {
		display: none;
	}

	@include bp('small') {
		font-size: 60px;
	}

	@include bp('large') {
		font-size: 40px;
	}
}

.c-hero-video__main {
	@include gradient-bg__to-bottom-right;
}

.c-hero-video-thumbnails {
	display: none;
	margin-top: $bsu;

	@include bp('large') {
		display: flex;
	}
}

.c-hero-video-thumbnail {
	@include bp('large') {

		&.is-selected {
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: rgba(251,251,251,0.5);
				border: 2px solid $brand;
			}
		}
	}
}

.c-hero-video-thumbnail__first {
	display: none;

	@include bp('large') {
		display: inline-block;
	}
}

.c-hero-videolist-mobile-expandar-heading {
	@include font-16b;
	padding: 10px 0;
	text-align: center;
	color: $brand;
	cursor: pointer;
}

.c-hero-videolist-mobile-expandar-icon {
	font-size: 14px;
	margin-right: 5px;
}
//for hero subnav in personal loan landing
.c-hero__subnav {
	position: relative;
	margin-top: -30px;
	z-index: 1;

	@include bp('medium') {
		margin-top: -80px;
	}

	@include bp('large') {
		margin-top: 0;
	}
}

.c-hero__subnav-wrapper {
	@include bp('large') {
		@include layout-wrapper;
	}
}

.c-hero-subnav-pod {
	@include gradient-bg__to-bottom-right;
	position: relative;
	padding-bottom: 100%;

	&.no-image {
		padding-bottom: 100%;
	}

	img {
		position: absolute;
	}

	@include bp('large') {
		&:after {
			display: none;
		}
	}
}

a.c-hero-subnav-pod {
	overflow: hidden;

	img {
		@include link-transition;
		transform: scale(1);
	}

	&:hover, &:focus {
		img {
			transform: scale(1.1);
		}
	}
}

.c-hero-subnav-pod__title {
	position: absolute;
	@include font-28b;
	top: 0;
	left: 0;
	padding: $bsu;
	color: $white;
	z-index: 1;
	text-shadow: 1px 1px rgba(0,0,0,0.5);
}



//half height hero
.c-hero__halfheight {
	position: relative;
	overflow: hidden;
	margin-bottom: $lsu;
}

.c-hero__halfheight-bg {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 130px;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100,7db9e8+100&0.6+0,0+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
	}
}

.c-hero__halfheight__body {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: $bsu;
	padding-bottom: $bsu*2;

	@include bp('large') {
		@include layout-wrapper;
	}
}

.c-hero__content-halfheight {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	max-width: calc(100% - 40px);

	@include bp('large') {
		max-width: none;
	}
}

.c-hero__calculator {
	align-self: flex-end;

	@include bp('medium') {
		padding-top: 0;
	}
}

//home hero
.c-home-hero {
	position: relative;
	@include gradient-bg__to-bottom-left;

	@include bp('medium') {
		min-height: 500px;
	}

	@include bp('large') {
		min-height: 0;
		background: none;
		overflow: hidden;
	}
}

.c-home-hero__img {
	display: none;

	&.is-loading {
		background-image: none !important;
	}

	@include bp('large') {
		position: relative;
		display: block;
		padding-bottom: percentage(math.div(768, 1920));
		min-height: 800px;
		height: 100%;
		width: 100%;
		background-size: cover;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 100%;
			height: 130px;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100,7db9e8+100&0.6+0,0+100 */
			background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
		}
	}
}


.c-home-hero__stripe-desktop {
	display: none;
	position: absolute;
	left: -1px;
	bottom: -1px;
	width: calc(100% + 2px);

	@include bp('large') {
		display: block;
	}
}

.c-home-hero__stripe-mobile {
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;

	@include bp('medium') {
		bottom: 50px;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			margin-top: -2px;
			height: 54px;
			background: $white;
		}
	}

	@include bp('large') {
		display: none;
	}
}

.c-home-hero-feature__img-desktop {
	// Stop overlaying calculator
	pointer-events: none;
	display: none;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 22%;
	z-index: 1;
	max-height: 670px;

	&::before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: percentage(math.div(1300, 780));
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@include bp('extra-large') {
		display: block;
		z-index: 2;
	}
}


.c-home-hero-feature__img-mobile {
	position: absolute;
	bottom: 0;
	left: 30px;

	img {
		max-width: 100%;
	}

	@include bp('large') {
		display: none;
	}

	@include bp-below(375px) {
		display: none;
	}
}

.c-home-hero__badge {
	display: none;

	@include bp('large') {
		display: block;
		position: absolute;
		bottom: 30px;
		left: calc(50% - 200px);
		transform: translateX(-50%);
		width: 110px;
	}

	@include bp('extra-large') {
		width: 135px;
		bottom: 50px;
		left: calc(50% - 260px);
	}
}

.c-home-hero__body {
	position: static;
	//padding-bottom: 60px;
	@include bp('large') {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding-bottom: 0;
	}
}

.c-home-hero__body-wrapper {
	@include bp('large') {
		height: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.c-home-hero__content {
	padding-top: $bsu;

	@include bp('small') {
		display: block;
	}

	@include bp('large') {
		width: calc(50% - 100px);
		align-self: center;
	}

	@include bp('extra-large') {
		width: calc(50% - 200px);
	}
}

.c-home-hero__title {
	@include font-50sbu;
	color: $white;

	&:after {
		margin-top: $bsu;
		content: '';
		display: block;
		height: 1px;
		width: 100%;
		background-color: $white;
	}

	&.desktop-title {
		display: none;
	}

	@include bp('large') {
		&.desktop-title {
			display: block;
			text-shadow: 1px 1px 10px $black;
		}

		&.mobile-title {
			display: none;
		}
	}

	@include bp('extra-large') {
		&:after {
			margin-top: $ssu*3;
		}
	}
}

.c-home-hero__abstract {
	@include font-20b;
	color: $white;
	margin-top: $bsu;
	display: none;

	@include bp('large') {
		display: block;
		text-shadow: 1px 1px 10px $black;
	}

	@include bp('extra-large') {
		margin-top: $ssu*3;
		@include font-32b;
	}
}

.c-home-hero__btn {
	display: none;

	@include bp('large') {
		display: inline-block;

		&:hover, &:focus {
			background: $orange;
			border-color: $orange;
		}
	}
}


.c-hero--branch-home {
	position: relative;
	height: auto;
	min-height: 220px;
	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, #000000 100%), linear-gradient(-135deg, #96CBD1 0%, #00444C 100%);
	width: calc(100% + 1px);

	@include bp('medium') {
		height: 200px;
		min-height: 0;
	}

	.c-hero__stripe-mobile {
		width: 100%;
		bottom: -5px;

		img {
			width: 100%;
		}
	}

	.c-hero__stripe-desktop {
		width: 100%;
		height: 100%;
		left: 0;

		img {
			width: 100%;
			height: 100%;

			@include bp-below(1400px) {
				object-fit: cover;
			}
		}
	}

	.c-hero__halfheight-bg {
		z-index: 0;
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 80px;
		transform: scaleX(-1);
	}
}

.c-hero--branch-home__content {
	padding-top: 50px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	h1 {
		color: #fff;
		margin-bottom: 30px;
		width: auto;

		@include bp('small') {
			margin-bottom: 0;
		}
	}
}

.c-hero--branch-home__logo {
	max-width: 370px;
	max-height: 70px;
	margin: 0 30px 30px 0;

	@include bp('medium') {
		margin: 0;
	}
}
