@use "sass:math";

$portal-personal-info-bp: "medium";

.c-portal-personal-info {
	@include flex-extend;
	@include bp-below($portal-personal-info-bp) {
		flex-direction: column;
	}
}

.c-portal-personal-info__section {
	@include flex-extend;

	@include bp-below($portal-personal-info-bp) {
		padding: $bsu 0;
		border-bottom: 1px solid $grey--light;

		&:last-child {
			border-right: none;
		}
	}

	@include bp($portal-personal-info-bp) {
		flex-direction: column;
		align-items: center;
		padding: 0 $ssu;

		@include bp("large") {
			padding: 0 $bsu;
		}

		@include bp("extra-large") {
			padding: $lsu;
		}

		width: percentage(math.div(1, 3));
		border-right: 1px solid $grey;

		&:last-child {
			border-right: none;
		}
	}
}

.c-portal-personal-info__head {
	@include bp-below($portal-personal-info-bp) {
		padding-right: $bsu;
	}
	@include bp($portal-personal-info-bp) {
		padding-bottom: $ssu;
	}
}

.c-portal-personal-info__body {
	// Allow it to be smaller than its content,
	// to ensure it fits within its container
	min-width: 0;
	max-width: 100%;

	@include bp-below($portal-personal-info-bp) {
		flex-grow: 1;
	}
	@include bp($portal-personal-info-bp) {
		@include flex-extend;
		align-items: center;
		flex-direction: column;
	}
}

.c-portal-personal-info__icon {
	font-size: 32px;
	@include bp($portal-personal-info-bp) {
		font-size: 40px;
	}
}

.c-portal-personal-info__label {
	@include font-12;
	margin-bottom: $tsu;
	@include bp($portal-personal-info-bp) {
		@include font-15;
		margin-bottom: $ssu;
	}
}

.c-portal-personal-info__value {
	@include font-15b;
	@include bp($portal-personal-info-bp) {
		margin-bottom: $ssu;
		@include font-18b;
	}

	display: block;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;
	text-overflow: ellipsis;
	// word-break: break-all;
}

.c-portal-personal-info__meta {
	.c-tooltip {
		margin-left: $tsu;
		top: -4px;
	}
}

.c-portal-personal-info__meta-label {
	@include font-12;
}

.c-portal-personal-info__meta-value {
	@include font-12b;
}

.c-portal-personal-info__actions {
	@include bp-below($portal-personal-info-bp) {
		@include flex-extend;
		align-items: flex-end;
	}

	.c-portal-personal-info__action {
		@include font-14;
	}
}
