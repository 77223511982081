// COMPONENT.RELATED-ITEMS

.c-related-items__intro {
	margin-bottom: $bsu;

	p {
		@include font-15;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-items-list {

}

.c-related-item {
	margin-bottom: $bsu;
}

.c-related-item__link {
	@include link-transition;
	@include pod-shadow;
	position: relative;
	display: block;
	padding: 15px $ssu;
	padding-left: $ssu * 3 + $bsu;
	background-color: $white;
	outline: none;
	margin-bottom: $bsu;
	border-radius: 5px;

	&:hover, &:focus {
		.c-related-item__text {
			color: $brand;
		}
	}
}

.c-related-item__text {
	@include font-20b;
	color: $body-color;
}

.c-related-item__icon {
	@include abs-center-v;
	left: 15px;
	color: $brand;
	font-size: 20px;
}

.c-related-item__meta {
	@include font-12;
	display: block;
	margin-top: $tsu;
	color: $grey--dark;
}
