﻿// OBJECT.POD

.o-pod {
	position: relative;
	display: block;
	@include pod-transition;

	@include bp('extra-large') {
		&:hover {
			.o-pod__heading::after {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}

.o-pod__body {
	@include font-15;
	padding: $ssu $bsu;
	//text-align: center;
	color: $body-color;
}

.o-pod__heading {
	@include font-28b;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: $bsu;
	padding-right: 65px;
	color: $white;
	z-index: 1;
	text-shadow: 1px 1px rgba(0,0,0,0.5);

	.c-pod-noimage & {
		position: static;

		@include bp('medium') {
			position: absolute;
		}
	}

	&::after {
		font-size: 35px;
		@include font-icon;
		content: $iconf-arrow-right;
		position: absolute;
		bottom: $bsu;
		right: $bsu;
	}

	@include bp('extra-large') {
		&::after {
			transition: all .35s ease-in;
			opacity: 0;
			transform: translateX(-40px);
		}
	}
}