@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?o6ebwd');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?o6ebwd#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?o6ebwd') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?o6ebwd') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?o6ebwd') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?o6ebwd##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconf-"], [class*=" iconf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconf-edit {
  &:before {
    content: $iconf-edit; 
  }
}
.iconf-dots {
  &:before {
    content: $iconf-dots; 
  }
}
.iconf-menu {
  &:before {
    content: $iconf-menu; 
  }
}
.iconf-zoom {
  &:before {
    content: $iconf-zoom; 
  }
}
.iconf-enlarge {
  &:before {
    content: $iconf-enlarge; 
  }
}
.iconf-parking {
  &:before {
    content: $iconf-parking; 
  }
}
.iconf-arrow-right {
  &:before {
    content: $iconf-arrow-right; 
  }
}
.iconf-arrow-left {
  &:before {
    content: $iconf-arrow-left; 
  }
}
.iconf-arrow-up {
  &:before {
    content: $iconf-arrow-up; 
  }
}
.iconf-arrow-down {
  &:before {
    content: $iconf-arrow-down; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-cross {
  &:before {
    content: $iconf-cross; 
  }
}
.iconf-delete {
  &:before {
    content: $iconf-delete; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-check {
  &:before {
    content: $iconf-check; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-open {
  &:before {
    content: $iconf-open; 
  }
}
.iconf-location {
  &:before {
    content: $iconf-location; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-speech-bubble {
  &:before {
    content: $iconf-speech-bubble; 
  }
}
.iconf-telephone {
  &:before {
    content: $iconf-telephone; 
  }
}
.iconf-phone {
  &:before {
    content: $iconf-phone; 
  }
}
.iconf-share {
  &:before {
    content: $iconf-share; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-instagram {
  &:before {
    content: $iconf-instagram; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-youku {
  &:before {
    content: $iconf-youku; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-wechat {
  &:before {
    content: $iconf-wechat; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-reset {
  &:before {
    content: $iconf-reset; 
  }
}
.iconf-external-link {
  &:before {
    content: $iconf-external-link; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}
.iconf-mouse {
  &:before {
    content: $iconf-mouse; 
  }
}
.iconf-car {
  &:before {
    content: $iconf-car; 
  }
}
.iconf-money {
  &:before {
    content: $iconf-money; 
  }
}
.iconf-bill {
  &:before {
    content: $iconf-bill; 
  }
}
.iconf-candle {
  &:before {
    content: $iconf-candle; 
  }
}
.iconf-kiwi {
  &:before {
    content: $iconf-kiwi; 
  }
}
.iconf-smartphone {
  &:before {
    content: $iconf-smartphone; 
  }
}
.iconf-padlock {
  &:before {
    content: $iconf-padlock; 
  }
}
.iconf-user {
  &:before {
    content: $iconf-user; 
  }
}
.iconf-play-button {
  &:before {
    content: $iconf-play-button; 
  }
}
.iconf-application {
  &:before {
    content: $iconf-application; 
  }
}
.iconf-question {
  &:before {
    content: $iconf-question; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-quotation {
  &:before {
    content: $iconf-quotation; 
  }
}
.iconf-clock {
  &:before {
    content: $iconf-clock; 
  }
}
.iconf-home-improvement {
  &:before {
    content: $iconf-home-improvement; 
  }
}
.iconf-save {
  &:before {
    content: $iconf-save; 
  }
}
.iconf-home {
  &:before {
    content: $iconf-home; 
  }
}
.iconf-map {
  &:before {
    content: $iconf-map; 
  }
}
.iconf-give-love {
  &:before {
    content: $iconf-give-love; 
  }
}
.iconf-travel {
  &:before {
    content: $iconf-travel; 
  }
}
.iconf-rings {
  &:before {
    content: $iconf-rings; 
  }
}
.iconf-click {
  &:before {
    content: $iconf-click; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-support {
  &:before {
    content: $iconf-support; 
  }
}
.iconf-online-form {
  &:before {
    content: $iconf-online-form; 
  }
}
.iconf-place {
  &:before {
    content: $iconf-place; 
  }
}
.iconf-if {
  &:before {
    content: $iconf-if; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-my-home {
  &:before {
    content: $iconf-my-home; 
  }
}
.iconf-fern {
  &:before {
    content: $iconf-fern; 
  }
}
.iconf-alert {
  &:before {
    content: $iconf-alert; 
  }
}
.iconf-top-up {
  &:before {
    content: $iconf-top-up; 
  }
}
.iconf-secure {
  &:before {
    content: $iconf-secure; 
  }
}
.iconf-notification {
  &:before {
    content: $iconf-notification; 
  }
}
.iconf-password {
  &:before {
    content: $iconf-password; 
  }
}
.iconf-information {
  &:before {
    content: $iconf-information; 
  }
}
.iconf-exclamation {
  &:before {
    content: $iconf-exclamation; 
  }
}
.iconf-help {
  &:before {
    content: $iconf-help; 
  }
}
.iconf-my-loan {
  &:before {
    content: $iconf-my-loan; 
  }
}
.iconf-documents {
  &:before {
    content: $iconf-documents; 
  }
}
.iconf-friend {
  &:before {
    content: $iconf-friend; 
  }
}
.iconf-bank {
  &:before {
    content: $iconf-bank; 
  }
}

