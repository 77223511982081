﻿@use "sass:math";

.c-map__listing__search {
	position: relative;

	input.c-map__listing-input {
		@include font-15;
		width: 100%;
		max-width: none;
		padding-right: $bsu*2;
	}

	@include bp('extra-large') {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
	}
}

.c-map__listing__search-icon {
	position: absolute;
	right: $ssu;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
	cursor: pointer;
	padding: $ssu;
}

.c-branch-list {
	margin-top: $bsu;
	margin-bottom: $bsu;

	@include bp('super-large') {
		margin-top: $bsu*2;
	}
}

.c-branch-list-item {
	@include pod-shadow;
	padding: 15px;
	border-radius: 5px;
	margin-bottom: $bsu;
}

.c-branch-list-item-tag {
	@include font-12;
	margin-bottom: 5px;
}

.c-branch-list-item__title {
	position: relative;
	padding-right: 40px;

	a {
		display: block;
		@include font-28b;

		&::after {
			@include font-icon;
			content: $iconf-arrow-right;
			position: absolute;
			top: 4px;
			right: 0;
			color: var(--primary-a);
		}
	}
}

.c-branch-list-item__details {
	display: flex;
	justify-content: space-between;
	margin-top: $bsu;
}

.c-branch-list-item__address {
	@include font-12;
	position: relative;
	padding-left: $bsu;
	margin-right: $bsu;
}

.c-branch-list-item__address-icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 15px;
}

.c-branch-list-item__directions {
	margin-top:$tsu;
}

.c-branch-list-item__contacts {
	flex-shrink: 0;
	margin-left: auto;
}

.c-branch-list-item__contact {
	@include font-12;
	position: relative;
	padding-left: $bsu;

	& + & {
		margin-top: 5px;
	}
}

.c-branch-list-item__contact-icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 15px;
}

//branch detail
.c-branch-detail__region {
	@include font-20;
}

.c-branch-detail-intro {
}

.c-branch-detail-intro__text {
	@include font-15;
}

.c-branch-detail__img {
	margin-bottom: $bsu;
	@include bp('medium') {
		float: right;
		width: 50%;
		margin-left: $bsu;
	}
}

.c-branch-detail__map-mobile {
	padding-bottom: 100%;
	margin-top: $bsu;
}

.c-branch-detail__btn {
	position: relative;

	&:after {
		@include ui-border('top');
		position: absolute;
		content: '';
		display: block;
		top: 50%;
		transform: translateY(-50%);
		height: 0;
		width: 100%;
		z-index: -1;
	}
}

.c-branch-detail-info-list {
	@include bp('large') {
		column-count: 2;
	}
}

.c-branch-detail-info-item {
	position: relative;
	padding-left: 25px;
	padding-top: 2px;
	margin-bottom: $bsu;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}

.c-branch-detail-info-item__title {
	@include font-12;
}

.c-branch-detail-info-item__icon {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 18px;
}

.c-branch-detail-info-item__link, .c-branch-detail-info-item__text {
	@include font-15b;
}

.c-branch-detail-info__seperator {
	color: $brand;
}

.c-branch-detail-info-direction__link {
	@include font-15b;

	@include bp('large') {
		@include font-12;
	}
}

.c-branch-detail-info-direction__icon {
	font-size: 12px;
	margin-left: math.div($ssu, 2);
}