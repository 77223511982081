﻿// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes
$base-font-size: 16px;
$base-line-height: 22px;


$weight--light: 300;
$weight--normal: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700;

// Style declarations
//
//	.c-component__heading {
//		@include font-12;
//	}

@mixin font-50 {
	font-size: 30px;
	font-weight: $weight--normal;
	line-height: calc(52/50);

	@include bp('medium') {
		font-size: 40px;
	}

	@include bp('extra-large') {
		font-size: 50px;
	}
}

@mixin font-50sb {
	font-family: $heading-font-family;
	font-size: 30px;
	font-weight: $weight--normal;
	line-height: calc(52/50);

	@include bp('medium') {
		font-size: 40px;
	}

	@include bp('extra-large') {
		font-size: 50px;
	}
}

@mixin font-50sbu {
	@include font-50sb;
	text-transform: uppercase;
}

@mixin font-40sb {
	font-family: $heading-font-family;
	font-size: 30px;
	font-weight: $weight--normal;
	line-height: calc(42/40);

	@include bp('medium') {
		font-size: 40px;
	}
}


@mixin font-40 {
	font-size: 28px;
	font-weight: $weight--normal;
	line-height: calc(52/40);

	@include bp('medium') {
		font-size: 32px;
	}

	@include bp('large') {
		font-size: 40px;
	}
}

@mixin font-40b {
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: calc(52/40);

	@include bp('medium') {
		font-size: 32px;
	}

	@include bp('large') {
		font-size: 40px;
	}
}

@mixin font-32b {
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: calc(36/32);

	@include bp('medium') {
		font-size: 28px;
	}

	@include bp('large') {
		font-size: 32px;
	}
}

@mixin font-28 {
	font-size: 28px;
	font-weight: $weight--normal;
	line-height: calc(32/28);
}

@mixin font-28b {
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: calc(32/28);
}

@mixin font-28bu {
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: calc(32/28);
	text-transform: uppercase;
}

@mixin font-24b {
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: calc(30/24);
}

@mixin font-20b {
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: calc(28/20);
}

@mixin font-20bu {
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: calc(28/20);
	text-transform: uppercase;
}

@mixin font-20 {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: calc(30/20);
}

@mixin font-18b {
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: calc(22/18);
}

@mixin font-18 {
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: calc(22/18);
}

@mixin font-18--tall {
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: calc(28/18);
}

@mixin font-18bu {
	@include font-18b;
	text-transform: uppercase;
}


@mixin font-16b {
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: calc(20/16);
}

@mixin font-16bu {
	@include font-16b;
	text-transform: uppercase;
}

@mixin font-16u {
	@include font-16b;
	text-transform: uppercase;
}

@mixin font-16 {
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: calc(20/16);
}

@mixin font-15 {
	font-size: 15px;
	font-weight: $weight--normal;
	line-height: calc(22/15);
}

@mixin font-15b {
	font-size: 15px;
	font-weight: $weight--bold;
	line-height: calc(22/15);
}

@mixin font-14b {
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: calc(17/14);
}

@mixin font-14 {
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: calc(17/14);
}

@mixin font-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	font-style: normal;
	line-height: calc(14/12);
}
@mixin font-12bu {
	@include font-12b;
	text-transform: uppercase;
}

@mixin font-12 {
	font-size: 12px;
	font-weight: $weight--normal;
	font-style: normal;
	line-height: calc(14/12);
}

@mixin font-10 {
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: calc(12/10);
}


@mixin font-10u {
	font-size: 10px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: calc(12/10);
}

@mixin font-10bu {
	font-size: 10px;
	font-weight: $weight--bold;
	text-transform: uppercase;
	line-height: calc(12/10);
}

@mixin font-8u {
	font-size: 8px;
	font-weight: $weight--bold;
	text-transform: uppercase;
	line-height: calc(10/8);
}

@mixin font-6 {
	font-size: 6px;
	font-weight: $weight--normal;
	line-height: calc(10/6);
}

@mixin font-6u {
	font-size: 6px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: calc(10/6);
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
