$task-pods-bp: "large";

@use "sass:math";

.c-task-pods {}

.c-task-pods__list {
	@include list-reset;

	margin-left: -$bsu;
	margin-top: -$bsu;

	@include flex-extend;
	@include bp($task-pods-bp) {
		justify-content: center;
	}
	flex-wrap: wrap;
}

.c-task-pods__item {
	padding-left: $bsu;
	padding-top: $bsu;
	width: percentage(math.div(1, 2));

	@include bp("small") {
		width: percentage(math.div(1, 3));
	}

	@include bp($task-pods-bp) {
		width: percentage(math.div(1, 6));
	}
}

.c-task-pods__pod {
	display: block;
	@include bp-below($task-pods-bp) {
		height: 100%;
	}
	@include bp($task-pods-bp) {
		padding-top: 100%;
		position: relative;
	}

	border-radius: 4px;

	background-size: 300%;
	background-position: right;
	&:hover,
	&:focus {
		background-position: left;
	}

	@include link-transition(background-position);
}

.c-task-pods__body {
	@include bp-below($task-pods-bp) {
		padding: $ssu;
	}
	@include bp-below("small") {
		padding: $ssu + $tsu;
	}
	@include bp($task-pods-bp) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		padding: $ssu + $tsu;
	}
}

.c-task-pods__icon {
	display: block;
	font-size: 32px;
	margin-bottom: $tsu;
}

.c-task-pods__text {
	display: block;
	@include font-14b;
}
