﻿// CONTROL.FORM

@use "sass:math";

.c-form,
.c-form.c-form__umbraco {
	max-width: none;

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea,
	.c-form__input {
		@include form-input;
	}

	input[type="file"] {
		width: 100%;
		font-family: $base-font-family;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	.c-form__input {
		height: 44px;
	}

	.c-form__input--readonly {
		align-self: flex-start;
		height: auto;
		padding: 0;
		margin-bottom: $tsu;

		@include font-16b;
		border: none;
		box-shadow: none;
		cursor: auto;
		word-break: break-word;
	}

	textarea,
	textarea.c-form__input {
		height: 150px;
	}

	select,
	select.c-form__input {
		background-image: url(/content/images/interface/form-select-arrow.png);
		background-repeat: no-repeat;
		background-position: center right 10px;
		padding-right: 30px;

		~ .field-validation-error {
			border-color: $error;
			margin-bottom: $tsu;
		}
	}
}

.c-form.c-form__umbraco {
	input[type="submit"] {
		font-size: 16px;
		font-weight: 700;
		line-height: calc(20/16);
		transition: all .25s ease-in-out;
		display: inline-block;
		padding: 17px 30px 18px;
		border: 0;
		border-radius: 30px;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		background: #068184;
		background: linear-gradient(90deg, #068184 0%, #6bc4bf 100%);
		color: #ffffff;
	}
}

.c-form--portal {
	flex-shrink: 0;
	width: 100%;
	max-width: 500px;
}

.c-form--portal-wide {
	max-width: none;
}

.c-form__split {
	@include flex-extend;
}

.c-form__aside {
	@include bp-below("large") {
		display: none;
	}

	@include bp("large") {
		margin-left: $lsu;
		flex-grow: 1;
	}
}

.c-form__component-content {
	display: contents;
}

.c-form__field,
teraform-anniversary > .c-form__field:last-child,
.c-form__field-split--m {
	margin-bottom: 12px;

	@include bp("medium") {
		margin-bottom: 16px;
	}
}

.c-form__field,
.c-form__field--help,
.c-form__field-split--m {
	&:last-child {
		margin-bottom: 0;
	}
}

.c-form__field--close {
	margin-bottom: $tsu;

	@include bp("medium") {
		margin-bottom: $ssu;
	}
}

.c-form__field--far {
	margin-bottom: $bsu;

	@include bp("medium") {
		margin-bottom: $bsu + $ssu;
	}
}

.c-form__field--has-overlay {
	position: relative;
	z-index: 1;

	&.is-active {
		z-index: 2;
	}
}

.c-form__field--mid {
	max-width: 350px;
}

.c-form__field--short {
	max-width: 300px;
}

.c-form__field--help {
	@include bp-below("medium") {
		margin-top: 3px;
		@include flex-extend;
		justify-content: flex-end;
	}
}

.c-form .c-form__field-hidden {
	input,
	select,
	textarea {
		display: none;
	}
}

.c-form__field-action {
	margin-top: $ssu;
	@include font-15;
}

.c-form__field-description {
	display: block;
	margin: $tsu 0;
	@include font-15;
}

.c-form__field-checkbox {
	@include flex-extend;
	align-items: flex-start;
}

.c-form__field-checkbox-input {
}

.c-form__field-checkbox-label {
	padding-top: 3px;

	a {
		@include link-brand;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.c-form__label--rte {
	// Make sure the required indicator can appear immediately after
	> p:nth-last-child(2) {
		display: inline-block;
	}
}

.c-form__field--row {
	@include flex-extend;
	align-items: center;
	max-width: $form-field-width;

	.c-form__label {
		flex-shrink: 0;
		width: 120px;
		margin-right: $bsu;
	}

	.c-form__input {
		flex-grow: 1;
	}
}

@mixin field-split {
	@include flex-extend;
	flex-wrap: wrap;
	align-items: flex-start;
	max-width: 500px + $bsu;

	&.c-form__field--mid {
		max-width: 370px + $bsu;
	}

	&.c-form__field--short {
		max-width: 300px + $bsu;
	}

	margin-top: -$ssu;
	margin-left: -$ssu;

	@include bp("small") {
		margin-top: -$bsu;
		margin-left: -$bsu;
	}

	margin-bottom: 12px;

	@include bp("medium") {
		margin-bottom: 16px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	> .c-form__label,
	> .c-form__error {
		width: 100%;
		padding-left: $ssu;

		@include bp("small") {
			padding-left: $bsu;
		}
	}

	> .c-form__label {
		padding-top: $ssu;

		@include bp("small") {
			padding-top: $bsu;
		}
	}

	> .c-form__field {
		width: 50%;
		padding-left: $ssu;
		padding-top: $ssu;

		@include bp("small") {
			padding-left: $bsu;
			padding-top: $bsu;
		}

		margin-bottom: 0;

		&.c-form__field--help {
			padding-top: 0;
		}
	}

	> .c-form__error {
		margin-top: 5px;
	}
}

.c-form__field-split,
.c-form__field-split-h {
	@include field-split;
}

.c-form__field-split--m {
	@include bp-below("medium") {
		.c-form__field--help:nth-child(n+2) {
			margin-top: -9px;
		}
	}

	@include bp("medium") {
		@include field-split;
	}
}

.c-form__field-split-h {
	margin-top: 0;

	> .c-form__label,
	> .c-form__error,
	> .c-form__field {
		padding-top: 0;
	}
}

.c-form__required-indicator {
	&::before {
		content: "*";
		color: $orange;
	}
}

.c-form__date-short,
.c-form__date-long {
	select {
		&, &.c-form__input {
			padding-left: 10px;
		}
	}
}

.c-form__date-short {
	width: 105px !important;

	@include bp("small") {
		width: calc(50% - 80px) !important;
	}
}

.c-form__date-long {
	width: calc(100% - 210px) !important;

	@include bp("small") {
		width: 160px !important;
	}
}

.c-form__recaptcha {
	height: 0;
}

.grecaptcha-badge {
	visibility: hidden;
}

.c-form__recaptcha-disclaimer {
	@include font-12;
	margin-top: $bsu;
	color: $body-color;

	a {
		@include link-plain;
		@include link-brand;
	}
}

.c-form__fine-print {
	@include font-14;

	a {
		@include link-brand;
	}
}

.c-form__selected-title {
	@include font-32b;
}

// Radio list
.c-form__radio {
	@include flex-extend;
}

.c-form__radio-label {
	padding-top: 7px;

	@include bp("medium") {
		padding-top: 10px;
	}

	margin-right: 10px;
	flex-shrink: 0;
}

.c-form__radio--block-label {
	.c-form__radio {
		display: block;
	}

	.c-form__radio-label {
		display: block;
		padding-top: 0;
		margin-right: 0;
		margin-bottom: 10px;
	}
}

.c-form__radio-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-top: -5px;

	@include bp("medium") {
		margin-left: -10px;
		margin-top: -10px;
	}
}

.c-form__radio-item {
	padding-left: 5px;
	padding-top: 5px;

	@include bp("medium") {
		padding-left: 10px;
		padding-top: 10px;
	}
}



// Checkbox
.c-form__checkbox {
	@include flex-extend;
}



// Section
.c-form__section {
	margin-top: 20px;

	@include bp("medium") {
		margin-top: 40px;
	}

	@include bp("large") {
		margin-top: 60px;
	}
}

.c-form__section--tight {
	margin-top: 20px;

	@include bp("medium") {
		margin-top: 40px;
	}
}

.c-form__section--supertight {
	margin-top: 20px;
}

.c-form__section--divider + .c-form__section--divider {
	border-top: 2px solid $grey--light;
	padding-top: 40px;

	@include bp("large") {
		padding-top: 60px;
	}
}

.c-form__section-close {
	position: absolute;
	top: $ssu;
	right: $ssu;

	&::before {
		content: "";
		@include abs-center;
		min-width: 44px;
		min-height: 44px;
	}
}

.c-form__section-title {
	display: block;

	@include font-16b;

	strong {
		color: $teal--dark;
	}

	@include bp("medium") {
		@include font-28b;
	}
}

.c-form__section-title,
.c-form__section-title-split {
	margin-bottom: 12px;

	@include bp("medium") {
		margin-bottom: $bsu;
	}
}

.c-form__section-description {
	@include font-14;
	display: block;
	width: 100%;
	margin: 10px 0 0;
}

.c-form__section-title-split {
	@include flex-extend;
	flex-wrap: wrap;
	align-items: center;

	.c-form__section-title {
		margin-bottom: 0;
		margin-right: $bsu;

		&:last-child {
			margin-right: 0;
		}
	}
}

.c-form__section-title-split-action {
	flex-grow: 1;
	@include flex-extend;
	justify-content: flex-end;
}

.c-form__section-title-split-action-button {
	@include button-reset;
	@include font-14;
}

.c-form__controls {
	@include flex-extend;
	flex-direction: column;
	flex-wrap: wrap; // TODO IF-729
	margin-top: $lsu;

	@include bp("medium") {
		border-top: 1px solid $grey;
		padding-top: $lsu;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.c-form__add-button {
	display: block;
	max-width: 500px !important;
	margin-top: $bsu;
	padding-top: $ssu;
	border-top: 1px solid $grey;
	font-weight: bold;
	transition: all 0.3s;
	font-size: 12px;

	.icon {
		color: $teal--dark;
		font-weight: bold;
		font-size: 12px;
		margin-right: $ssu;

		@include bp("medium") {
			font-size: 14px;
		}
	}

	&:hover {
		cursor: pointer;
		color: $teal--dark;
		transition: all 0.3s;
	}

	@include bp("medium") {
		font-size: 16px;
	}
}

.c-form__remove-button {
	display: block;
	max-width: 500px !important;
	margin-top: $bsu;
	padding-top: $ssu;
	text-align: right;
	overflow: hidden;
	font-weight: bold;
	transition: all 0.3s;
	font-size: 12px;

	&:before {
		background-color: $grey;
		content: "";
		display: inline-block;
		height: 1px;
		position: relative;
		vertical-align: middle;
		width: 100%;
	}

	&:before {
		right: $bsu;
		margin-left: -50%;
		margin-top: -4px;
	}

	.icon {
		color: $status-red;
		font-weight: bold;
		font-size: 12px;
		margin-right: $ssu;

		@include bp("medium") {
			font-size: 14px;
		}
	}

	&:hover {
		cursor: pointer;
		color: $teal--dark;
		transition: all 0.3s;
	}

	@include bp("medium") {
		font-size: 16px;
	}
}

// TODO IF-729
.c-form__controls-fallback {
	width: 100%;
	padding-bottom: $lsu;
}

.c-form__controls-fallback-error {
	margin-bottom: $bsu;

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-form__controls--close {
	padding-top: 0;
	margin-top: 0;
}

.c-form__controls--borderless {
	margin-top: 0;
	border-top: 0;
}

.c-form__controls-text {
	@include font-14;
	margin: $bsu 0;

	@include bp("medium") {
		margin: 0 $bsu;
		flex-grow: 1;
		text-align: right;
		margin-right: $bsu;
	}
}

.c-form__controls-group {
	@include flex-extend;
}

.c-form__control--text {
	@include bp-below("medium") {
		text-align: left;
		margin-bottom: $lsu;
	}
}

.c-form__exit {
	display: inline-block;
	margin-top: $bsu;

	@include bp("medium") {
		margin-top: $lsu;
	}

	@include link-brand;
}

.c-form__error {
	display: block;
	margin-top: $tsu;
}

.field-validation-error, .c-form__error, .c-form__error-message {
	@include font-14;
	color: $error;
}

.c-form__error-message {
	display: none;
}

.has-error .c-form__error-message {
	display: block;
}




.umbraco-forms-caption {
	@include font-18;
	margin-bottom: 1.5em;
}

.umbraco-forms-form {

	.umbraco-forms-fieldset {
		max-width: 500px;
		@include ui-border('bottom');
		border-bottom: 1px solid #CCCCCC !important;
		padding-bottom: $lsu;
		margin-bottom: $lsu;
	}
}

.umbraco-forms-field {
	margin-bottom: $bsu;

	&:last-child {
		margin-bottom: 0;
	}

	&.checkbox {
		position: relative;
		padding-left: $bsu;

		input[type="checkbox"] {
			position: absolute;
			left: 0;
			top: $tsu;
		}
	}
}

.umbraco-forms-label-wrapper {
	order: 2;
}

.umbraco-forms-label, .c-form__label {
	@include font-16;
	display: block;
	margin-bottom: $tsu;
}

.c-form__label-split {
	margin-bottom: $tsu;

	.c-form__label {
		display: inline;
	}

	.c-tooltip {
		display: inline-block;
		vertical-align: middle;
		margin-left: $ssu;
		margin-bottom: $tsu;
	}
}

.c-form__label--strong {
	@include font-16b;
}

.umbraco-forms-tooltip {
	@include font-14;
	color: $grey--dark;
}

.umbraco-forms-field-wrapper {
	margin-top: $ssu;

	.date & {
		position: relative;

		&:after {
			position: absolute;
			display: block;
			right: $ssu;
			top: 15px;
			font-family: #{$icomoon-font-family};
			content: $iconf-calendar;
			pointer-events: none;
		}
	}

	.checkbox & {
		margin-top: 0;
	}

	.singlecheckbox & {
		order: -1;
		margin-right: $tsu;
	}

	.titleanddescription & {
		margin-top: 0;

		a {
			@include link-brand;
		}
	}

	.checkboxlist, .radiobuttonlist {
		label {
			display: inline-block;
			margin-bottom: math.div($ssu, 2);
			margin-left: 3px;
		}

		label:last-of-type {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-navigation {
	text-align: right;
	max-width: 500px;
}


.titleanddescription {

	h2 {
		@include font-20b;
		margin-bottom: 0.5em;
	}

	p {
		@include font-18;

		&:last-child {
			margin-bottom: 0;
		}
	}
}



// Fake inputs
.c-fake-checkbox {
	&:checked ~ .c-fake-checkbox__display,
	&.is-checked ~ .c-fake-checkbox__display {
		&:before {
			border-color: $brand;
			border-color: var(--primary-a);
		}
	}

	&:focus ~ .c-fake-checkbox__display {
		@include focus-outline;
	}

	&:disabled ~ .c-fake-checkbox__display,
	&.is-disabled ~ .c-fake-checkbox__display {
		cursor: default;
	}

	&:disabled:checked ~ .c-fake-checkbox__display,
	&.is-disabled.is-checked ~ .c-fake-checkbox__display {
		&:before {
			border-color: $grey;
		}
	}
}

.c-fake-checkbox__display {
	@include bp("medium") {
		@include link-transition;
	}

	cursor: pointer;

	&:before {
		@include bp("medium") {
			@include link-transition;
		}

		content: "";
		position: absolute;
		left: 4px;
		top: 6px;
		display: block;
		width: 16px;
		height: 8px;
		border: 2px solid transparent;
		border-width: 0 0 2px 2px;
		background: transparent;
		transform: rotate(-45deg);
	}

	&.has-error {
		border-color: $error;
	}
}

// Fake Radio
.c-fake-radio__display {
	display: block;
	cursor: pointer;
	box-shadow: inset 0 0 0 1px var(--primary-a);

	&.has-error {
		box-shadow: inset 0 0 0 1px $error;
	}

	color: var(--primary-a);
	background: var(--primary-b);
	padding: 11px 15px;
	border-radius: 20px;
	@include font-14b;

	@include bp("medium") {
		padding: 11px 20px;
		border-radius: 20px;
		@include font-14b;
	}

	text-align: center;

	@include bp("small") {
		width: auto;
	}

	@include bp("medium") {
		@include link-transition;
	}

	&.has-error {
		border-color: $error;
		color: $error;
	}
}

.c-form__radio--large {
	.c-fake-radio__display {
		padding: 16px 20px;
		border-radius: 30px;
		@include font-18b;

		@include bp("medium") {
			padding: 16px 30px;
		}
	}
}

.c-fake-radio:checked + .c-fake-radio__display {
	color: $white;
	color: var(--primary-b);
	background: $brand;
	background: var(--primary-a);
}

.c-fake-radio:focus + .c-fake-radio__display,
.c-fake-radio__display:hover {
	box-shadow: inset 0 0 0 3px $brand;
	box-shadow: inset 0 0 0 3px var(--primary-a);

	&.has-error {
		box-shadow: inset 0 0 0 3px $error;
	}
}

// Plain display
.c-fake-radio__display--plain {
	display: inline-block;
	margin-right: $ssu;
	vertical-align: middle;
	width: 26px;
	height: 26px;
	border-radius: 100%;
	border: 1px solid transparent;
	cursor: pointer;
	position: relative;
	@include link-transition(all, 0.1s);

	&::before {
		content: "";
		@include abs-center;
		width: 0;
		height: 0;
		border-radius: 100%;

		@include link-transition(all, 0.1s);
	}

	&::after {
		content: "";
		@include abs-center;
		min-width: 100%;
		min-height: 100%;
		width: 44px;
		height: 44px;
	}

	.c-fake-radio:checked + &::before {
		width: 70%;
		height: 70%;
	}

	.c-fake-radio:focus + &::after {
		@include focus-outline;
	}

	&.has-error {
		border-color: $error;

		&::before {
			background: $error;
		}
	}

	.c-fake-radio:disabled + & {
		cursor: default;
		border-color: $grey;

		&::before {
			background: $grey;
		}
	}
}

.c-fake-radio__display-text--plain {
	cursor: pointer;

	.c-fake-radio:disabled ~ & {
		cursor: default;
	}
}

.c-fake-radio:disabled {
	+ .c-fake-radio__display {
		&,
		&:hover,
		&.has-error {
			box-shadow: inset 0 0 0 1px $grey;
		}

		color: $grey;
		cursor: default;

		&:hover {
			background: $white;
		}
	}

	&:checked + .c-fake-radio__display {
		background: $grey;
		color: $white;
	}
}

.c-fake-radio:focus + .c-fake-radio__display {
	@include focus-outline;
}

// Fake file
.c-fake-file {
}

.c-fake-file__display {
	@include flex-extend;
	max-width: 500px; // Needs to reflect form-input mixin

	.c-fake-file:focus + & {
		@include focus-outline;
	}

	&.has-error {
		.c-fake-file__display-value {
			border-color: $error;
		}

		.c-fake-file__display-button {
			background: $error;
		}
	}

	cursor: pointer;

	.c-fake-file:disabled + & {
		cursor: not-allowed;

		.c-fake-file__display-value {
			background: $grey--light;
		}

		.c-fake-file__display-button {
			cursor: not-allowed;
			color: $white;

			&,
			&:hover,
			&:focus {
				background: $grey;
			}
		}
	}
}

.c-fake-file__display-value {
	@include form-input;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(.has-value) {
		color: $grey;
	}
}

.c-fake-file__display-button {
	margin-left: $ssu;

	@include flex-extend;
	align-items: center;
	justify-content: center;
}

.c-fake-file__upload-layout {
	@include bp("medium") {
		display: flex;
		align-items: stretch;
	}
}

.c-fake-file__upload-input {
	flex-grow: 1;
	max-width: 500px; // Needs to reflect form-input mixin
}

.c-fake-file__upload-button {
	flex-shrink: 0;

	@include bp-below("medium") {
		margin-top: $ssu;
	}

	@include bp("medium") {
		margin-left: $ssu;
	}

	@include flex-extend;
	align-items: center;
	justify-content: center;
}


// Custom range input
$range-height: 24px;
$range-focus-width: 3px;

.c-form__range {
	@include flex-extend;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: ($ssu * 1.5);
	margin: ($bsu * 1.5) 0 $bsu;

	@include bp("medium") {
		flex-wrap: nowrap;
		align-items: center;
		padding: $bsu;
		margin: $lsu 0 $bsu;
	}

	background: $grey--lightest;
	border-radius: 12px;
	// Create stacking context
	isolation: isolate;
	transform: scale(1);
}

.c-form__range-label {
	@include font-12bu;

	@include bp("medium") {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.c-form__range-label--name {
	order: 1;
	width: 100%;

	@include bp("medium") {
		width: auto;
	}
}

.c-form__range-input-wrap {
	order: 2;
	width: 100%;

	@include bp("medium") {
		order: 3;
		width: auto;
	}
}

.c-form__range-label--min {
	order: 3;

	@include bp("medium") {
		order: 2;
	}
}

.c-form__range-label--max {
	order: 4;
}

.c-form__range-input-wrap {
	flex-grow: 1;

	@include bp("medium") {
		margin-right: 20px;
	}
	// Because IE11 and Edge need the track to be thick to allow for a circular
	// thumb on the range input, the track needs to be hidden and faked here:
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		margin-top: -2px;
		height: 2px;
		left: 0;
		width: 100%;
		background: var(--secondary-a);
	}
}

.c-form__range-tooltip-track {
	// Restricting the track for the tooltip helps it stay centred above the
	// thumb, since the centre of the thumb doesn't move smoothly from 0% to 100%

	position: absolute;
	bottom: 100%;
	left: 0;
	right: $range-height;
}

.c-form__range-tooltip {
	position: absolute;
	bottom: 100%;
	left: 0;
	transform: translateX(-50%);
	margin-left: math.div($range-height, 2);
	margin-bottom: 17px;
	background: $white;
	padding: 10px 15px;
	border-radius: 50px;

	@include font-12bu;
	@include link-transition(font-size);
	white-space: nowrap;
	box-shadow: 0 8px 10px 5px rgba($black, 0.2);

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		display: block;
		width: 0;
		height: 0;
		border-top: 5px solid $white;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}
}

@include bp-below("extra-large") {
	.c-form__range--zoom .c-form__range-tooltip {
		font-size: 18px;
	}
}

.c-form__range-focus {
	display: block;
	position: absolute;
	z-index: 1;
	pointer-events: none;
	bottom: 0;
	top: 0;
	left: 0;
	transform: translate(-50%, -50%);
	margin-left: math.div($range-height, 2);
	margin-top: math.div($range-height, 2);
	width: $range-height + ($range-focus-width * 2);
	height: $range-height + ($range-focus-width * 2);
	border-radius: 100%;
}

.c-form__range-input:focus {
	outline: none;

	+ .c-form__range-tooltip-track {
		.c-form__range-focus {
			// @include focus-outline;
			box-shadow: inset 0 0 0 $range-focus-width var(--secondary-a);
		}
	}
}

.c-form__range::-moz-focus-outer {
	border: 0;
}

.c-form__range.is-disabled {
	.c-form__range-input-wrap {
		&::before {
			background: $grey--light;
		}
	}

	.c-form__range-tooltip {
		color: $grey;
	}
}

// Based off code generated by http://danielstern.ca/range.css/
.c-form__range-input {
	position: relative;
	z-index: 1;
	-webkit-appearance: none;
	background: transparent;
	width: 100%;
	margin: 0;
	padding: 0;
	height: $range-height;
}

.c-form__range-input::-webkit-slider-runnable-track {
	width: 100%;
	// Height required for IE11 and Edge
	height: $range-height;
	cursor: pointer;
	box-shadow: none;
	background: transparent;
	border-radius: 0;
	border: none;
}

.c-form__range-input::-moz-range-track {
	width: 100%;
	height: $range-height;
	cursor: pointer;
	box-shadow: none;
	background: transparent;
	border-radius: 0;
	border: none;
}

.c-form__range-input::-ms-track {
	height: $range-height;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

.c-form__range-input::-webkit-slider-thumb {
	box-shadow: none;
	border: none;
	height: $range-height;
	width: $range-height;
	border-radius: $range-height;
	background: var(--primary-a);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: 0;
}

.c-form__range-input::-moz-range-thumb {
	box-shadow: none;
	border: none;
	height: $range-height;
	width: $range-height;
	border-radius: $range-height;
	background: var(--primary-a);
	cursor: pointer;
}

.c-form__range-input::-ms-thumb {
	box-shadow: none;
	border: none;
	width: $range-height;
	border-radius: $range-height;
	background: var(--primary-a);
	cursor: pointer;
	height: $range-height;
}

.c-form__range-input:disabled {
	&::-webkit-slider-runnable-track {
		cursor: default;
	}

	&::-moz-range-track {
		cursor: default;
	}

	&::-ms-track {
		cursor: default;
	}

	&::-webkit-slider-thumb {
		background: $grey;
		cursor: default;
	}

	&::-moz-range-thumb {
		background: $grey;
		cursor: default;
	}

	&::-ms-thumb {
		background: $grey;
		cursor: default;
	}
}

.c-form__range-input::-ms-fill-lower {
	background: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;
}

.c-form__range-input::-ms-fill-upper {
	background: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;
}

.c-form__range-input::-ms-tooltip {
	display: none;
}



// Custom currency input
.c-form__currency-layout {
	position: relative;
}

.c-form__currency-unit {
	color: $black;
	position: absolute;
	// Need to reflect form-input mixin's padding and height
	left: 10px;
	top: 10px;
	line-height: 24px;

	@include bp("medium") {
		left: 20px;
	}
}

.c-form__currency-input {
	// Extra specificity needed to override
	.c-form &.c-form__input[type="number"] {
		padding-left: calc(10px + 1ch);

		@include bp("medium") {
			padding-left: calc(20px + 1ch);
		}
	}
}



// Autocomplete
.c-form__autocomplete {
	max-width: 500px;
	position: relative;
}

.c-form__autocomplete-result-list {
	@include list-reset;
	position: absolute;
	z-index: 1;
	background: $white;
	width: 100%;
}

.c-form__autocomplete-result-item {
	display: block;
	width: 100%;
	padding: $ssu;
	border: 1px solid $grey;
	margin-top: -1px;
	text-align: left;
	cursor: pointer;

	&:hover,
	&[aria-selected="true"] {
		background: $grey--light;
	}
}

.c-form__autocomplete-selection {
	background: $grey--light;
	padding: $bsu;
	margin: $bsu 0;

	:last-child {
		margin-bottom: 0;
	}
}



// Divider
.c-form__divider {
	margin: $bsu 0;
	overflow: hidden;
	text-align: center;
}

.c-form__divider-text {
	color: $grey--dark;
	@include font-16u;
	display: inline-block;
	padding: 0 $bsu;
	position: relative;

	&::before,
	&::after {
		content: "";
		position: absolute;
		width: 160px;
		top: 50%;
		border-top: 1px solid $grey--dark;
	}

	&::before {
		right: 100%;
	}

	&::after {
		left: 100%;
	}
}




// Value
.c-form__value {
	display: block;
	background: $grey--lightest;
	border-radius: 2px;
	padding: $ssu $bsu;
	margin: $bsu 0;

	:last-child {
		margin-bottom: 0;
	}
}




// Missing
.c-form__missing {
	font-style: italic;
	color: $blue--strong;
}




// Expand Collapse
.c-form__expand-collapse[aria-hidden="true"] {
	display: none;
}

.c-form__expand-collapse-open {
	margin-top: 40px;

	@include bp("large") {
		margin-top: 60px;
	}

	&[aria-expanded="true"] {
		display: none;
	}
}




// Credit Sense
.credit-sense--frame {
	border: none;
	width: 100%;

	&.is-hidden {
		// Maintain its size, but hide it from the user and
		// stop them interacting with it
		visibility: hidden;
		position: absolute;
		pointer-events: none;
	}
}




// Countdown
.c-form__countdown {
	max-width: 500px;

	@include flex-extend;
	justify-content: flex-end;
	margin: $tsu 0;
}

.c-form__countdown-remaining {
	@include font-12b;
	color: $grey--darker;
}




// Ensure Teraform's custom AngularJS tags are block level or, ideally, ignored
teraform-checkbox,
teraform-date,
teraform-file,
teraform-radio,
teraform-range,
teraform-select,
teraform-text,
teraform-anniversary,
teraform-nz-bank-account {
	display: block;

	@supports (display: contents) {
		display: contents;
	}
}

// Teraform's label transclusion tag should be inline-block by default instead
teraform-label {
	display: inline-block;

	@supports (display: contents) {
		display: contents;
	}
}
