﻿// CONTROL.TRIPLE-POD

.c-triple-pod {

}

.c-triple-pod__body {
	text-align: center;
	margin-bottom: $lsu;
}

.c-triple-pod__img {
	margin-bottom: $bsu;

	.o-fit__image {
		object-fit: contain;
	}
}

.c-triple-pod__content {
	padding: 0 $bsu;
}

.c-triple-pod__title {
	@include font-24b;
	margin-bottom: $bsu;

	@include bp("medium") {
		@include font-28b;
	}
}

.c-triple-pod__abstract {
	@include font-15;
}

.c-triple-pod__link {
	text-align: center;
}