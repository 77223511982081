// OBJECT.FIGURE

.o-figure {
	display: block;

	> img,
	> noscript > img {
		display: block;
		width: 100%;
	}
}
