﻿// CONTROL.HELP-POD
.c-help-pod {
	@include pod-shadow;
	position: relative;
	display: block;
	top: 0;
	//min-height: 200px;
	padding: $ssu*3;
	border-radius: 5px;
	height: 100%;

	&:hover, &:focus {
		top: -5px;
		@include gradient-bg__to-bottom-right;

		.c-help-pod-title, .c-help-pod-abstract {
			color: $white;
		}
	}

	.c-help-pods--gradient & {
		@include gradient-bg__to-bottom-right;

		.c-help-pod-title, .c-help-pod-abstract {
			color: $white;
		}

		&:hover, &:focus {
			background: $white;

			.c-help-pod-title {
				color: $brand;
			}

			.c-help-pod-abstract {
				color: $black;
			}
		}
	}
}

.c-help-pod-title {
	@include font-28b;
	position: relative;
	padding-right: 40px;
	color: $brand;
	margin-bottom: $bsu;
	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		position: absolute;
		top: 4px;
		right: 0;
	}
}

.c-help-pod-abstract {
	@include font-15;
	color: $body-color;
}