﻿// CONTROL.USER-SELLING_POINT

@use "sass:math";

.c-usp {
	position: relative;
	height: 450px;
	background-position: bottom;
	background-size: cover;

	&:after {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
	}

	.c-testimonial-carousel + & {
		margin-top: 0;
	}
}

.c-usp-content {
	position: absolute;
	z-index: 1;
	width: 100%;
	bottom: $bsu;

	@include bp('extra-large') {
		bottom: $bsu*2;
	}
}

.c-usp-items {
	display: flex;
	@include flex-wrap;

	@include bp('large') {
		flex-wrap: nowrap;
		justify-content: space-around;
	}
}

.c-usp-item {
	width: 50%;
	@include bp('large') {
		width: auto;
		flex: 1;
	}
}

.c-usp-item-body {
	text-align: center;
	padding: $bsu math.div($ssu, 2);
	color: $white;

	@include bp('large') {
		padding: $bsu
	}
}

a.c-usp-item-body {
	&:hover, &:focus {
		.c-usp-item-title {
			text-decoration: underline;
		}
	}
}

.c-usp-item-icon {
	display: block;
	font-size: 50px;
	margin-bottom: $bsu;
}

.c-usp-item-title {
	@include font-18b;
	margin-bottom: $bsu;
}

.c-usp-item-abstract {
	display: none;

	@include bp('extra-large') {
		@include font-14;
		display: block;
	}
}