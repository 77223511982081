.c-portal-app {}

.c-portal-app__head {
	padding: $lsu 0;

	text-align: center;
}

.c-portal-app__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-28;
}

.c-portal-app__intro {
	@include font-15;

	a {
		@include link-brand;
	}

	> :last-child {
		margin-bottom: 0;
	}
}
