@use "sass:math";

@keyframes spin-centred-cw {
	0% {
		transform: translate(50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(50%, -50%) rotate(360deg);
	}
}

@mixin loader-spin($size: 70px) {
	// ::before is an overlay to block interaction
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba($white, 0.8);

		opacity: 0;
		animation: 0.5s 0.5s ease fadein forwards;
	}

	// ::after is the loader itself
	&::after {
		content: "";
		@include abs-center;
		z-index: 1;

		opacity: 0;
		animation: 0.5s 0.5s ease fadein forwards,
		           1s linear infinite spin-centred-cw;

		width: $size;
		height: $size;
		border-radius: 100%;

		border: math.div($size, 7) solid rgba($grey--darker, 0.1);
	}
}

.c-loader {
	position: relative;

	@include loader-spin;
}

.c-loader--small {
	position: relative;

	@include loader-spin(35px);
}

.c-loader--immediate {
	&::before {
		opacity: 1;
		animation: none;
	}
	&::after {
		opacity: 1;
		animation: 1s linear infinite spin-centred-cw;
	}
}

.c-loader--spacer {
	min-height: 200px;
}

.c-loader--fixed {
	position: fixed;
	z-index: 4;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.c-loader--spacer {
	// A loader that takes up its own space instead of being applied
	// to an element that is busy

	pointer-events: none;
	width: 100%;
	max-width: 100%;
	height: 400px;
	@include bp("medium") {
		height: 250px;
	}
	@include bp("large") {
		width: 500px;
		height: 500px;
	}
	@include bp("extra-large") {
		width: 550px;
	}

	&::before {
		background: transparent;
	}
}

.c-loader--after-cloak {
	display: none;
}
.vue-cloak + .c-loader--after-cloak {
	display: block;
}
