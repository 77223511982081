﻿// BASE.LINKS

a {
	text-decoration: none;
}

.o-link-brand {
	@include link-transition;
	color: $brand;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}