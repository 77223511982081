.c-bank-statements {
	padding-top: 20px;
	padding-bottom: 20px;
}

.c-bank-statements.vue-cloak {
	display: none;
}

.c-illion {
	margin-bottom: 40px;
}

.c-bank-statements__heading {
	margin: 40px 0;
}
.c-bank-statements__intro {
	margin: 0 0 40px;
}