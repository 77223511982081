/* OBJECT.FAKE-INPUT */

.o-fake-input__wrapper {
	position: relative;
}

.o-fake-input__wrapper--list {
	@include flex-extend;
	align-items: center;
}

.o-fake-input {
	// Hide, but still allow access through tabbing
	position: absolute !important;
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	overflow: hidden !important;
	padding: 0 !important;
	height: 1px !important;
	width: 1px !important;
	margin: -1px !important;
}

.o-fake-input__display {
	position: relative;
	display: block;
	width: 25px;
	height: 25px;
	border: 1px solid $grey;
	margin-right: 10px;
	text-align: center;
	line-height: 40px;
}

.o-fake-input__label {
	@include font-15;
}