// COMPONENT.FIGURE

@use "sass:math";

.c-figure {
    margin: 0 auto $bsu;

    &.u-float--left {
        clear: left;

        @include bp('small') {
            margin-right: $bsu;
            margin-left: 0;
        }
    }

    &.u-float--right {
        clear: right;

        @include bp('small') {
            margin-right: 0;
            margin-left: $bsu;
        }
    }
}

.c-figure--embed {

    .c-figure__content,
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.c-figure__container {
    position: relative;
    display: block;
    width: 100%;

    .c-figure--embed & {
        overflow: hidden;
        height: 0;
    }
    // Configure height of video pane with a percentage
    // Default ratio is 16:9
    .c-figure--video & {
        padding-bottom: percentage(math.div(9, 16));
    }
    // Configure height of map pane with a percentage
    // Default ratio is 3:2
    .c-figure--map & {
        padding-bottom: percentage(math.div(2, 3));
    }

    img {
        display: block;
        width: 100%;
    }
}

.c-figure__figcaption {
    @include ui-border(bottom);
    padding: $ssu 0;
}

.c-video__cover {
    transition: all 1s linear;
    opacity: 1;
    visibility: visible;

    &.is-playing {
        opacity: 0;
        visibility: hidden;
    }
}
