﻿// COMPONENT.PAGINATION

.c-pagination {
	@include font-15;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $bsu 0;

	@include bp('extra-large') {
		padding: $bsu*2 0;
	}
}

.c-pagination-btn {
	color: $brand;
	&:hover, &:focus {
		color:darken($brand,10);
	}
}

.c-pagination-btn {
	@include font-16b;
	display: inline-flex;
	align-items: center;
	margin: 0 $bsu;
}

.c-pagination-dropdown {
	@include pod-shadow;
	padding: 0 $ssu;
	margin: 0 $ssu;
	height: 45px;
	text-align: left;

	@include bp('medium') {
		padding: 0 $bsu;
		margin: 0 $bsu;
	}
}

.c-pagination-icon {

	font-size: 25px;

	@include bp('medium') {
		&.left-icon {
			margin-right: $bsu;
		}

		&.right-icon {
			margin-left: $bsu;
		}
	}
}

.c-pagination-desktop-text {
	display: none;
	@include bp('medium') {
		display: inline;
	}
}