﻿// CONTROL.LOAN-CALCULATOR
.c-loan-calculator-control {
    .c-loan-calculator {
        top: 0;
        margin: 0 auto;
        left: -20px;
        width: calc(100% + 40px);
    }

    @include bp('medium') {
        .c-loan-calculator {
            left: 0;
            width: 100%;
            min-width: 460px;
            max-width: 560px;
        }
    }

    @include bp('extra-large') {
        .c-loan-calculator {
            max-width: none;
        }
    }
}

.c-loan-calculator-control__body {
    background-image: url('/Content/images/interface/calculator_pattern.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    .c-loan-calculator-control__text.u-1\/2-l {
        padding-left: 20px;

        @include bp('large') {
            width: 100% !important;
            padding-left: 30px;
        }

        @include bp('extra-large') {
            width: 45% !important;
            padding-left: 30px;
        }
    }

    .c-loan-calculator-control__calculator.u-1\/2-l {
        padding-left: 20px;

        @include bp('large') {
            width: 100% !important;
            padding-left: 30px;
        }

        @include bp('extra-large') {
            width: 55% !important;
            padding-left: 30px;
        }
    }
}

.c-loan-calculator-control__content {
    margin-bottom: $bsu;

    @include bp('large') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-bottom: 0;
        padding-bottom: $bsu*3;
    }
}

.c-loan-calculator-control__subheading {
    @include font-28b;
    margin-bottom: $bsu;
}

.o-grid--extra-loose {
    margin: 0 0 0 -20px !important;

    @include bp('extra-large') {
        margin: 0 0 0 -70px !important;
    }
}
