$portal-repayment-panel-bp: "medium";

.c-portal-repayment-panel {
	background: $status-teal-dark--pale;
	border-radius: 8px;
	padding: $bsu;

	text-align: center;

	margin-top: $bsu;
	.c-portal-card & {
		margin-top: 0;
	}
	.c-portal-card .c-notification + .c-portal-card__repayment &,
	.c-portal-card .c-portal-card__notification-list + .c-portal-card__repayment & {
		margin-top: $bsu;
	}

	margin-bottom: $ssu;

	@include bp-below($portal-repayment-panel-bp) {
		@include flex-extend;
		flex-direction: column;
		align-items: center;
	}
}

.c-portal-repayment-panel__heading {
	display: block;
	margin-bottom: $ssu;
	&:first-child {
		margin-top: 0;
	}

	text-align: center;
	@include font-14;
}

.c-portal-repayment-panel__heading--bold {
	@include font-12bu;
}

.c-portal-repayment-panel__amount {
	@include font-28b;
}

.c-portal-repayment-panel__due {
	display: inline-block;
	margin-left: $ssu;

	@include font-15b;
}

.c-portal-repayment-panel__description {
	margin-top: $tsu;

	@include font-14;

	&:last-child {
		margin-bottom: 0;
	}
}
