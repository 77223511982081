// COMPONENT.THEME

/*
	!IMPORTANT!
	Any changes made to the following should also be made in
	~/Views\Housing\ThemeStyle.cshtml
*/


/*
	These custom properties can be used directly in SCSS files for
	progressive enhancement, where a utility class won't work.

	Browsers that don't support custom properties will ignore CSS like this:

	background-color: var(--primary-a);

	So if a fallback is necessary you can use something like this:
	background-color: $grey;
	background-color: var(--primary-a);

	Browser support:
	https://caniuse.com/#search=custom%20properties
*/
:root {
	--primary-a: #{$brand};
	--primary-b: #{$white};
	--primary-text: #{$blue--grey}; // todo-fe was $blue--dark

	--secondary-a: #{$blue--grey}; // todo-fe was $blue--dark
	--secondary-b: #{$white};

	--supporting-1-a: #{$orange};
	--supporting-1-b: #{$white};

	--supporting-2-a: #{$orange--dark};
	--supporting-2-b: #{$white};

	--supporting-4: #{$teal--pale};

	--link: #{$brand};
	--link--hover: #{$brand};

	--gradient-start: #{$blue--grey};
	--gradient-end: #{$gradient-stop-color};
	--gradient-contrast: #{$white};
}

$hollow-btn-contrast: $white;
$range-focus-width: 3px;

.t-bg {
	background: var(--primary-a);
}

.t-bg--rev {
	background: var(--primary-b);
}

.t-bg--sec {
	background: var(--secondary-a);
}

.t-bg__text {
	color: var(--primary-b);
}

.t-text {
	color: var(--primary-text);
}

.t-bg__text--rev {
	color: var(--primary-a);
}

.t-text--sec {
	color: var(--secondary-a);
}

.t-bg__text--sec {
	color: var(--secondary-b);
}

.t-bg--sup-1 {
	background-color: var(--supporting-1-a);
}

.t-bg__text--sup-1 {
	color: var(--supporting-1-b);
}

.t-bg__text--rev--active:hover,
.t-bg__text--rev--active:focus,
.t-hover-parent:hover .t-bg__text--rev--active,
.t-hover-parent:focus .t-bg__text--rev--active {
	color: var(--secondary-a);
}

.t-before-bg::before,
.t-before-bg.is-selected::before {
	background-color: var(--primary-a);
}

.t-before-text::before {
	color: var(--primary-a);
}

.t-border-hover:focus,
.t-border-hover:hover,
.t-hover-parent:focus .t-border-hover,
.t-hover-parent:hover .t-border-hover {
	border-color: var(--primary-a);
}

.t-border-hover[aria-expanded="false"]:hover {
	border-color: var(--primary-a);
}

.t-link,
.t-link-parent a,
.o-layout-header__lede a {
	color: var(--link);
}

.t-link:focus,
.t-link:hover,
.t-hover-parent:hover .t-link,
.t-hover-parent:focus .t-link,
.t-link-parent a:focus,
.t-link-parent a:hover,
.o-layout-header__lede a:focus,
.o-layout-header__lede a:hover {
	color: var(--link--hover);
}

.t-link--primary-hover:focus,
.t-link--primary-hover:hover,
.t-hover-parent:focus .t-link--primary-hover,
.t-hover-parent:hover .t-link--primary-hover {
	color: var(--primary-a);
}

.t-link-parent--plain a {
	color: var(--primary-b);
}

.t-link-parent--plain a:hover,
.t-link-parent--plain a:focus {
	text-decoration: underline;
}

.t-link-parent--sec-plain a {
	color: var(--secondary-b);
}

.t-link-parent--sec-plain a:hover,
.t-link-parent--sec-plain a:focus {
	text-decoration: underline;
}

.t-link-underline,
.o-link-brand {
	color: var(--primary-text);
	text-decoration: none;
}

.t-link-underline:hover,
.t-link-underline:focus,
.o-link-brand:hover,
.o-link-brand:focus {
	color: var(--primary-text);
	text-decoration: underline;
}

/* Plugins - we don't want to change the class names */
.flickity-prev-next-button {
	background-color: transparent;
}
.flickity-button-icon {
	fill: var(--primary-a);
}
.flickity-page-dots .dot.is-selected {
	background-color: var(--primary-a);
}

.t-pseudo::before,
.t-pseudo::after {
	color: var(--primary-a);
}
.t-pseudo-border::before,
.t-pseudo-border::after {
	border-color: var(--primary-a);
}

/*************/
/* GRADIENTS */
/*************/
.t-bg-grad {
	background: var(--gradient-start);
	background: linear-gradient(90deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
}

.t-bg-grad--rev {
	background: var(--gradient-end);
	background: linear-gradient(90deg, var(--gradient-end) 0%, var(--gradient-start) 100%);
}

.t-bg-grad--tb {
	background: var(--gradient-start);
	background: linear-gradient(0deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
}

.t-bg-grad--tb-rev {
	background: var(--gradient-end);
	background: linear-gradient(0deg, var(--gradient-end) 0%, var(--gradient-start) 100%);
}

.t-bg-grad--diag {
	background: var(--gradient-start);
	background: linear-gradient(to bottom right, var(--gradient-start), var(--gradient-end));
}

.t-bg-grad--diag-rev {
	background: var(--gradient-end);
	background: linear-gradient(to bottom right, var(--gradient-end), var(--gradient-start));
}

.t-bg-grad__text {
	color: var(--gradient-contrast);
}

/***********/
/* OBJECTS */
/***********/
.o-body-text > ul > li::before {
	background-color: var(--primary-a);
}
.o-body-text > ul ul li::before {
	border-color: var(--primary-a);
}
.o-body-text table ul li::before {
	color: var(--primary-a);
}
.o-body-text a:not(.o-btn),
.o-layout-header__lede a{
	color: var(--link);
}
.o-body-text a:not(.o-btn):focus,
.o-body-text a:not(.o-btn):hover,
.o-layout-header__lede a:focus,
.o-layout-header__lede a:hover {
	color: var(--link--hover);
}
.o-link-brand {
	color: var(--primary-text);
	text-decoration: none;
}
.o-link-brand:hover,
.o-link-brand:focus {
	color: var(--primary-text);
	text-decoration: underline;
}
.o-control__heading::after,
.o-body-text h2:after {
	border-color: var(--primary-a);
}
.o-tile__heading {
	color: var(--primary-text);
}

/*************/
/* SPECIFICS */
/*************/
.t-psuedo::before,
.t-pseudo::after {
	color: var(--primary-a);
}
.t-pseudo-border::before,
.t-pseudo-border::after,
.o-control__heading::after,
.o-body-text h2:after {
	border-color: var(--primary-a);
}

.c-header.is-down .c-header__nav-toggle b,
.no-hero .c-header .c-header__nav-toggle b,
.c-header__menu:focus b,
.c-header__menu:hover b {
	border-color: var(--secondary-a);
}

.c-header.is-down .c-header__menu-text,
.no-hero .c-header .c-header__menu-text,
.c-header__menu:focus .c-header__menu-text,
.c-header__menu:hover .c-header__menu-text {
	color: var(--secondary-a);
}

@media (max-width: 1023px) {
	.c-header__nav-toggle b{
		border-color: var(--secondary-a);
	}

	.c-header__menu-text {
		color: var(--secondary-a);
	}

}


@media (max-width: 767px) {
	.c-hero__calculator .c-loan-calculator__payment-list,
	.c-hero__calculator .c-loan-calculator__payment-primary,
	.c-hero__calculator .c-loan-calculator__title,
	.c-hero__calculator .c-loan-calculator__title-label.t-text,
	.c-hero__calculator .c-loan-calculator__total,
	.c-hero__calculator .c-loan-calculator__total-amount {
		color: var(---gradient-contrast);
	}
}

@media (max-width: 1023px) {

	.c-home-hero,
	.c-hero__content {
		background: linear-gradient(to bottom left, var(--gradient-start), var(--gradient-end));
	}

	.c-hero__calculator .o-btn--hollow.c-loan-calculator__continue-mobile {
		border-color: var(--gradient-contrast);
		color: var(--gradient-contrast);
	}

	.c-hero__calculator .o-btn--hollow.c-loan-calculator__continue-mobile:hover {
		background: var(--gradient-contrast);
		color: var(--primary-a);
	}
}

.c-help-pod .c-help-pod-title {
	color: var(--primary-text);
}

.c-help-pods--gradient .c-help-pod,
.c-help-pod:hover {
	background: var(--gradient-start);
	background: linear-gradient(90deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
}

.c-help-pods--gradient .c-help-pod .c-help-pod-title,
.c-help-pods--gradient .c-help-pod .c-help-pod-abstract,
.c-help-pod:hover,
.c-help-pod:hover .c-help-pod-title,
.c-help-pod:hover .c-help-pod-abstract {
	color: var(--gradient-contrast);
}

.c-help-pods--gradient .c-help-pod:hover,
.c-help-pods--gradient .c-help-pod:focus {
	background: var(--primary-b);
}

.c-help-pods--gradient .c-help-pod:hover .c-help-pod-title,
.c-help-pods--gradient .c-help-pod:hover .c-help-pod-abstract,
.c-help-pods--gradient .c-help-pod:focus .c-help-pod-title,
.c-help-pods--gradient .c-help-pod:focus .c-help-pod-abstract {
	color: var(--primary-a);
}

.c-expand-collapse__item-header {
	background-color: var(--primary-b);
}

.c-expand-collapse__item-header:hover,
.c-expand-collapse__item-header:focus {
	color: var(--primary-a);
}

.c-share__icon.iconf-email {
	color: var(--primary-b);
	border-color: var(--primary-a);
	background: var(--primary-a);
}

.c-share__icon.iconf-email:hover,
.c-share__icon.iconf-email:focus {
	color: var(--primary-a);
	background: var(--primary-b);
}

.c-share__icon.iconf-print {
	color: var(--secondary-b);
	border-color: var(--secondary-a);
	background: var(--secondary-a);
}

.c-share__icon.iconf-print:hover,
.c-share__icon.iconf-print:focus {
	color: var(--secondary-a);
	background: var(--secondary-b);
}

.c-app-form__step-list-item::before {
	border-top: 1px solid var(--primary-a);
}

.c-app-form__step-list-btn {
	box-shadow: inset 0 0 0 1px var(--primary-a);
	background: var(--primary-a);
	color: var(--primary-b);
}

.c-app-form__step-list-item[aria-current="true"] .c-app-form__step-list-btn {
	background: var(--primary-a);
	color: var(--primary-b);
}

.c-app-form__step-list-item[aria-current="true"] ~ .c-app-form__step-list-item .c-app-form__step-list-btn {
	color: var(--primary-a);
	background: var(--primary-b);
}

.c-branch-list-item__title a::after {
	color: var(--primary-a);
}

.c-cta-verbose__body--no-bg .c-cta-verbose__text,
.c-cta-verbose__body--no-bg .o-cta__heading {
	color: var(--primary--text);
}

.c-fake-checkbox:checked ~ .c-fake-checkbox__display:before {
	border-color: var(--primary-a);
}

.c-fake-radio__display {
	box-shadow: inset 0 0 0 1px var(--primary-a);
	color: var(--primary-a);
	background: var(--primary-b);
}

.c-fake-radio:checked + .c-fake-radio__display {
	color: var(--primary-b);
	background: var(--primary-a);
}

.c-fake-radio:focus + .c-fake-radio__display,
.c-fake-radio__display:hover {
	box-shadow: inset 0 0 0 3px var(--primary-a);
}

.c-form__range-input-wrap::before {
	background: var(--secondary-a);
}

.c-form__range-input:focus + .c-form__range-tooltip-track .c-form__range-focus {
	box-shadow: inset 0 0 0 $range-focus-width var(--secondary-a);
}

.c-form__range-input::-ms-thumb {
	background: var(--primary-a);
}

.c-tooltip__trigger.t-btn {
	background: var(--primary-a);
	color:var(--primary-b);
}

.c-modal__body a:not(.o-btn) {
	color: var(--link);
}

.c-modal__body a:not(.o-btn):hover {
	color: var(--link--hover);
}

// .c-testimonial-carousel__logo-image {
// 	background-image: url('@Model.LargeSiteIcon.Url');
// }

.c-pod-carousel__title,
.m-quote__text,
.c-listing .c-listing-stacked .o-tile__heading {
	color: var(--primary--text);
}

.c-hero-video-thumbnail.is-selected:after {
	border-color: var(--primary-a);
}

@media (max-width: 767px) {
	.c-testimonial-carousel__item-content {
		background: linear-gradient(to bottom right, var(--gradient-start), var(--gradient-end));
	}

	.c-testimonial-carousel__title.t-text,
	.c-testimonial-carousel__text,
	.c-testimonial-carousel__citation {
		color:var(--gradient-contrast);
	}
}

/***********/
/* Buttons */
/***********/
/* primary - no gradient */
.t-btn,
.o-btn--primary,
.o-btn--brand,
.umbraco-forms-form.primary {
	background-color: var(--primary-a);
	color: var(--primary-b);
}

.t-btn:hover,
.t-btn:focus,
.o-btn--primary:hover,
.o-btn--primary:focus,
.o-btn--brand:hover,
.o-btn--brand:focus,
.umbraco-forms-form.primary:hover,
.umbraco-forms-form.primary:focus,
.t-hover-parent:hover.o-btn--primary,
.t-hover-parent:focus.o-btn--primary {
	background-color: var(--secondary-a);
	color: var(--secondary-b);
}

/* primary - if gradient enabled this is handled on ThemeStyle.cshtml */

/* bordered */
.o-btn--bordered {
	background-color: var(--primary-b);
	color: var(--primary-text);
	border: 1px solid var(--primary-a);
}

.o-btn--bordered[aria-current]:not([aria-current="false"]),
.o-btn--bordered:hover,
.o-btn--bordered:focus,
.t-hover-parent:hover .o-btn--bordered,
.t-hover-parent:focus .o-btn--bordered {
	background-color: var(--secondary-a);
	color: var(--secondary-b);
}

.o-btn--hollow {
	background-color: transparent;
	color: $hollow-btn-contrast;
	border: 1px solid $hollow-btn-contrast;
}

.o-btn--hollow:hover,
.o-btn--hollow:focus {
	background-color: $hollow-btn-contrast;
	color: var(--primary-a);
}

.o-btn--supporting {
	color: var(--supporting-1-b);
	background-color: var(--supporting-1-a);
}

.o-btn--supporting:hover,
.o-btn--supporting:focus {
	color: var(--supporting-2-b);
	background-color: var(--supporting-2-a);
}

.o-btn__badge {
	background: var(--primary-a);
	color: var(--primary-b);
}

.o-btn--link {
	color: var(--primary-a);
}
.o-btn--link:hover,
.o-btn--link:focus {
	color: var(--secondary-a);
}

.c-pod-carousel__arrow,
.c-image-gallery__arrow {
	color: var(--primary-text);
}

.c-pagination-btn {
	color: var(--link);
}

.c-pagination-btn:hover {
	color: var(--link--hover);
}

/* text */
.o-btn--text {
	color: var(--link);
}

.o-btn--text:hover,
.o-btn--text:focus,
.t-hover-parent:hover .o-btn--text,
.t-hover-parent:focus .o-btn--text {
	color: var(--link--hover);
}

/* theme */
.t-bg-hover:hover,
.t-bg-hover:focus {
	background-color: var(--primary-a);
	color: var(--primary-b);
}

.t-bg-hover:hover .t-text,
.t-bg-hover:focus .t-text {
	color: var(--primary-b);
}

/* Header Apply Button */
.c-header__apply-btn {
	background: var(--supporting-1-a);
	border-color: var(--supporting-1-a);
	color: var(--supporting-1-b);
}
.c-header__apply-btn:hover,
.c-header__apply-btn:focus {
	background: var(--supporting-1-b);
	color: var(--supporting-1-a);
}

/* Footer Share Icon */
.c-footer__social-link {
	border-color: var(--primary-text);
	color: var(--primary-text);
}

.c-footer__social-link:hover {
	background: var(---primary-text);
	border-color: var(--primary-b);
	color: var(--primary-b);
}

/* loan calculator continue */
.c-loan-calculator__continue {
	background: var(--primary-a);
	color: var(--primary-b);
}

.c-loan-calculator__continue-close::before {
	color: var(--primary-b);
}

@media screen and (min-width: 1280px) {

	.c-header__apply-btn {
		background: var(--primary-b);
		border-color: var(--primary-a);
		color: var(--primary-text);
	}
	.c-header__apply-btn:hover,
	.c-header__apply-btn:focus {
		background: var(--supporting-1-a);
		border-color: var(--supporting-1-a);
		color: var(--supporting-1-b);
	}

	.c-header.is-down .c-header__apply-btn,
	.no-hero .c-header .c-header__apply-btn {
		background: var(--supporting-1-a);
		border-color: var(--supporting-1-a);
		color: var(--supporting-1-b);
	}
	.c-header.is-down .c-header__apply-btn:hover,
	.no-hero .c-header .c-header__apply-btn:hover,
	.c-header.is-down .c-header__apply-btn:focus,
	.no-hero .c-header .c-header__apply-btn:focus {
		background: var(--supporting-1-b);
		color: var(--supporting-1-a);
	}
}

/*******************/
/* Customer Portal */
/*******************/
.c-portal-header__welcome {
	background: var(--primary-a);
	color: var(--primary-b);
}

.c-portal-hero--primary .c-portal-hero__body-outer {
	background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
}

.c-portal-hero--simple {
	background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
}

.c-portal-hero--simple .c-portal-hero__back {
	color: var(--gradient-contrast);
}


.c-portal-app-progress__amount,
.c-portal-app-progress__link-text {
	color: var(--primary-text);
}


.c-portal__footer-back-to-top-icon {
	color: var(--link);
}

.c-portal__footer-back-to-top-icon:hover,
.c-portal__footer-back-to-top-icon:focus {
	color: var(--link--hover);
}


.c-fake-radio__display--plain {
	border-color: var(--primary-a);
}

.c-fake-radio__display--plain::before {
	background: var(--secondary-a);
}


.c-portal-personal-info__icon {
	color: var(--primary-a);
}


.c-portal-nav__section[aria-current]:not([aria-current="false"])::before {
	border-left-color: var(--primary-a);
}


.c-notification__content a {
	color: var(--link);
}

.c-notification .c-notification__bar {
	border-color: var(--secondary-a);
	background: var(--secondary-a);
}
.c-notification .c-notification__body {
	border-color: var(--secondary-a);
	background: var(--secondary-b);
}
.c-notification .c-notification__amount {
	color: var(--secondary-a);
}
.c-notification .c-notification__low-contrast {
	color: var(--secondary-a);
}

.c-notification.o-portal-status--okay .c-notification__bar {
	border-color: var(--primary-a);
	background: var(--primary-a);
	color: var(--primary-b);
}
.c-notification.o-portal-status--okay .c-notification__body {
	border-color: var(--primary-a);
	background: var(--supporting-3-a);
	color: var(--supporting-3-b);
}
.c-notification.o-portal-status--okay .c-notification__amount {
	color: var(--secondary-a);
}
.c-notification.o-portal-status--okay .c-notification__low-contrast {
	color: var(--secondary-a);
}

.o-btn--bordered-portal {
	color: var(--primary-text);
	background: var(--primary-b);
	border: 1px solid var(--primary-a);
}
.o-btn--bordered-portal:hover,
.o-btn--bordered-portal:focus {
	color: var(--primary-b);
	background: var(--primary-a);
}

.o-btn--bordered-portal.o-portal-status--okay {
	color: var(--primary-text);
	background: var(--primary-b);
	border: 1px solid var(--primary-a);
}
.o-btn--bordered-portal.o-portal-status--okay:hover,
.o-btn--bordered-portal.o-portal-status--okay:focus {
	color: var(--primary-b);
	background: var(--primary-a);
}


.c-portal-nav__sticky::after {
	background: var(--supporting-4);
}

.c-portal-nav__menu {
	background: var(--supporting-4);
}

.c-portal-nav__toggle {
	background: var(--primary-a);
}
.c-portal-nav__toggle::before {
	color: var(--primary-b);
}

.c-portal-nav__toggle:hover,
.c-portal-nav__toggle:focus {
	background: var(--secondary-a);
}
.c-portal-nav__toggle:hover::before,
.c-portal-nav__toggle:focus::before {
	color: var(--secondary-b);
}

.c-portal-nav__button-link {
	color: var(--link);
}

.c-portal-nav__button-link:hover,
.c-portal-nav__button-link:focus {
	color: var(--link-hover);
}

.c-portal-nav__welcome {
	background: var(--primary-a);
	color: var(--primary-b);
}

.c-portal-nav__section-head,
.c-portal-nav__section-child-link {
	color: var(--link);
}


@media (max-width: 767px) {
	.c-tab-nav--mobile .c-tab-nav__link {
		color: var(--primary-text);
		background: var(--primary-b);
	}
	.c-tab-nav--mobile .c-tab-nav__link::after {
		color: var(--primary-a);
	}
}
@media (min-width: 768px) {
	.c-tab-nav__item[aria-current="page"] .c-tab-nav__link {
		color: var(--link);
	}
	.c-tab-nav__item[aria-current="page"] .c-tab-nav__link::before {
		border-bottom: var(--primary-a);
	}
	.c-tab-nav__item[aria-current="page"] .c-tab-nav__link:hover,
	.c-tab-nav__item[aria-current="page"] .c-tab-nav__link:focus {
		color: var(--link-hover);
	}
}


// Task Pods
.c-task-pods__pod {
	color: var(--gradient-contrast);
	background-image: linear-gradient(to right, var(--gradient-start) 0%, var(--gradient-start) 66%, var(--gradient-end) 100%);
}


.c-portal-personal-info__action,
.c-form__section-title-split-action-button,
.c-comm-prefs__change {
	color: var(--link);
}
.c-portal-personal-info__action:hover,
.c-portal-personal-info__action:focus,
.c-form__section-title-split-action-button:hover,
.c-form__section-title-split-action-button:focus,
.c-comm-prefs__change:hover,
.c-comm-prefs__change:focus {
	color: var(--link-hover);
}


.c-loader::after,
.c-loader--small::after {
	border-top-color: var(--primary-a);
}


// B2C
#api #retryCode {
	color: var(--link);
}

#api #cancel {
	color: var(--primary-a);
}
#api #cancel:hover,
#api #cancel:focus {
	color: var(--secondary-a);
}
