﻿// CONTROL.CAREERS

.c-careers__pod {
	@include flex-extend;
	flex-direction: column;
}

.c-careers__pod-body {
	width: 100%;
	flex-grow: 1;

	@include flex-extend;
	flex-direction: column;
	align-items: flex-start;
}

.c-careers__pod-details {
	@include list-reset;
	@include flex-extend;
	@include flex-wrap;
	margin-top: 10px;
}

.c-careers__pod-detail {
	white-space: nowrap;
}

.c-careers__pod-term {
	font-weight: $weight--bold;
}

.c-careers__pod-def {
	padding: 0 15px 0 8px;
}

.c-careers__pod-text {
	flex-grow: 1;

	@include font-15;
	margin-top: 10px;
	color: $body-color;

	*:last-child {
		margin-bottom: 0;
	}
}

.c-careers__pod-btn {
	margin-top: 15px;
}