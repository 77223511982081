// OBJECT.BODY-TEXT

// First class here targets the RTE within the CMS so it will also get styled
.mce-content-body {
	padding: 10px;
}

.mce-content-body,
.o-body-text {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}


	h3,
	h4,
	h5 {
		margin: 1.2em 0 0.6em;
	}

	h2 {
		/*@include font-40b;

		@include bp('large') {
			@include font-28b;
		}*/
		@extend .o-control__heading;
	}

	h3 {
		@include font-20b;

		@include bp('large') {
			@include font-28b;
		}
	}

	h4 {
		@include font-16b;

		@include bp('large') {
			@include font-20b;
		}
	}

	h5 {
		@include font-16b;
	}

	> p {
		@include font-16;
		margin-bottom: 1.5em;
	}

	a:not(.o-btn) {
		@include link-brand;
	}

	hr {
		border-width: 1px 0 0;
		border-top: 1px solid $base-ui-color;
		height: 0;
		clear: both;
		margin: 1.5em 0;
	}

	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1.5em 0;

		li {
			@include font-16;
			margin-bottom: 1em;
		}

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul {
			margin-top: 10px;
			padding-left: 20px;

			li {
				position: relative;
				padding-left: 20px;
				list-style-type: none;

				&:before {
					position: absolute;
					left: 0;
					top: 7px;
					content: "";
					width: 10px;
					height: 10px;
					border-radius: 50%;
					border: 1px solid var(--primary-a);
				}
			}
		}

		ol {

			li {
				list-style-type: lower-roman;
			}
		}
	}

	> ul > li {
		position: relative;
		padding-left: 20px;
		list-style-type: none;

		&:before {
			position: absolute;
			left: -10px;
			top: 7px;
			content: "";
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: var(--primary-a);
		}
	}

	> ol > li {
		list-style-type: decimal;
	}

	table {
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;
		border-collapse: collapse;


		p {
			margin: 0;
		}
		// Remove default min-width if content editor has set the width in the CMS
		&[style*="width"] {
			min-width: 0;
		}
		// Add margin to right if content editor has set the table to float left in the CMS
		&[style*="float: left"] {
			margin-right: $bsu;
		}
		// Add margin to left if content editor has set the table to float right in the CMS
		&[style*="float: right"] {
			margin-left: $bsu;
		}

		ul {
			list-style: none;

			li::before {
				content: "\2022";
				color: var(--primary-a);
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
		}
	}

	.tablescroll table {
		margin-bottom: 0;
	}

	th {
		@include font-16b;
		background-color: #F5FBFD;
	}

	thead {

		td {
			@include font-16b;
			background-color: #F5FBFD;
		}
	}

	th,
	td {
		@include ui-border('all');
		padding: $ssu $bsu;
	}

	td {
		@include font-16;
		vertical-align: top;
	}

	caption {
		@include ui-border(bottom);
		@include font-12;
		caption-side: bottom;
		padding: $bsu 0;
		text-align: left;
	}
}

// RTE figure - shared styling across macros
.o-body-text__figure {
	margin: $bsu auto $lsu;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin: 0 $lsu $bsu 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin: 0 0 $bsu $lsu;
		}
	}

	@include bp('medium') {
		margin-top: $lsu;
	}
}

.o-body-text__figcaption {
	@include ui-border('bottom');
	@include font-12;
	padding: $bsu 0
}
