@use "sass:math";

$modal-close-outline-width: 3px;

.c-modal {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($white, 0.6);
	visibility: hidden;
	opacity: 0;

	&[aria-hidden="false"] {
		animation: 0.5s fadein forwards, 0.5s becomeVisible forwards;
	}

	&[aria-hidden="true"] {
		animation: 0.5s fadeout forwards, 0.5s becomeHidden forwards;
	}
}

.c-modal__relative-area {
	@include bp("medium") {
		position: relative;

		.c-modal {
			position: relative;
			background: none;

			&::before {
				content: "";
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($white, 0.6);
			}
		}

		.c-modal__body {
			top: 0;
			left: auto;
			right: 0;
			bottom: auto;
			transform: none;
			min-width: 400px;
		}
	}
}

.c-modal__body-open {
	// To be placed on the <body> tag when a modal is open
	// Uncomment this rule to prevent background scrolling
	// while a modal window is open:
	// overflow: hidden;
}

.c-modal__body {
	@include abs-center;
	overflow: auto;
	width: 100%;
	height: 100%;
	padding: 44px + $modal-close-outline-width;

	@include bp("medium") {
		width: auto;
		max-width: 80vw;
		height: auto;
		max-height: 80vh;
	}

	background: $white;
	box-shadow: 0 0 20px rgba($black, 0.2);

	@include flex-extend;
	align-items: center;
	justify-content: center;

	&,
	> .c-modal__body-dummy {
		> p:last-child {
			margin-bottom: 0;
		}
	}

	&.c-modal__body--stacked {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
}

.c-modal__body-dummy {
	&:focus {
		outline: none;
	}
}

.c-modal__close {
	position: absolute;
	top: $modal-close-outline-width;
	right: $modal-close-outline-width;

	@include button-reset;
	display: block;
	padding: 14px;
	font-size: 16px;
	line-height: 1;
	color: $brand;


	&::after {
		@include font-icon;
		content: $iconf-close;
		// Stop it jumping when starting to change
		display: block;
		transform: scale(1.01);
		@include link-transition(transform);
	}

	&:hover,
	&:focus {
		&::after {
			transform: scale(1.5);
		}
	}
}

.c-modal__dummy {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

.c-modal__body-open--full {
	.c-modal__body {
		max-width: none;
		max-height: none;
		width: 100%;
		height: 100%;
		// 45px is the close button height plus its 1px offset
		padding: #{45px + $bsu} 0 $lsu;

		@include bp("medium") {
			padding: $hsu 0;
		}

		box-shadow: none;
	}

	.c-modal__close {
		right: 20px;

		@include bp($layout-wrapper-max-width) {
			right: 50%;
			transform: translateX(#{math.div($layout-wrapper-max-width, 2) - 20px});
		}
	}

	background: $white;
}

.c-modal__confirm-buttons {
	@include flex-extend;
	justify-content: center;
	margin-top: $bsu;
	padding: 0 $bsu;
}

.c-modal__confirm-button {
	+ .c-modal__confirm-button {
		margin-left: $bsu;
	}
}

// Privacy Policy

#privacy-policy-modal {
	.c-modal__body {

		@include bp("medium") {
			width: 80%;
			max-width: 980px;
		}

		@include bp("large") {
			width: 980px;
		}
	}

	.c-modal__close {
		display: flex;

		&:after {
			margin-left: 10px;
		}
	}

	.bottom-close {
		bottom: 10px;
		right: -45px;
		top: auto;
		position: relative;
		float: right;

		@include bp("medium") {
			bottom: -33px;
		}
	}
}
