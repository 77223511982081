﻿// CONTROL.HIGHLIGHTS
.c-lighlights-pod {
	position: relative;
	margin-bottom: $bsu;

	@include bp('extra-large') {
		display: flex;
	}
}

.c-highlights__tag {
	@include font-12;
	position: absolute;
	left: 0;
	top: 0;
	background: $brand;
	z-index: 1;
	padding: 5px;
	color: $white;

}
.c-highlights__content {
	padding-top: $bsu;

	@include bp('extra-large') {
		flex: 0 0 50%;
		padding: $bsu;
	}
}

.c-highlights__figure {
	@include bp('extra-large') {
		flex: 0 0 50%;
	}
}

.c-highlights__title {
	@include font-28b;
	margin-bottom: $bsu;
}

.c-highlights__abstract {

}