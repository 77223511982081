// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;

$large-nav-width: 800px;
$extra-large-nav-width: 1000px;

body {
	overflow-x: hidden;
	max-width: 100vw;
}

body.nav-open {
	overflow-y: hidden;

	@include bp('large') {
		overflow-y: auto;
	}
}

.c-navigation__wrapper {
	position: fixed;
	left: 0;
	top: $header-height--mobile;
	z-index: $z-navigation;
	width: 100%;
	height: calc(100vh - #{$header-height--mobile});
	overflow-y: auto;
	background-color: $navigation-bg;
	visibility: hidden;
	overscroll-behavior: contain;

	body.has-notification & {
		top: calc($header-height--mobile + $notif-height--mobile);
	}

	&[aria-expanded="true"] {
		animation: 0.3s slideinRight forwards, 0.3s becomeVisible forwards;
	}

	&[aria-expanded="false"] {
		animation: 0.3s slideoutRight forwards, 0.3s becomeHidden forwards;
	}

	@include bp('large') {
		display: flex;
		top: 0;
		width: $large-nav-width;
		height: calc(100vh - 30px); //ipad 100vh not full height
		left: calc(100% - #{$large-nav-width});

		body.has-notification & {
			position: fixed;
			top: 0;
		}

		&[aria-expanded="true"] {
			box-shadow: -10px 0px 10px 1px rgba(0,0,0,.2);
		}
	}

	@include bp('extra-large') {
		width: $extra-large-nav-width;
		height: 100vh;
		left: calc(100% - #{$extra-large-nav-width});
	}
}

.c-navigation {
	@include bp('large') {
		position: relative;
		display: flex;
		flex-direction: column;
		padding-top: 60px;
		width: 50%;
		justify-content: space-between;
	}
}

.c-navigation__items {
	@include ui-border('bottom');
	padding-top: $tsu;
	padding-bottom: $ssu;

	@include bp('large') {
		border-bottom: none;
		padding-left: 30px;
	}
}

.c-navigation__item {
	position: relative;

	@include bp('large') {
		/*display: table-cell;
		width: 1%;
		border: 0;
		text-align: center;
		vertical-align: bottom;*/
	}
}

.c-navigation__link {
	@include font-18b;
	position: relative;
	display: block;
	padding: $ssu $hsu $ssu $bsu*2;
	color: $body-color;

	&:hover, &:focus {
		color: $brand;
	}

	@include bp('extra-large') {
		@include font-28b;
	}
}

/*.c-navigation__link--home {
	font-size: rem(20px);

	@include bp('large') {
		line-height: 1;
	}
}*/

.c-navigation__expander {
	position: absolute;
	left: $bsu;
	top: 50%;
	transform: translateY(-50%);
	font-size: 10px;
	color: $brand;
	transition: transform 0.3s linear;

	.c-navigation__item[aria-expanded="true"] & {
		@extend .iconf-minus;
		transform: translateY(-50%) rotate(180deg);
	}

	@include bp('large') {
		//display: none;
		/*right: $ssu;
		top: 50%;
		transform: translateY(-50%);*/
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	display: none;
	padding: 0 0 $ssu $bsu*2;
	width: 100%;
	background: $navigation-bg;

	@include bp('large') {
		/*position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);

		:focus-within > & { // :focus-within is not supported by IE
			display: block;
		}*/
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: 8px;

	@include bp('large') {
		/*@include ui-border('bottom');*/
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-18;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&:hover, &:focus {
		color: $brand;
	}

	@include bp('large') {
		@include font-20;
	}
}

.c-navigation__skiplink {
	background-color: $orange;
	color: $white;
	padding: 5px 10px;
	margin: 5px;
	position: fixed;
	top: 5px;
	left: 5px;
	z-index: 1000;
	border-radius: 5px !important;
	border: 1px solid $orange;
	opacity: 0;
	transition: all 0.5s ease;

	&:focus {
		opacity: 1;
	}
}

//footer nav links
.c-navigation__footer-links {
	padding: $bsu;

	@include bp('large') {
		display: none;
	}
}

.c-navigation__footer-item {
	padding-left: $bsu;
}

.c-navigation__footer-item-link, .c-navigation__footer-item-expander {
	@include font-18;
	position: relative;
	display: block;
	padding: $tsu 0;
	color: $body-color;
	cursor: pointer;
}

.c-navigation__footer-item-expander-icon {
	position: absolute;
	left: -$bsu;
	top: 50%;
	transform: translateY(-50%);
	font-size: 10px;
	color: $brand;
	transition: transform 0.3s linear;

	.c-navigation__footer-item[aria-expanded="true"] & {
		@extend .iconf-minus;
		transform: translateY(-50%) rotate(180deg);
	}
}

.c-navigation__footer-item--child__items {
	display: none;

	.c-navigation__footer-item[aria-expanded="true"] &, .no-js :hover > & {
		display: block;
	}
}

.c-navigation__footer-item-link--child {
	@include font-15;
	padding: $tsu 0;
	color: $body-color;
}


//quick links
.c-navigation__quicklinks {
	@include ui-border('top');
	display: flex;
	justify-content: space-around;

	@include bp('large') {
		border-top: none;
	}
}

.c-navigation__quicklinks-item {
	width: 25%;
}

.c-navigation__quicklinks-content {
	@include button-reset;
	@include link-transition;
	display: block;
	width: 100%;
	padding: $bsu 0;
	text-align: center;

	&:hover, &:focus {
		background-color: $brand;

		.c-navigation__quicklinks-icon, .c-navigation__quicklinks-title {
			color: $white;
		}
	}
}

.c-navigation__quicklinks-icon {
	display: block;
	color: $brand;
	font-size: 30px;
	margin-bottom: $ssu;
}

.c-navigation__quicklinks-title {
	@include font-12b;
	color: $body-color;
}


.c-navigation-close {
	@include button-reset;
	display: none;

	@include bp('large') {
		display: block;
		position: absolute;
		font-size: 30px;
		right: $bsu;
		top: $bsu;
		cursor: pointer;

		&:hover, &:focus {
			color: $brand;
		}

		&::after {
			content: "";
			@include abs-center;
			min-width: 44px;
			min-height: 44px;
		}
	}
}

//desktop panel part
.c-navigation-panel {
	display: none;

	@include bp('large') {
		display: flex;
		flex-direction: column;
		width: 50%;
	}
}

.c-navigation-feature__img {
	height: 50%;
	background-position: center;
	background-size: cover;
}

.c-navigation-panel-content {
	@include gradient-bg__to-bottom-right;
	height: 50%;
	padding: $ssu*3;

	@include bp('extra-large') {
		padding: $bsu*2;
	}
}

.c-navigation-panel-title {
	@include font-20bu;
	color: $white;

	&:after {
		margin-top: $bsu;
		margin-bottom: $bsu;
		content: '';
		display: block;
		height: 1px;
		width: 100%;
		background-color: $white;
	}
}

.c-navigation-panel-text {
	@include font-15;
	color: $white;
}

.c-navigation-panel__btn {
	display: inline-block;
	margin-top: $bsu;
}

.c-navigation__overlay {

	&[aria-expanded="true"] {
		width: 100vw;
		height: 100vh;
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		animation: 0.3s slideinRight forwards, 0.3s becomeVisible forwards;
	}

	&[aria-expanded="false"] {
		animation: 0.3s slideoutRight forwards, 0.3s becomeHidden forwards;
	}
}
