$portal-hero-bp: "medium";

@mixin portal-hero-gradient {
	@include gradient-bg__to-right;
}

.c-portal-hero {}

.c-portal-hero__body {
	@include bp-below($portal-hero-bp) {
		padding: $bsu $lsu;
	}
	@include bp($portal-hero-bp) {
		padding: $hsu $hsu $lsu 0;
	}

	color: $white;

	p:last-child {
		margin-bottom: 0;
	}
}

.c-portal-hero__heading {
	@include bp-below($portal-hero-bp) {
		@include font-16b;
	}
	@include bp($portal-hero-bp) {
		@include font-28b;
	}
}

.c-portal-hero__detail-label {
	@include font-15;
}

.c-portal-hero__detail-value {
	@include font-15b;
}

.c-portal-hero__amount {
	display: block;
	margin-top: $lsu;
	margin-bottom: $ssu;

	@include font-50;
}

.c-portal-hero__purpose {
	display: block;
	margin-bottom: $bsu;

	@include font-15;
}

.c-portal-hero__status {}

.c-portal-hero__status-label {
	@include font-20;
}

.c-portal-hero__status-value {
	@include font-20bu;
}

.c-portal-hero--primary {
	@include flex-extend;
	align-items: stretch;

	.c-portal-hero__body-outer {
		@include bp-below($portal-hero-bp) {
			display: none;
		}

		width: 50%;
		&:first-child:last-child {
			width: 100%;
			.c-portal-hero__body-inner {
				@include layout-split(left, 100%, $portal-layout-width, $bsu);
			}
		}
		@include portal-hero-gradient;
	}
	.c-portal-hero__body-inner {
		padding-left: $bsu;
		@include layout-split(left, 50%, $portal-layout-width, $bsu);
	}

	.c-portal-hero__heading {
		margin-bottom: $bsu;
	}

	.c-portal-hero__introduction {
		@include font-18--tall;
	}

	.c-portal-hero__media {

		@include bp-below("small") {
			width: 100%;
			min-height: 160px;
		}
		@include bp("small") {
			@include bp-below($portal-hero-bp) {
				width: 100%;
				min-height: 230px;
			}
		}
		@include bp($portal-hero-bp) {
			width: 50%;
			min-height: 330px;
		}
		position: relative;
	}

	.c-portal-hero__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: center right;

		padding: 0;
	}
}

.c-portal-hero--simple {
	@include portal-hero-gradient;
	position: relative;

	.c-portal-hero__layout {
		@include layout-wrapper($portal-layout-width);
	}

	.c-portal-hero__body {
		@include flex-extend;

		@include bp-below($portal-hero-bp) {
			flex-direction: column;
		}
		@include bp($portal-hero-bp) {
			align-items: flex-end;
			position: relative;
		}
	}

	.c-portal-hero__back {
		position: absolute;
		top: $ssu;
		left: 0;

		@include flex-extend;
		align-items: center;
		justify-content: center;

		min-width: 40px;
		min-height: 40px;

		color: $white;

		@include bp($portal-hero-bp) {
			display: none;
		}
	}
	.c-portal-hero__back--desktop {
		@include bp-below($portal-hero-bp) {
			display: none;
		}
		@include bp($portal-hero-bp) {
			@include flex-extend;
		}

		.c-portal-hero__back-icon {
			margin-right: $ssu;
		}

		top: 30px;
	}

	.c-portal-hero__heading {
		@include bp-below($portal-hero-bp) {
			margin-bottom: $ssu;

			width: 100%;
			text-align: center;
		}
		@include bp($portal-hero-bp) {
			width: 50%;
		}
	}
	.c-portal-hero__detail {
		@include bp-below($portal-hero-bp) {
			width: 100%;
			text-align: center;
		}
		@include bp($portal-hero-bp) {
			width: 50%;
			text-align: right;
		}
	}

	.c-portal-hero__detail--desktop-only {
		@include bp-below($portal-hero-bp) {
			display: none;
		}
	}
}
