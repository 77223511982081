// CONTROL.LISTING-STACKED

.c-listing-stacked {
	margin: $bsu 0;
}

.c-listing-stacked__item {
	margin-bottom: $bsu;

	&:last-child {
		margin: 0;
	}
}

.c-listing-stacked__item-link {
	@include ui-border('all');
	@include link-transition;
	display: block;
	padding: $ssu;

	&:hover,
	&:focus {
		outline: none;
		border-color: $brand;

		.c-listing-stacked__item-figure > img {
			transform: scale(1.05);
		}
	}

	@include bp('small') {
		@include flex-extend;
		justify-content: space-between;
	}
}

.c-listing-stacked__item-figure {
	position: relative;
	overflow: hidden;
	margin-bottom: $bsu;

	@include bp('small') {
		flex: 0 0 auto;
		width: calc(50% - #{$bsu});
		margin-bottom: 0;
	}

	@include bp('medium') {
		width: 25%;
	}

	> img {
		transition: transform 0.2s ease-in-out;
	}
}

.c-listing-stacked__item-body {
	flex: 1 1 auto;
	padding: $ssu;

	.c-listing-stacked__item-figure + & {

		@include bp('small') {
			padding-top: $ssu;
			padding-left: $bsu;
		}

		@include bp('medium') {
			padding-left: $bsu;
		}

		@include bp('large') {
			padding-top: $bsu;
			padding-left: $lsu;
			padding-right: $bsu;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-listing-stacked__item-heading {
	@include font-28b;
	color: $body-color;
	margin-bottom: 0.7em;

	a {
		@include link-transition;
		color: $body-color;

		&:hover,
		&:focus {
			color: $brand;
		}
	}
}

.c-listing-stacked__item-text {
	@include font-14;
	color: $body-color;
}