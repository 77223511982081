.c-portal-loan-progress {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: $grey--light;
    position: relative;

    @include flex-extend;
    align-items: center;
    justify-content: center;
}

.c-portal-loan-progress__svg {
    position: absolute;
    display: block;
    width: 100%;
    // transform: scaleX(-1) rotate(-90deg);
    transform: rotate(-90deg);
}

.c-portal-loan-progress__circle {
    stroke-width: 10;
    fill: transparent;
    // Pre-calculated circumference of a circle with radius 95
    stroke-dasharray: 597;
    animation: 2s ease-in-out forwards;
}

.c-portal-loan-progress__body {
    @include flex-extend;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: $ssu;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 100%;
    background: $white;
    color: $body-color;
    position: relative;
}

.c-portal-loan-progress__title {
    @include flex-extend;
    align-items: flex-start;

    .c-tooltip {
        margin-top: $lsu - 2px;
        margin-left: $ssu;
    }
}

.c-portal-loan-progress__label {
    margin-top: $lsu - 5px;

    .c-portal-loan-progress__body--detail & {
        margin-top: $lsu;
    }

    @include font-10bu;
}

.c-portal-loan-progress__amount {
    margin-top: $ssu;

    @include font-28;
}

.c-portal-loan-progress__meta {
    margin-top: $ssu;

    @include font-12;
}

.c-portal-loan-progress__link-text {
    margin-top: $bsu;

    @include font-12b;
}


////////////
// Status //
////////////
@mixin portal-loan-progress-theme($status: $status-teal-dark) {
    .c-portal-loan-progress {
        .c-portal-loan-progress__circle {
            stroke: $status;
        }

        .c-portal-loan-progress__amount,
        .c-portal-loan-progress__link-text {
            color: $status;
        }
    }
}

@include portal-loan-progress-theme;

.o-portal-status--crystallised {
    @include portal-loan-progress-theme($grey--darkest);
}

.o-portal-status--settled {
    @include portal-loan-progress-theme($grey--darker);
}

.o-portal-status--okay {
    @include portal-loan-progress-theme($status-green);
}

.o-portal-status--overdue {
    @include portal-loan-progress-theme($status-orange-light);
}

.o-portal-status--overdue-very {
    @include portal-loan-progress-theme($status-red);
}

.o-portal-status--on-arrangement,
.o-portal-status--warning {
    @include portal-loan-progress-theme($status-orange-dark);
}
